import { GetFacilityInfoDocument, GetFacilityInfoQuery, GetFacilityInfoQueryVariables } from '@/api'
import { createFileRoute } from '@tanstack/react-router'

import { RouteContext } from '../__root'
/**
 * Preload data for everything within /facilities/$id context
 */
export const Route = createFileRoute('/facilities/$id')({
  loader: async ({ params, context }) => {
    const ctx = context as RouteContext
    const res = await ctx.urql.query<GetFacilityInfoQuery, GetFacilityInfoQueryVariables>(
      GetFacilityInfoDocument,
      {
        id: Number(params.id)
      }
    )

    const facility = res.data?.facility

    ctx.setFacility(facility)
  }
})
