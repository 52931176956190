import { useTranslation } from 'react-i18next'
import { useRegisterSW } from 'virtual:pwa-register/react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../ui/alert-dialog'

const reloadTimer = import.meta.env.DEV ? 5 * 1000 : 10 * 60 * 1000

function ReloadPrompt() {
  const { t } = useTranslation()
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(_swUrl, r) {
      if (r) {
        setInterval(() => {
          r.update()
        }, reloadTimer)
      }
    }
  })

  const close = () => {
    setOfflineReady(false)
    setNeedRefresh(false)
  }

  return (
    <>
      <AlertDialog open={needRefresh || offlineReady}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('upgrade.new-version-title')}</AlertDialogTitle>
            <AlertDialogDescription>{t('upgrade.new-version-description')}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => close()} className='bg-destructive text-destructive-foreground'>
              {t('common.cancel')}
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => updateServiceWorker(true)}>
              {t('upgrade.reload')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export { ReloadPrompt }
