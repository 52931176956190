import { PowershareWhereInput, QueryMode, useGetPowershareListQuery } from '@/api'
import { Datetime } from '@/components/DateTime'
import { Route, powersharesSearchSchema } from '@/routes/powershares'
import { Link as RouterLink, useNavigate } from '@tanstack/react-router'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { usePagination } from './usePagination'

type PowersharesSearchParams = z.infer<typeof powersharesSearchSchema>
type Order = PowersharesSearchParams['orderBy']

type PowershareColumns = {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
}

function usePowershareTable() {
  const search = Route.useSearch()

  const { t } = useTranslation()
  const navigate = useNavigate({ from: Route.fullPath })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(search.where)

  const { pagination, onPaginationChange } = usePagination(search.pagination)

  const { pageSize, pageIndex } = pagination

  const skip = pageSize * pageIndex
  const take = pageSize

  const [sorting, setSorting] = useState<Order>(search.orderBy)

  const queryVariables = useMemo(
    () => ({
      take,
      skip,
      orderBy: sorting.reduce((acc: Record<string, 'desc' | 'asc'>, item) => {
        acc[item.id] = item.desc ? 'desc' : 'asc'
        return acc
      }, {}),

      where: (() => {
        const value = columnFilters?.[0]?.value || ''
        const numericValue = Number(value) || null
        return {
          OR: [
            {
              name: {
                contains: value,
                mode: QueryMode.Insensitive
              }
            },
            {
              ...(numericValue
                ? {
                    id: {
                      equals: numericValue
                    }
                  }
                : {})
            }
          ]
        } as PowershareWhereInput
      })()
    }),
    [take, skip, sorting, columnFilters]
  )

  const [result] = useGetPowershareListQuery({
    variables: queryVariables
  })

  const { data, fetching, error } = result

  useEffect(() => {
    navigate({
      to: Route.fullPath,
      search: prev => ({
        ...prev,
        pagination: { skip, take },
        orderBy: sorting,
        where: columnFilters.map(f => ({ value: f.value as string, id: f.id }))
      })
    })
  }, [pageIndex, pageSize, sorting, columnFilters, navigate, skip, take])

  const columns: ColumnDef<PowershareColumns>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('common.id'),
        cell: ({ row }) => (
          <RouterLink to='/powershares/$id' params={{ id: row.getValue('id') as string }}>
            {row.getValue('id')}
          </RouterLink>
        )
      },
      {
        accessorKey: 'name',
        header: t('common.name'),
        meta: {
          filterVariant: 'text'
        },
        cell: ({ row }) => (
          <RouterLink to='/powershares/$id' params={{ id: row.getValue('id') as string }}>
            {row.getValue('name')}
          </RouterLink>
        )
      },
      {
        accessorKey: 'createdAt',
        header: t('common.created-at'),
        enableSorting: false,
        cell: ({ row }) => <Datetime date={row.getValue('createdAt')} />
      },
      {
        accessorKey: 'updatedAt',
        header: t('common.updated-at'),
        enableSorting: false,

        cell: ({ row }) => <Datetime date={row.getValue('updatedAt')} />
      }
    ],
    [t]
  )

  const state = {
    pagination,
    sorting,
    columnFilters
  }

  const table = useReactTable({
    data: data?.powersharesConnection?.edges || [],
    columns,
    state,
    rowCount: data?.powersharesConnection.totalCount || 0,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableFilters: true,
    onSortingChange: setSorting,
    onPaginationChange,
    onColumnFiltersChange: setColumnFilters
  })

  return { table, fetching, error }
}

export default usePowershareTable
