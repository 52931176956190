import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { toast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { RemixiconComponentType, RiFileCopyLine } from '@remixicon/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type CopyToClipBoardProps = RemixiconComponentType['defaultProps'] & {
  value: string
  valueName: string
}

export const CopyToClipBoard = ({ value, valueName, className, size, ...props }: CopyToClipBoardProps) => {
  const { t } = useTranslation()
  const copy = useCallback(() => {
    navigator.clipboard.writeText(value)
    toast({
      variant: 'success',
      title: t('common.copy-to-clip-board-success', '{{valueName}} copied', {
        valueName
      })
    })
  }, [t, value, valueName])

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <RiFileCopyLine
            size={size || 20}
            className={cn('cursor-pointer text-foreground', className)}
            onClick={copy}
            {...props}
          />
        </TooltipTrigger>
        <TooltipContent>{t('common.copy-to-clip-board')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
