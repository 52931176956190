import { GetSearchableFacilityQueryVariables } from '@/api'
import { FacilityIcon, PowershareIcon, SingleBoardComputerIcon, UserIcon } from '@/components/Icons/Icons'
import { Command, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command'
import { Link, useNavigate } from '@tanstack/react-router'
import { CommandList } from 'cmdk'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommandItemRow } from './types'
import { useFacilityCommandGroupData } from './useFacilityCommandGroupData'
import { usePowershareCommandGroupData } from './usePowershareCommandGroupData'
import { useSingleBoardComputerCommandGroupData } from './useSingleBoardComputerCommandGroupData'
import { useUserCommandGroupData } from './useUserCommandGroupData'

const Icon = {
  User: <UserIcon />,
  Facility: <FacilityIcon />,
  Powershare: <PowershareIcon />,
  Sbc: <SingleBoardComputerIcon />
}

const parseWhere = (value: string): GetSearchableFacilityQueryVariables => ({
  searchValue: value,
  searchNumber: Number(value) || 0
})

const content = (row: CommandItemRow) => (
  <div className='flex w-full cursor-pointer flex-row content-center hover:text-primary'>
    {Icon[row.type]}
    <span className='flex-1'></span>
    <p> {row.description}</p>
  </div>
)

interface GeneralSearchProps {
  setOpen?: Dispatch<SetStateAction<boolean>>
  open?: boolean
  initialValue?: string
}

export const GeneralSearch = ({ setOpen, open, initialValue }: GeneralSearchProps) => {
  const [where, setWhere] = useState<GetSearchableFacilityQueryVariables | null>(
    initialValue ? parseWhere(initialValue) : null
  )
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isSearching = where?.searchValue !== ''
  const paused = !isSearching

  useEffect(() => {
    setWhere(parseWhere(''))
  }, [open])

  const onChange = useCallback(
    (value: string) => {
      setWhere(parseWhere(value))
    },
    [setWhere]
  )

  const closeSearch = useCallback(() => {
    if (open && setOpen) {
      setOpen(false)
    }
  }, [setOpen, open])

  const usersData = useUserCommandGroupData({ where, paused })
  const powershareData = usePowershareCommandGroupData({ where, paused })
  const sbcData = useSingleBoardComputerCommandGroupData({ where, paused })
  const facilityData = useFacilityCommandGroupData({ where, paused })

  const commandItem = useCallback(
    (link: { to: string; params: Record<string, string | number> }, row: CommandItemRow) => {
      return (
        <Link onClick={closeSearch} key={`p-${row.id}`} to={link.to} params={link.params}>
          <CommandItem
            className=''
            key={`${row.type}-${row.id}`}
            onSelect={() => {
              closeSearch()
              navigate(link)
            }}
          >
            {content(row)}
          </CommandItem>
        </Link>
      )
    },
    [closeSearch, navigate]
  )

  const groups = useMemo(() => {
    return [
      {
        key: 'facility',
        heading: t('search.facilities', 'Facilities'),
        items: facilityData.map(row =>
          commandItem({ to: '/facilities/$id', params: { id: row.id.toString() } }, row)
        )
      },
      {
        key: 'users',
        heading: t('search.users', 'Users'),
        items: usersData.map(row => commandItem({ to: '/users/$id', params: { id: row.id.toString() } }, row))
      },

      {
        key: 'powershare',
        heading: t('search.powershares'),
        items: powershareData.map(row =>
          commandItem({ to: '/powershares/$id', params: { id: row.id.toString() } }, row)
        )
      },

      {
        key: 'sbc',
        heading: t('sbc.single-board-computer', { count: 3 }),
        items: sbcData?.map(row =>
          commandItem(
            { to: '/single-board-computers/$productId', params: { productId: row.id.toString() } },
            row
          )
        )
      }
    ]
  }, [commandItem, t, usersData, facilityData, powershareData, sbcData])

  if (!groups.length) return
  return (
    <Command shouldFilter={false}>
      <CommandList>
        <CommandInput
          value={where?.searchValue || ''}
          placeholder={t('search.placeholder')}
          onValueChange={onChange}
        />

        {isSearching ? (
          <>
            {groups.map(group =>
              group.items?.length ? (
                <CommandGroup key={group.key} heading={group.heading}>
                  {group.items}
                </CommandGroup>
              ) : null
            )}
          </>
        ) : null}
      </CommandList>
    </Command>
  )
}
