import * as Sentry from '@sentry/react'

const tracePropagationTargets = [
  'https://fsp-pre-production.eu.prod.ferroamp.com',
  'https://fsp.ferroamp.com',
  'https://fsp.staging.portal.dev.ferroamp.com',
  /https:\/\/fsp-review-(.+).staging\.portal\.dev\.ferroamp.com/
]

let environment = ''
switch (window.location.hostname) {
  case 'fsp.ferroamp.com':
    environment = 'production'
    break
  case 'fsp-pre-production.eu.prod.ferroamp.com':
    environment = 'pre-production'
    break
  case 'fsp.staging.portal.dev.ferroamp.com':
    environment = 'staging'
    break
  default:
    break
}

if (/^fsp-review-(.+).staging.portal.dev.ferroamp.com/.test(window.location.host)) {
  environment = 'review'
}

if (__APP_VERSION__ !== 'development') {
  Sentry.init({
    environment,
    release: __APP_VERSION__,
    dsn: 'https://bcb948ecfb76c461b11012bba2cb829d@o405551.ingest.us.sentry.io/4506863908814848',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export { Sentry }
