import { useState } from 'react'

type Pagination = {
  take: number
  skip: number
}

export const usePagination = ({ skip = 0, take = 30 }: Pagination) => {
  const [pagination, setPagination] = useState({
    pageSize: take,
    pageIndex: Math.ceil(skip / take)
  })
  return {
    onPaginationChange: setPagination,
    pagination
  }
}
