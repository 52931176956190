import { usePageHeader } from '@/store/usePageHeader'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const usePowershares = () => {
  const { t } = useTranslation()
  const { setPages, removePages } = usePageHeader()

  useEffect(() => {
    setPages([
      {
        name: t('powershare.create.create-powershare', 'Create Powershare'),
        link: { to: '/powershares/create' }
      }
    ])
    return () => removePages()
  }, [setPages, t, removePages])
}
