import { Moon, Sun } from '@/components/Icons'
import { Button } from '@/components/ui/button'
import { useFerroampTheme } from '@/hooks/useFerroampTheme'
import { useTheme } from '@/theme'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function ModeToggle() {
  const { setTheme } = useTheme()
  const { t } = useTranslation()
  const currentTheme = useFerroampTheme()
  const change = useCallback(() => {
    if (currentTheme === 'dark') {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }, [currentTheme, setTheme])

  return (
    <Button variant='outline' size='icon' onClick={change} className='border-none'>
      <Sun className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' />
      <Moon className='absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' />
      <span className='sr-only'>{t('theme.sr.toggle-theme', 'Toggle theme')}</span>
    </Button>
  )
}
