import {
  GetSearchableSingleBoardComputersQueryVariables,
  useGetSearchableSingleBoardComputersQuery
} from '@/api'
import { useMemo } from 'react'
import { CommandItemRow } from './types'

export const useSingleBoardComputerCommandGroupData = ({
  where,
  paused
}: {
  where: GetSearchableSingleBoardComputersQueryVariables | null
  paused: boolean
}) => {
  const [res] = useGetSearchableSingleBoardComputersQuery({
    variables: { searchValue: where?.searchValue || '' },
    pause: paused
  })

  return (useMemo(() => {
    return (
      res.data?.singleBoardComputers?.map(sbc => ({
        type: 'Sbc',
        description: `${sbc?.bbb?.hostname || ''} ${sbc?.bbb?.macAddress || ''} ${sbc.productId} `,
        id: sbc.productId
      })) || []
    )
  }, [res.data]) || []) as CommandItemRow[]
}
