import { PageHeaderChildPages } from '@/components/PageHeader'
import { create } from 'zustand'

interface PageHeaderProps {
  pages?: PageHeaderChildPages
  setPages: (pages: PageHeaderChildPages) => void
  removePages: () => void
}

export const usePageHeader = create<PageHeaderProps>(set => ({
  pages: undefined,
  setPages: (pages: PageHeaderChildPages) => {
    set(() => ({ pages }))
  },
  removePages: () => {
    set({ pages: undefined })
  }
}))
