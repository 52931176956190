import { GetSearchableFacilityQueryVariables, useGetSearchableFacilityQuery } from '@/api'
import { useGraphQLError } from '@/hooks/useGraphqlError'
import { useEffect, useMemo } from 'react'
import { CommandItemRow } from './types'

export const useFacilityCommandGroupData = ({
  where,
  paused
}: {
  where: GetSearchableFacilityQueryVariables | null
  paused: boolean
}) => {
  const [res] = useGetSearchableFacilityQuery({
    variables: where || { searchNumber: 0, searchValue: '' },
    pause: paused
  })
  const [, setErrors] = useGraphQLError()
  useEffect(() => {
    if (res.error) {
      setErrors(res.error)
    }
  }, [res.error, setErrors])

  return (useMemo(() => {
    const facilities = res?.data?.facilities
    return (
      facilities?.map(facility => {
        return {
          type: 'Facility',
          description: `${facility.name} (${facility.id})`,
          id: facility.id
        }
      }) || []
    )
  }, [res.data]) || []) as CommandItemRow[]
}
