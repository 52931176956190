export const FspSplash = () => {
  return (
    <div
      className='flex h-screen items-center justify-center'
      style={{
        background: '#1A1A1B'
      }}
    >
      <img className='h-40 w-40 animate-bounce' src='/fsp-logo.png' />
    </div>
  )
}
