import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CombinedError } from 'urql'
import { toast } from '../components/ui/use-toast'

export const useGraphQLError = (error?: CombinedError) => {
  const [errors, setErrors] = useState<CombinedError | undefined>(error)
  const { t } = useTranslation()
  useEffect(() => {
    errors?.graphQLErrors?.map(error => {
      toast({
        variant: 'destructive',
        title: errors?.message,
        description: (
          <>
            <ul>
              <li>
                {t('errors.resource-requested')} {error.path?.join('.')}
              </li>
              <li>
                {t('errors.code')} {error.extensions?.code as string}
              </li>
              {/* <li>Service: {error.extensions.serviceName as string}</li> */}
              {/* <li>IssueId: {error.extensions.sentryEventId as string}</li> */}
            </ul>
          </>
        )
      })
    })
  }, [errors, t])

  return [errors, setErrors] as const
}
