import { TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { RiArrowDownSLine, RiArrowRightSLine, RiArrowUpSLine } from '@remixicon/react'
import { flexRender } from '@tanstack/react-table'
import useTable from './useTable'

const THead = () => {
  const table = useTable()

  if (!table) return null
  return (
    <TableHeader>
      {table.getHeaderGroups().map(headerGroup => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map(header => {
            return (
              <TableHead
                key={header.id}
                colSpan={header.colSpan}
                {...(header.column.getCanSort()
                  ? {
                      onClick: header.column.getToggleSortingHandler(),
                      className: 'cursor-ponter'
                    }
                  : {})}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && (
                  <>
                    {header.column.getIsSorted() === 'asc' ? (
                      <RiArrowUpSLine className='inline'></RiArrowUpSLine>
                    ) : header.column.getIsSorted() === 'desc' ? (
                      <RiArrowDownSLine className='inline'></RiArrowDownSLine>
                    ) : (
                      <RiArrowRightSLine className='inline'></RiArrowRightSLine>
                    )}
                  </>
                )}
              </TableHead>
            )
          })}
        </TableRow>
      ))}
    </TableHeader>
  )
}

export default THead
