import { GetFacilityInfoQuery } from '@/api'
import { Layout } from '@/components/Navigation'
import { SearchShortCut } from '@/components/Search'
import { ThemeProvider } from '@/theme'
import { createRootRoute } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { Client } from 'urql'

export type RouteContext = {
  urql: Client
  setFacility: (f: GetFacilityInfoQuery['facility']) => void
}

export const Route = createRootRoute({
  component: () => (
    <ThemeProvider defaultTheme='dark' storageKey='ferroamp-theme'>
      <SearchShortCut />
      <Layout />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </ThemeProvider>
  )
})
