import { DataTable } from '@/components/DataTable'
import TBody from '@/components/DataTable/TBody'
import THead from '@/components/DataTable/THead'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import useUsersTable from '@/hooks/useUsersTable'
import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

export const usersSearchSchema = z.object({
  pagination: z
    .object({
      take: z.number().min(0).default(30),
      skip: z.number().min(0).default(0)
    })
    .default({}),
  orderBy: z
    .object({
      id: z.string().default('id'),
      desc: z.boolean().default(false)
    })
    .array()
    .default([]),
  where: z
    .object({
      id: z.string(),
      value: z.string()
    })
    .array()
    .default([])
})

export const Route = createFileRoute('/users/')({
  component: Component,
  validateSearch: usersSearchSchema,
  pendingComponent: () => <div>Loading component...</div>
})

function Component() {
  const { t } = useTranslation()
  const { table, fetching, error } = useUsersTable()

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('common.users')}</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable showPagination={true} fetching={fetching} error={error} table={table}>
          <THead />
          <TBody />
        </DataTable>
      </CardContent>
    </Card>
  )
}
