import { useKeycloak } from '@/hooks/useKeycloak'
import { Button } from '../ui/button'

export const NotAccess = () => {
  const keycloak = useKeycloak()

  return (
    <div
      className='absolute flex h-full w-full place-content-center items-center justify-self-center align-middle'
      style={{
        background: '#0E0E0F',
        color: 'white'
      }}
    >
      <div className='col flex flex-col place-content-center items-center justify-center gap-10 align-middle'>
        <h1 style={{ fontSize: '48px' }}>NO ACCESS</h1>
        <p>
          If you know you should have access, try logout and login again, if that doesn&apos;t work contact
          your immediate manager and she/he can pass it on to ask for permission.
        </p>

        <div className='flex flex-row space-x-4'>
          <Button
            onClick={() => {
              window.location.replace('https://portal.ferroamp.com')
            }}
          >
            Go to portal
          </Button>
          <Button onClick={() => keycloak.logout()}>Logout</Button>
        </div>
      </div>
    </div>
  )
}
