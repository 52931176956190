import { useState } from 'react'
import { SideNav } from './SideNav'
import { useNav } from './useNav'

import { useSidebar } from '@/hooks/useSidebar'
import { cn } from '@/lib/utils'
import { useAuthStore } from '@/store'
import { RiUserLine } from '@remixicon/react'
import { FeedbackButton } from '../FeedbackButton/FeedbackButton'
import { ChevronLeft, FeIcon, Logo } from '../Icons'

interface SidebarProps {
  className?: string
}

export default function Sidebar({ className }: SidebarProps) {
  const { isOpen, toggle } = useSidebar()
  const [status, setStatus] = useState(false)
  const { user } = useAuthStore()
  const NavItems = useNav({ isBottom: false })

  const handleToggle = () => {
    setStatus(true)
    toggle()
    setTimeout(() => setStatus(false), 500)
  }
  return (
    <nav
      className={cn(
        `relative z-40 hidden h-screen flex-col bg-background pt-0 md:flex`,
        status && 'duration-500',
        isOpen ? 'w-72' : 'w-[78px]',
        className
      )}
    >
      <div className='relative h-screen flex-col bg-white p-0 dark:bg-white/5 md:flex'>
        <div className='group relative flex h-[70px] flex-row items-center justify-start border-b border-white/5 px-6'>
          <FeIcon type='fspIconNew' className='h-[30px] w-[30px] min-w-[30px] rounded-full bg-primary p-2' />
          <Logo
            className={
              'ml-5 h-[28px] duration-200' +
              (!isOpen &&
                'hidden text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100')
            }
          />
        </div>
        <ChevronLeft
          className={cn(
            'absolute -right-3 top-20 cursor-pointer rounded-full border border-input bg-background text-3xl text-foreground hover:bg-input',
            !isOpen && 'rotate-180'
          )}
          onClick={handleToggle}
        />

        <div className='space-y-4 overflow-x-hidden py-4'>
          <div className='px-3 py-2'>
            <div className='mt-3 space-y-1'>
              <SideNav
                className='text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100'
                items={NavItems}
              />
            </div>
          </div>
        </div>
        <span className='flex-1' />

        <div className='space-y-4 overflow-x-hidden py-4'>
          <div className='px-3 py-2'>
            <div className='mt-3 space-y-1'>
              <FeedbackButton />
              {user && (
                <SideNav
                  items={[
                    {
                      Icon: RiUserLine,
                      title: `${user.firstName} ${user.lastName}`,
                      href: '/profile/'
                    }
                  ]}
                  className='text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
