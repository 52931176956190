import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
// import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import { DEFAULT_LANGUAGE, LanguageType, SupportedLanguages, resources, supportedLngs } from './locales'
export { supportedLngs }
i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use({
    type: 'backend',
    read<Namespace extends keyof typeof resources>(
      language: SupportedLanguages,
      namespace: Namespace,
      callback: (errorValue: unknown, translations: null | LanguageType) => void
    ) {
      import(`./locales/${language}/${namespace}.json`)
        .then(resources => {
          callback(null, resources)
        })
        .catch(error => {
          callback(error, null)
        })
    }
  })
  .init({
    lng: DEFAULT_LANGUAGE,
    supportedLngs,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
