import { useTheme } from '@/theme'

export const useFerroampTheme = () => {
  const { theme } = useTheme()

  const isTheme =
    theme === 'system'
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
      : theme
  return isTheme
}
