import { GetSearchableUsersQueryVariables, useGetSearchableUsersQuery } from '@/api'
import { useMemo } from 'react'
import { CommandItemRow } from './types'

export const useUserCommandGroupData = ({
  where,
  paused
}: {
  where: GetSearchableUsersQueryVariables | null
  paused: boolean
}) => {
  const [res] = useGetSearchableUsersQuery({
    variables: { searchValue: where?.searchValue || '' },
    pause: paused
  })

  return (useMemo(() => {
    const user = res?.data?.user

    const users = res?.data?.users?.map(u => ({
      type: 'User',
      description: `${u.name} (${u.email})`,
      id: u.id
    }))

    return [
      ...(users ? users : []),
      ...(user
        ? [
            {
              type: 'User',
              description: `${user?.name} (${user?.email})`,
              id: user?.id
            }
          ]
        : [])
    ]
  }, [res.data]) || []) as CommandItemRow[]
}
