'use client'
import { PropsWithChildren } from 'react'

import { Table } from '@/components/ui/table'

import { CombinedError } from 'urql'
import { TableContext } from './Context'
import { DataTablePagination } from './data-table-pagination'
import { DataTableToolbar } from './data-table-toolbar'
import { t_table } from './types'

interface IProps<T> {
  table: t_table<T>
  fetching: boolean
  showPagination: boolean
  error: CombinedError | undefined
}

export function DataTable<T>({ children, table, showPagination }: PropsWithChildren<IProps<T>>) {
  return (
    <TableContext.Provider value={{ table }}>
      <div className='space-y-4'>
        <DataTableToolbar />
        <div className=''>
          <Table>{children}</Table>
        </div>
        {showPagination && <DataTablePagination />}
      </div>
    </TableContext.Provider>
  )
}
