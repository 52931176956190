import { useGetUserSearchQuery } from '@/api'
import { Datetime } from '@/components/DateTime'
import { Route, usersSearchSchema } from '@/routes/users'
import { Link, useNavigate } from '@tanstack/react-router'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { usePagination } from './usePagination'

type UsersSearchParams = z.infer<typeof usersSearchSchema>
type Order = UsersSearchParams['orderBy']

type UserColumns = {
  id: string
  name?: string | null
  email?: string | null
  phoneNumber?: string | null
  createdAt: Date
}

function useUsersTable() {
  const { t } = useTranslation()

  const search = Route.useSearch()

  const navigate = useNavigate({ from: Route.fullPath })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(search.where)

  const { pagination, onPaginationChange } = usePagination(search.pagination)

  const { pageSize, pageIndex } = pagination

  const skip = pageSize * pageIndex
  const take = pageSize

  const [sorting, setSorting] = useState<Order>(search.orderBy)

  const searchString = search.where.at(0)?.value || ''

  const [result] = useGetUserSearchQuery({
    variables: {
      idSearch: searchString.length == 36,
      id: searchString,
      where: { search: searchString },
      take,
      skip
    }
  })

  const { data, fetching, error } = result

  useEffect(() => {
    navigate({
      to: Route.fullPath,
      search: prev => ({
        ...prev,
        pagination: { skip, take },
        orderBy: sorting,
        where: columnFilters.map(f => ({ value: f.value as string, id: f.id }))
      })
    })
  }, [pageIndex, pageSize, sorting, columnFilters, navigate, skip, take])

  const columns: ColumnDef<UserColumns>[] = [
    {
      accessorKey: 'id',
      header: t('common.id'),
      enableSorting: false,
      cell: ({ row }) => (
        <Link to='/users/$id' params={{ id: row.getValue('id') as string }}>
          {row.getValue('id')}
        </Link>
      )
    },
    {
      accessorKey: 'name',
      header: t('common.name'),
      meta: {
        filterVariant: 'text'
      },
      enableSorting: false
    },
    {
      accessorKey: 'email',
      header: t('common.email'),
      enableSorting: false
    },
    {
      accessorKey: 'phoneNumber',
      header: t('common.phone-number'),
      enableSorting: false
    },
    {
      accessorKey: 'createdAt',
      header: t('common.created-at'),
      cell: ({ row }) => <Datetime date={row.getValue('createdAt')} />,
      enableSorting: false
    }
  ]

  const state = {
    pagination,
    sorting,
    columnFilters
  }

  const rows = [...(data?.users || []), ...[data?.user]].filter(
    (a: UserColumns | undefined | null) => a
  ) as UserColumns[]

  const table = useReactTable({
    data: rows,
    columns,
    state,
    rowCount: data?.usersConnection.totalCount || 0,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableFilters: true,
    onSortingChange: setSorting,
    onPaginationChange,
    onColumnFiltersChange: setColumnFilters
  })

  return { table, fetching, error }
}

export default useUsersTable
