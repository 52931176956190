import * as en from './en/translation.json'
import * as sv from './sv/translation.json'

import * as klingon from './klingon/translation.json'

export const DEFAULT_LANGUAGE = 'en'
export type SupportedLanguages = 'en' | 'sv' | 'klingon'
export const supportedLngs: SupportedLanguages[] = ['en', 'sv', 'klingon'] as const
export type LanguageType = typeof en
export const resources: Record<SupportedLanguages, typeof en> = {
  en,
  sv,
  klingon
}
