import {
  RiArrowLeftDoubleLine,
  RiArrowLeftLine,
  RiArrowRightDoubleLine,
  RiArrowRightLine
} from '@remixicon/react'

import { Button } from '@/components/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useTranslation } from 'react-i18next'
import useTable from './useTable'

export function DataTablePagination() {
  const { t } = useTranslation()
  const table = useTable()

  if (!table) return null

  const currentPage = table.getState().pagination.pageIndex + 1
  const totalPage = table.getPageCount()
  const pageSize = table.getState().pagination.pageSize

  return (
    <div className='flex items-center px-2 lg:justify-between'>
      <div className='flex-none text-sm text-muted-foreground lg:flex-1' />
      <div className='flex items-center space-x-6 lg:space-x-8'>
        <div className='flex items-center space-x-2'>
          <p className='hidden text-sm font-medium lg:block'>{t('data_table.row-per-page')}</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={value => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className='h-8 w-[70px]'>
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side='top'>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className='w-54 flex items-center justify-center text-sm font-medium'>
          {currentPage}/{totalPage}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => {
              table.setPageIndex(0)
            }}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>{t('data_table.sr-only.first-page')}</span>
            <RiArrowLeftDoubleLine className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => {
              table.previousPage()
            }}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>{t('data_table.sr-only.previous-page')}</span>
            <RiArrowLeftLine className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => {
              table.nextPage()
            }}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>{t('data_table.sr-only.next-page')}</span>
            <RiArrowRightLine className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1)
            }}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>{t('data_table.sr-only.last-page')}</span>
            <RiArrowRightDoubleLine className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  )
}
