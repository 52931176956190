import { TableBody, TableCell, TableRow } from '@/components/ui/table'
import { flexRender } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import useTable from './useTable'

const TBody = () => {
  const { t } = useTranslation()
  const table = useTable()

  if (!table) return null
  return (
    <TableBody>
      {table.getRowModel().rows?.length ? (
        table.getRowModel().rows.map(row => (
          <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={table.getAllColumns().length} className='h-24 text-center'>
            {t('data_table.no-results')}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default TBody
