import { useAppConfigStore, useAuthStore } from '@/store'
import { authExchange } from '@urql/exchange-auth'
import { SubscribePayload, createClient as createWSClient } from 'graphql-ws'
import Keycloak from 'keycloak-js'
import { Client, cacheExchange, fetchExchange, subscriptionExchange } from 'urql'

const CreateFerroampWSClient = (endpoint?: string, keycloak?: Keycloak) => {
  return createWSClient({
    url: `wss://${endpoint}/graphql/stream`,
    // retryAttempts: Infinity,
    // keepAlive: 10000,
    // shouldRetry: () => true,

    connectionParams: async () => {
      return {
        authorization: `Bearer ${keycloak?.token}`
      }
    }

    // on: {
    // 	connecting: () => {
    // 		// console.log('WS CONNECTING...')
    // 	},
    // 	connected: () => {
    // 		// console.log('WS CONNECTED')
    // 	},
    // 	error: () => {
    // 		// console.log('WS ERROR')
    // 	}
    // }
  })
}

export const CreateFerroampClient = (): Client => {
  const { keycloak } = useAuthStore.getState()
  const { config } = useAppConfigStore.getState()
  const wsClient = CreateFerroampWSClient(config.graphqlUrl, keycloak)
  return new Client({
    url: `https://${config.graphqlUrl}/graphql`,

    fetchOptions: () => {
      if (!keycloak?.authenticated) keycloak?.login()
      if (keycloak?.isTokenExpired) keycloak.updateToken()

      return {
        headers: { authorization: `Bearer ${keycloak?.token}` }
      }
    },

    exchanges: [
      cacheExchange,
      authExchange(async utils => {
        const token = keycloak?.token
        if (!keycloak?.authenticated) keycloak?.login()
        if (keycloak?.isTokenExpired()) keycloak.updateToken()

        return {
          addAuthToOperation(operation) {
            if (!token) return operation
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`
            })
          },
          willAuthError(_operation) {
            return keycloak?.isTokenExpired() || !keycloak?.authenticated
          },
          didAuthError(error, _operation) {
            const codes = ['UNAUTHENTICATED', 401]
            // check if the error was an auth error
            // this can be implemented in various ways, e.g. 401 or a special error code
            return error.graphQLErrors.some(e => codes.includes(e.extensions?.code as string | number))
          },

          async refreshAuth() {
            const tokenIsUpdated = await keycloak?.updateToken()
            if (!tokenIsUpdated) {
              keycloak?.login()
            }
          }
        }
      }),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: sink => {
              const dispose = wsClient.subscribe(operation as SubscribePayload, sink)
              return {
                unsubscribe: dispose
              }
            }
          }
        }
      })
    ]
  })
}
