import { GetFacilityInfoQuery } from '@/api'
import { create } from 'zustand'

type FacilityQueryResponse = GetFacilityInfoQuery['facility']
type CurrentFacility = {
  facility: FacilityQueryResponse
  setFacility: (f: FacilityQueryResponse) => void
  // fetch: (id: number | string) => void
}

/**
 * `useCurrentFacility` should only be used within routing `/facilities/${id}`.
 */
export const useCurrentFacility = create<CurrentFacility>(set => ({
  facility: null,
  setFacility: f => {
    set({ facility: f })
  }
}))
