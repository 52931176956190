import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  DateTimeISO: { input: any; output: any }
  EmailAddress: { input: any; output: any }
  FacilityDate: { input: any; output: any }
  ICalendarEvent: { input: any; output: any }
  JSON: { input: any; output: any }
  Locale: { input: any; output: any }
  PhoneNumber: { input: any; output: any }
  Test: { input: any; output: any }
}

/** Adaptive Current Equalization mode and references. The mode and reference definitions are given in */
export type AceMode = {
  __typename?: 'AceMode'
  /** Allowed values: 0, 1, 2, 3, 4, 5, 6, 7. Default 0 */
  mode: Scalars['Int']['output']
  ref1: Scalars['Float']['output']
  ref2: Scalars['Float']['output']
}

/** Adaptive Current Equalization mode and references. The mode and reference definitions are given in */
export type AceModeInput = {
  /** Allowed values: 0, 1, 2, 3, 4, 5, 6, 7. Default 0 */
  mode: Scalars['Int']['input']
  ref1: Scalars['Float']['input']
  ref2: Scalars['Float']['input']
}

export type AddAlarmSubscriberInput = {
  code: AlarmCode
  facilityId: Scalars['Int']['input']
  sendWith: SubscriptionType
  to: Scalars['String']['input']
}

export type AddAlarmSubscriberMutationResponse = {
  __typename?: 'AddAlarmSubscriberMutationResponse'
  alarmSubscriber?: Maybe<AlarmSubscriber>
  code: Scalars['Float']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  validationErrors?: Maybe<Array<ValidationError>>
}

export type AddExistingUserToFacilityDataInput = {
  /** Email for the user you want to add */
  email: Scalars['String']['input']
  /** ID for the facility */
  facilityId: Scalars['ID']['input']
  relation: FacilityUserRoles
}

export type AddExistingUserToFacilityError = FacilityNotFound | UserWithEmailNotFound | ValidationError

export type AddExistingUserToFacilityMutationResponse = {
  __typename?: 'AddExistingUserToFacilityMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<AddExistingUserToFacilityError>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<FacilityUser>
}

export type AddManyFacilitiesToPowershareInput = {
  facilities: Array<Scalars['Int']['input']>
}

export type AddManyPowershareUsersInput = {
  users: Array<PowershareUserRelationInput>
}

export type AddNewUserToFacilityDataInput = {
  /** Email for the user you want to add */
  email: Scalars['String']['input']
  /** ID for the facility */
  facilityId: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  relation: FacilityUserRoles
}

export type AddNewUserToFacilityError = FacilityNotFound | FailedToSendVerify | NewUserError | ValidationError

/** This will add a new user to the facility. The added user will receive an email with further instructions to set up their account. */
export type AddNewUserToFacilityMutationResponse = {
  __typename?: 'AddNewUserToFacilityMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<AddNewUserToFacilityError>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<FacilityUser>
}

export type AddOneUserToPowershareInputs = {
  relation: PowershareUserRoles
  userId: Scalars['String']['input']
}

export type Alarm = {
  __typename?: 'Alarm'
  entries: Array<AlarmEntry>
  logs: Array<AlarmLog>
  subscribers: Array<AlarmSubscriber>
}

export type AlarmEntriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type AlarmLogsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export type AlarmSubscribersArgs = {
  code?: AlarmCode
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}

export enum AlarmCode {
  All = 'ALL',
  SsoOffline = 'SSO_OFFLINE',
  SystemOffline = 'SYSTEM_OFFLINE'
}

export type AlarmEntry = {
  __typename?: 'AlarmEntry'
  activatedAt?: Maybe<Scalars['DateTimeISO']['output']>
  code: AlarmCode
  productId: Scalars['String']['output']
  status: AlarmStatus
  updatedAt: Scalars['DateTimeISO']['output']
}

export type AlarmEntryWhereInput = {
  AND?: InputMaybe<Array<AlarmEntryWhereInput>>
  NOT?: InputMaybe<Array<AlarmEntryWhereInput>>
  OR?: InputMaybe<Array<AlarmEntryWhereInput>>
  activatedAt?: InputMaybe<DateTimeNullableFilter>
  code?: InputMaybe<EnumAlarmCodeFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  productId?: InputMaybe<StringFilter>
}

export type AlarmLog = {
  __typename?: 'AlarmLog'
  activatedAt: Scalars['DateTimeISO']['output']
  code: AlarmCode
  facilityId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  productId: Scalars['String']['output']
  resolvedAt?: Maybe<Scalars['DateTimeISO']['output']>
}

export type AlarmLogWhereInput = {
  AND?: InputMaybe<Array<AlarmLogWhereInput>>
  NOT?: InputMaybe<Array<AlarmLogWhereInput>>
  OR?: InputMaybe<Array<AlarmLogWhereInput>>
  activatedAt?: InputMaybe<DateTimeFilter>
  code?: InputMaybe<EnumAlarmCodeFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  id?: InputMaybe<StringFilter>
  productId?: InputMaybe<StringFilter>
  resolvedAt?: InputMaybe<DateTimeNullableFilter>
}

export enum AlarmStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type AlarmSubscriber = {
  __typename?: 'AlarmSubscriber'
  code: AlarmCode
  displayName: Scalars['String']['output']
  facilityId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  sendWith: SubscriptionType
  to: Scalars['String']['output']
}

export type AlarmSubscriberWhereInput = {
  AND?: InputMaybe<Array<AlarmSubscriberWhereInput>>
  NOT?: InputMaybe<Array<AlarmSubscriberWhereInput>>
  OR?: InputMaybe<Array<AlarmSubscriberWhereInput>>
  code?: InputMaybe<EnumAlarmCodeFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  id?: InputMaybe<StringFilter>
  sendWith?: InputMaybe<EnumSubscriptionTypeFilter>
  to?: InputMaybe<StringFilter>
}

export type AllowedFacilityLifeCycleTransitions = {
  __typename?: 'AllowedFacilityLifeCycleTransitions'
  stage: FacilityLifeCycleStages
}

export type AllowedSbcLifeCycleTransitions = {
  __typename?: 'AllowedSbcLifeCycleTransitions'
  stage: SingleBoardComputerLifeCycleStages
}

/** Retrieve version information, track modifications, compare versions, and validate operations against the API. */
export type Api = {
  __typename?: 'Api'
  changelog: Array<ApiChangelog>
  /** Get the differences between the current version and a specified version. */
  diff: Array<ApiDiff>
  /** The time when the API was last modified */
  modifiedAt: Scalars['DateTimeISO']['output']
  /** Validate an operation/document against the API. */
  validate: Array<ApiValidate>
  /** The version of the API. */
  version: Scalars['Int']['output']
}

/** Retrieve version information, track modifications, compare versions, and validate operations against the API. */
export type ApiDiffArgs = {
  compareVersion: Scalars['Int']['input']
}

/** Retrieve version information, track modifications, compare versions, and validate operations against the API. */
export type ApiValidateArgs = {
  operation: Scalars['String']['input']
}

export type ApiChangelog = {
  __typename?: 'ApiChangelog'
  changes: Array<ApiDiff>
  date: Scalars['DateTimeISO']['output']
}

export type ApiDiff = {
  __typename?: 'ApiDiff'
  /** The level of criticality of the difference. */
  level: CriticalityLevel
  /** A message describing the difference. */
  message: Scalars['String']['output']
  /** The path of the difference. */
  path: Scalars['String']['output']
}

export type ApiValidate = {
  __typename?: 'ApiValidate'
  /** Describing the validation result. */
  message: Scalars['String']['output']
  /** The type of validation result. */
  type: Scalars['String']['output']
}

/** Active Power mode and references. The mode and reference definitions are given in */
export type ApwrMode = {
  __typename?: 'ApwrMode'
  /** Allowed values: 0, 1, 2, 3, 4, 5. Default value: 1 */
  mode?: Maybe<Scalars['Int']['output']>
  ref1?: Maybe<Scalars['Float']['output']>
  ref2?: Maybe<Scalars['Float']['output']>
}

/** Active Power mode and references. The mode and reference definitions are given in */
export type ApwrModeInput = {
  /** Allowed values: 0, 1, 2, 3, 4, 5. Default value: 1 */
  mode?: InputMaybe<Scalars['Int']['input']>
  ref1?: InputMaybe<Scalars['Float']['input']>
  ref2?: InputMaybe<Scalars['Float']['input']>
}

export type BaseConfigurationWhereInput = {
  id: Scalars['Int']['input']
}

export type BaseRawDataOrderByInput = {
  day?: InputMaybe<SortOrder>
  hour?: InputMaybe<SortOrder>
  month?: InputMaybe<SortOrder>
  timestamp?: InputMaybe<SortOrder>
  year?: InputMaybe<SortOrder>
}

export type BaseRawDataWhereInput = {
  day?: InputMaybe<BaseRawDataWherePartitionInput>
  hour?: InputMaybe<BaseRawDataWherePartitionInput>
  month: BaseRawDataWherePartitionInput
  timestamp?: InputMaybe<DateTimeNullableFilter>
  year: BaseRawDataWherePartitionInput
}

export type BaseRawDataWherePartitionInput = {
  equals?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type Battery = {
  __typename?: 'Battery'
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: Maybe<Scalars['Int']['output']>
  metadata?: Maybe<Metadata>
  param?: Maybe<BatteryParam>
  /** A list of terminals of type Battery Energy Storage System (B-ESS). */
  terminals?: Maybe<Array<Maybe<BatteryTerminal>>>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a battery-terminal. */
export type BatteryAttributes = {
  __typename?: 'BatteryAttributes'
  /** Battery manufacturer identifier is used to identify the battery technology/integration. 0 = FerroESM, 1 = Nilar, 2 = Volvo, 3 = Sunwoda/PSM, 4 = Pylontech */
  manufacturer?: Maybe<Scalars['Int']['output']>
  /** This schema contains one regex per batterId that are available within the Ferroamp system. Unique product identifier for a Ferroamp integrated battery. It is generally a Serial number obtained from the BMS, and configured by the battery manufacturer at production */
  pid: Scalars['String']['output']
  /** Maximum C-rate of the battery-terminal. Computed as min(batteryCurrentMax, esoCurrentMax) * batteryVoltage / ratedCapacity. */
  ratedCRate?: Maybe<Scalars['Int']['output']>
  /** Maximum rated energy capacity (in Watt hours) of the battery-terminal. */
  ratedCapacity: Scalars['Int']['output']
  /** Maximum rated power capability (in Watts) of the battery-terminal. */
  ratedPower: Scalars['Float']['output']
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a battery-terminal. */
export type BatteryAttributesInput = {
  /** Battery manufacturer identifier is used to identify the battery technology/integration. 0 = FerroESM, 1 = Nilar, 2 = Volvo, 3 = Sunwoda/PSM, 4 = Pylontech */
  manufacturer?: InputMaybe<Scalars['Int']['input']>
  /** This schema contains one regex per batterId that are available within the Ferroamp system. Unique product identifier for a Ferroamp integrated battery. It is generally a Serial number obtained from the BMS, and configured by the battery manufacturer at production */
  pid: Scalars['String']['input']
  /** Maximum C-rate of the battery-terminal. Computed as min(batteryCurrentMax, esoCurrentMax) * batteryVoltage / ratedCapacity. */
  ratedCRate?: InputMaybe<Scalars['Int']['input']>
  /** Maximum rated energy capacity (in Watt hours) of the battery-terminal. */
  ratedCapacity: Scalars['Int']['input']
  /** Maximum rated power capability (in Watts) of the battery-terminal. */
  ratedPower: Scalars['Float']['input']
}

export type BatteryConfig = {
  __typename?: 'BatteryConfig'
  installation?: Maybe<BatteryInstallation>
  runtime?: Maybe<BatteryRuntime>
}

export type BatteryConfigInput = {
  installation?: InputMaybe<BatteryInstallationInput>
  runtime?: InputMaybe<BatteryRuntimeInput>
}

export type BatteryInput = {
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: InputMaybe<Scalars['Int']['input']>
  metadata?: InputMaybe<MetadataInput>
  param?: InputMaybe<BatteryParamInput>
  /** A list of terminals of type Battery Energy Storage System (B-ESS). */
  terminals?: InputMaybe<Array<InputMaybe<BatteryTerminalInput>>>
}

export type BatteryInstallation = {
  __typename?: 'BatteryInstallation'
  isSingle?: Maybe<Scalars['Boolean']['output']>
}

export type BatteryInstallationInput = {
  isSingle?: InputMaybe<Scalars['Boolean']['input']>
}

export type BatteryMaxCRate = {
  __typename?: 'BatteryMaxCRate'
  charge: Scalars['Int']['output']
  discharge: Scalars['Int']['output']
}

export type BatteryMaxCRateInput = {
  charge: Scalars['Int']['input']
  discharge: Scalars['Int']['input']
}

export type BatteryParam = {
  __typename?: 'BatteryParam'
  batteryResponseTime?: Maybe<Scalars['Int']['output']>
  maxCRate?: Maybe<BatteryMaxCRate>
  socLimits?: Maybe<BatterySocLimits>
}

export type BatteryParamInput = {
  batteryResponseTime?: InputMaybe<Scalars['Int']['input']>
  maxCRate?: InputMaybe<BatteryMaxCRateInput>
  socLimits?: InputMaybe<BatterySocLimitsInput>
}

export type BatteryRuntime = {
  __typename?: 'BatteryRuntime'
  droopDeadband: DroopDeadband
  droopLimits: DroopLimits
  powerLimits: PowerLimits
  runtimeMode: RuntimeMode
  socLimits: SocLimits
}

export type BatteryRuntimeInput = {
  droopDeadband: DroopDeadbandInput
  droopLimits: DroopLimitsInput
  powerLimits: PowerLimitsInput
  runtimeMode: RuntimeModeInput
  socLimits: SocLimitsInput
}

export type BatterySocLimits = {
  __typename?: 'BatterySocLimits'
  high: Scalars['Int']['output']
  low: Scalars['Int']['output']
}

export type BatterySocLimitsInput = {
  high: Scalars['Int']['input']
  low: Scalars['Int']['input']
}

export type BatteryTerminal = {
  __typename?: 'BatteryTerminal'
  attributes?: Maybe<BatteryTerminalAttributes>
  config?: Maybe<BatteryConfig>
  id?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Metadata>
  param?: Maybe<BatteryTerminalParam>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a battery-terminal. */
export type BatteryTerminalAttributes = {
  __typename?: 'BatteryTerminalAttributes'
  /** Battery manufacturer identifier is used to identify the battery technology/integration. 0 = FerroESM, 1 = Nilar, 2 = Volvo, 3 = Sunwoda/PSM, 4 = Pylontech */
  manufacturer?: Maybe<Scalars['Int']['output']>
  /** This schema contains one regex per batterId that are available within the Ferroamp system. Unique product identifier for a Ferroamp integrated battery. It is generally a Serial number obtained from the BMS, and configured by the battery manufacturer at production */
  pid: Scalars['String']['output']
  /** Maximum C-rate of the battery-terminal. Computed as min(batteryCurrentMax, esoCurrentMax) * batteryVoltage / ratedCapacity. */
  ratedCRate?: Maybe<Scalars['Int']['output']>
  /** Maximum rated energy capacity (in Watt hours) of the battery-terminal. */
  ratedCapacity: Scalars['Int']['output']
  /** Maximum rated power capability (in Watts) of the battery-terminal. */
  ratedPower: Scalars['Float']['output']
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a battery-terminal. */
export type BatteryTerminalAttributesInput = {
  /** Battery manufacturer identifier is used to identify the battery technology/integration. 0 = FerroESM, 1 = Nilar, 2 = Volvo, 3 = Sunwoda/PSM, 4 = Pylontech */
  manufacturer?: InputMaybe<Scalars['Int']['input']>
  /** This schema contains one regex per batterId that are available within the Ferroamp system. Unique product identifier for a Ferroamp integrated battery. It is generally a Serial number obtained from the BMS, and configured by the battery manufacturer at production */
  pid: Scalars['String']['input']
  /** Maximum C-rate of the battery-terminal. Computed as min(batteryCurrentMax, esoCurrentMax) * batteryVoltage / ratedCapacity. */
  ratedCRate?: InputMaybe<Scalars['Int']['input']>
  /** Maximum rated energy capacity (in Watt hours) of the battery-terminal. */
  ratedCapacity: Scalars['Int']['input']
  /** Maximum rated power capability (in Watts) of the battery-terminal. */
  ratedPower: Scalars['Float']['input']
}

export type BatteryTerminalInput = {
  attributes?: InputMaybe<BatteryTerminalAttributesInput>
  config?: InputMaybe<BatteryConfigInput>
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<MetadataInput>
  param?: InputMaybe<BatteryTerminalParamInput>
}

/** User-defined terminal parameters (high-level) for battery-terminals. */
export type BatteryTerminalParam = {
  __typename?: 'BatteryTerminalParam'
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable?: Maybe<Scalars['Boolean']['output']>
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override?: Maybe<Scalars['Boolean']['output']>
}

/** User-defined terminal parameters (high-level) for battery-terminals. */
export type BatteryTerminalParamInput = {
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable?: InputMaybe<Scalars['Boolean']['input']>
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override?: InputMaybe<Scalars['Boolean']['input']>
}

export type BeagleboardExtras = {
  __typename?: 'BeagleboardExtras'
  hostname?: Maybe<Scalars['String']['output']>
  macAddress?: Maybe<Scalars['String']['output']>
  portalRegistrationKey: Scalars['String']['output']
  productId: Scalars['ID']['output']
}

export type BeagleboardExtrasNullableRelationFilter = {
  is?: InputMaybe<BeagleboardExtrasWhereInput>
  isNot?: InputMaybe<BeagleboardExtrasWhereInput>
}

export type BeagleboardExtrasOrderByWithRelationInput = {
  hostname?: InputMaybe<SortOrderInput>
  macAddress?: InputMaybe<SortOrderInput>
  portalRegistrationKey?: InputMaybe<SortOrder>
  productId?: InputMaybe<SortOrder>
  sbc?: InputMaybe<SingleBoardComputerOrderByWithRelationInput>
}

export type BeagleboardExtrasWhereInput = {
  AND?: InputMaybe<Array<BeagleboardExtrasWhereInput>>
  NOT?: InputMaybe<Array<BeagleboardExtrasWhereInput>>
  OR?: InputMaybe<Array<BeagleboardExtrasWhereInput>>
  hostname?: InputMaybe<StringNullableFilter>
  macAddress?: InputMaybe<StringNullableFilter>
  portalRegistrationKey?: InputMaybe<StringFilter>
  productId?: InputMaybe<StringFilter>
  sbc?: InputMaybe<SingleBoardComputerNullableRelationFilter>
}

export type BessLog = {
  __typename?: 'BessLog'
  alarmFlag?: Maybe<Scalars['Float']['output']>
  batteryManufacturer: Scalars['String']['output']
  bessState?: Maybe<Scalars['Float']['output']>
  bmsState?: Maybe<Scalars['Float']['output']>
  cellTemperatureMax?: Maybe<Scalars['Float']['output']>
  cellTemperatureMin?: Maybe<Scalars['Float']['output']>
  cellVoltageMax?: Maybe<Scalars['Float']['output']>
  cellVoltageMin?: Maybe<Scalars['Float']['output']>
  errorFlag?: Maybe<Scalars['Float']['output']>
  fault?: Maybe<Scalars['Float']['output']>
  ratedCapacity?: Maybe<Scalars['Float']['output']>
  ratedPower?: Maybe<Scalars['Float']['output']>
  serial: Scalars['String']['output']
  state?: Maybe<Scalars['Float']['output']>
  stateOfCharge?: Maybe<Scalars['Float']['output']>
  stateOfHealth?: Maybe<Scalars['Float']['output']>
  status?: Maybe<Scalars['Float']['output']>
  timestamp: Scalars['DateTimeISO']['output']
  warningFlag?: Maybe<Scalars['Float']['output']>
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<NestedBoolFilter>
}

export enum ChargerType {
  Ac = 'AC',
  Dc = 'DC'
}

export type CloudApiCreateInput = {
  allowedRequests?: InputMaybe<Scalars['Int']['input']>
  bindIp?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CloudApiMutationResponse = {
  __typename?: 'CloudApiMutationResponse'
  cloudApi?: Maybe<CloudApiSettings>
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type CloudApiSettings = {
  __typename?: 'CloudApiSettings'
  allowedRequests?: Maybe<Scalars['Int']['output']>
  apiKey: Scalars['String']['output']
  bindIp?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export type CloudApiUpdateInput = {
  allowedRequests?: InputMaybe<Scalars['Int']['input']>
  bindIp?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export enum CommunicationStates {
  Current = 'CURRENT',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ConfigurationCapabilities = {
  __typename?: 'ConfigurationCapabilities'
  canBeNamed?: Maybe<Scalars['Boolean']['output']>
  canBeScheduled?: Maybe<Scalars['Boolean']['output']>
  capability: Scalars['String']['output']
  resourceType: ConfigurationResourceType
}

export enum ConfigurationCommunicationState {
  Current = 'CURRENT',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum ConfigurationResourceType {
  AncillaryService = 'ANCILLARY_SERVICE',
  EmsConfiguration = 'EMS_CONFIGURATION',
  SoftwareEhubConfiguration = 'SOFTWARE_EHUB_CONFIGURATION',
  UpstreamDataConfiguration = 'UPSTREAM_DATA_CONFIGURATION'
}

export enum ConfigurationVerb {
  Delete = 'DELETE',
  Upsert = 'UPSERT'
}

export type Configurations = {
  __typename?: 'Configurations'
  capabilities: Array<ConfigurationCapabilities>
  emsConfiguration: EmsConfigType
  id: Scalars['ID']['output']
}

export type CreateOneFacilityErrors =
  | CreateOneFacilityInvalidLifeCycleStageError
  | CreateOneFacilityNoExistingSingleBoardComputerError
  | CreateOneFacilityPendingFacilityError
  | CreateOneFacilitySingleBoardComputerTakenError

export type CreateOneFacilityGenerationOneSbcInput = {
  /** Generation 1. Register an EnergyHub System */
  registrationKey?: InputMaybe<Scalars['String']['input']>
}

export type CreateOneFacilityGenerationTwoSbcInput = {
  /** Generation 2. Register a Micro-grid System */
  productId?: InputMaybe<Scalars['String']['input']>
}

/** The single board computer must in life cycle stage: shipped */
export type CreateOneFacilityInvalidLifeCycleStageError = {
  __typename?: 'CreateOneFacilityInvalidLifeCycleStageError'
  currentStage: SingleBoardComputerLifeCycleStages
}

export type CreateOneFacilityMutationResponse = {
  __typename?: 'CreateOneFacilityMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<CreateOneFacilityErrors>>
  facility?: Maybe<Facility>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

/** No single board computer found by provided productId or registrationKey */
export type CreateOneFacilityNoExistingSingleBoardComputerError = {
  __typename?: 'CreateOneFacilityNoExistingSingleBoardComputerError'
  entry?: Maybe<Scalars['String']['output']>
}

export type CreateOneFacilityPendingFacilities = {
  __typename?: 'CreateOneFacilityPendingFacilities'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
}

/** User has exceeded number of allowed pending facility installations */
export type CreateOneFacilityPendingFacilityError = {
  __typename?: 'CreateOneFacilityPendingFacilityError'
  description: Scalars['String']['output']
  pendingFacilities: Array<CreateOneFacilityPendingFacilities>
}

/** Single board computer is already used */
export type CreateOneFacilitySingleBoardComputerTakenError = {
  __typename?: 'CreateOneFacilitySingleBoardComputerTakenError'
  description: Scalars['String']['output']
}

export type CreateOnePowershareInputs = {
  /** Facility IDs to be added to the powershare */
  facilities?: InputMaybe<Array<Scalars['Int']['input']>>
  installedOn?: InputMaybe<Scalars['DateTimeISO']['input']>
  /** Display name of the powershare */
  name: Scalars['String']['input']
  /** User which will be granted access to the powershare */
  users?: InputMaybe<Array<PowershareUserRelationInput>>
}

export enum CriticalityLevel {
  Breaking = 'Breaking',
  Dangerous = 'Dangerous',
  NonBreaking = 'NonBreaking'
}

/** A set of parameters that describe the external current sensor connected to this cluster of grid-tied inverters. */
export type CtConfig = {
  __typename?: 'CtConfig'
  /** Status of auto-configuration of external current sensors. */
  configDone?: Maybe<Scalars['Boolean']['output']>
  /** Transfer ratio of current sensor. */
  ctRatio: Scalars['Int']['output']
  /** Timestamp of the last auto-configuration of external current sensors. */
  lastConfigDate?: Maybe<Scalars['String']['output']>
}

/** A set of parameters that describe the external current sensor connected to this cluster of grid-tied inverters. */
export type CtConfigInput = {
  /** Status of auto-configuration of external current sensors. */
  configDone?: InputMaybe<Scalars['Boolean']['input']>
  /** Transfer ratio of current sensor. */
  ctRatio: Scalars['Int']['input']
  /** Timestamp of the last auto-configuration of external current sensors. */
  lastConfigDate?: InputMaybe<Scalars['String']['input']>
}

/** Active/Reactive single-phase current limits (in Amperes). Active current limits also apply to ACE. */
export type CurrentLimits = {
  __typename?: 'CurrentLimits'
  imag: Scalars['Float']['output']
  real: Scalars['Float']['output']
}

/** Active/Reactive single-phase current limits (in Amperes). Active current limits also apply to ACE. */
export type CurrentLimitsInput = {
  imag: Scalars['Float']['input']
  real: Scalars['Float']['input']
}

export type DataRetentions = {
  __typename?: 'DataRetentions'
  /** Since when we can retrieve power-data 1 minute resolution */
  powerData1HourHistory?: Maybe<Scalars['DateTimeISO']['output']>
  /** Since when we can retrieve power-data 1 minute resolution */
  powerData1MinuteHistory: Scalars['DateTimeISO']['output']
  /** Since when we can retrieve power-data 1 second resolution */
  powerData1SecondHistory: Scalars['DateTimeISO']['output']
  /** Since when we can retrieve ui-data 1 second resolution */
  uiData1SecondHistory: Scalars['DateTimeISO']['output']
}

export type DateRangeQuery = {
  /** Greater than */
  gt?: InputMaybe<Scalars['FacilityDate']['input']>
  /** Greater than or equal */
  gte?: InputMaybe<Scalars['FacilityDate']['input']>
  /** Less than */
  lt?: InputMaybe<Scalars['FacilityDate']['input']>
  /** Less than or equal */
  lte?: InputMaybe<Scalars['FacilityDate']['input']>
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>
  not?: InputMaybe<NestedDateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>
  not?: InputMaybe<NestedDateTimeNullableFilter>
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type DatetimeRangeQuery = {
  /** Greater than */
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>
  /** Greater than or equal */
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>
  /** Less than */
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>
  /** Less than or equal */
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>
}

export type DefaultEmsConfigSchedule = IEmsConfigSchedule & {
  __typename?: 'DefaultEmsConfigSchedule'
  emsParam: EmsConfig
  id: Scalars['ID']['output']
  lastApplied?: Maybe<Scalars['DateTime']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  lastUpdateBy?: Maybe<Scalars['ID']['output']>
}

export type DefaultEmsConfigScheduleInput = {
  emsParam: EmsConfigInput
}

/** Droop Voltage deadband (in Volts). */
export type DroopDeadband = {
  __typename?: 'DroopDeadband'
  left: Scalars['Float']['output']
  right: Scalars['Float']['output']
}

/** Droop Voltage deadband (in Volts). */
export type DroopDeadbandInput = {
  left: Scalars['Float']['input']
  right: Scalars['Float']['input']
}

/** Droop Power limits (in Watts). */
export type DroopLimits = {
  __typename?: 'DroopLimits'
  neg: Scalars['Float']['output']
  pos: Scalars['Float']['output']
}

/** Droop Power limits (in Watts). */
export type DroopLimitsInput = {
  neg: Scalars['Float']['input']
  pos: Scalars['Float']['input']
}

export type EditFacilityMappingDataInput = {
  /** Transitions the previous single board computers life cycle stage. */
  currentSingleBoardComputerLifeCycleStage: SingleBoardComputerLifeCycleStages
}

export type EditFacilityMappingMutationResponse = {
  __typename?: 'EditFacilityMappingMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<EditFacilitySbcMappingErrors>
  message: Scalars['String']['output']
  newMapping?: Maybe<FacilityMapping>
  previousMapping?: Maybe<FacilityMapping>
  success: Scalars['Boolean']['output']
}

export type EditFacilityMappingWhereInput = {
  currentProductId: Scalars['String']['input']
  facilityId: Scalars['String']['input']
  newProductId: Scalars['String']['input']
}

export type EditFacilitySbcMappingErrors = SbcTransitionError | SbcBadLifeCycleStageError

/** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
export type EmsBattery = {
  __typename?: 'EmsBattery'
  powerRef: EmsBatteryPowerRef
  socRef: EmsBatterySocRef
}

/** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
export type EmsBatteryInput = {
  powerRef: EmsBatteryPowerRefInput
  socRef: EmsBatterySocRefInput
}

/** Asymmetric charge/discharge power references for battery. */
export type EmsBatteryPowerRef = {
  __typename?: 'EmsBatteryPowerRef'
  charge: Scalars['Float']['output']
  discharge: Scalars['Float']['output']
}

/** Asymmetric charge/discharge power references for battery. */
export type EmsBatteryPowerRefInput = {
  charge: Scalars['Float']['input']
  discharge: Scalars['Float']['input']
}

export type EmsBatterySocRef = {
  __typename?: 'EmsBatterySocRef'
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
}

export type EmsBatterySocRefInput = {
  high: Scalars['Float']['input']
  low: Scalars['Float']['input']
}

export type EmsConfig = {
  __typename?: 'EmsConfig'
  battery?: Maybe<EmsBattery>
  grid?: Maybe<EmsGrid>
  mode: Scalars['Int']['output']
  pv?: Maybe<EmsPv>
}

export type EmsConfigBase = IConfigurationBaseType & {
  __typename?: 'EmsConfigBase'
  config: SystemApiEmsConfigurationTypes
  generation: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  rejection?: Maybe<UnifiedEvents>
  state: ConfigurationCommunicationState
  status?: Maybe<UnifiedEvents>
}

export type EmsConfigInput = {
  battery?: InputMaybe<EmsBatteryInput>
  grid?: InputMaybe<EmsGridInput>
  mode: Scalars['Int']['input']
  pv?: InputMaybe<EmsPvInput>
}

export type EmsConfigSchedule = {
  __typename?: 'EmsConfigSchedule'
  default: Scalars['Boolean']['output']
  emsParam: EmsConfig
  iCalendarEvent: Scalars['ICalendarEvent']['output']
  id: Scalars['ID']['output']
  lastApplied?: Maybe<Scalars['DateTime']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  lastUpdateBy?: Maybe<Scalars['ID']['output']>
}

export type EmsConfigScheduleDeleteResponse = {
  __typename?: 'EmsConfigScheduleDeleteResponse'
  success: Scalars['Boolean']['output']
}

export type EmsConfigScheduleInput = {
  emsParam: EmsConfigInput
  iCalendarEvent: Scalars['ICalendarEvent']['input']
}

export type EmsConfigType = {
  __typename?: 'EmsConfigType'
  current: Array<EmsConfigBase>
  history: Array<EmsConfigBase>
  pending: Array<EmsConfigBase>
  poll: Array<EmsConfigBase>
}

export type EmsConfigTypeCurrentArgs = {
  generation?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EmsConfigTypePendingArgs = {
  generation?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EmsConfigTypePollArgs = {
  generation?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

/** EMS parameters for controlling Grid-tied inverters. */
export type EmsGrid = {
  __typename?: 'EmsGrid'
  /** EMS parameters for controlling Adaptive Current Equalization (ACE) functionality. */
  ace?: Maybe<EmsGridAce>
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['output']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['output']
  /** Asymmetric power thresholds for grid-feedback control. (thresholdHigh >= thresholdLow) */
  thresholds?: Maybe<EmsGridThresholds>
}

/** EMS parameters for controlling Grid-tied inverters. */
export type EmsGridAce = {
  __typename?: 'EmsGridAce'
  /** The mode identifier is used to select an ACE operation use-case. 0 = ACE Disabled; 1 = ACE Enabled */
  mode: Scalars['Int']['output']
  /** A symmetric current threshold below which ACE is disabled. */
  threshold: Scalars['Float']['output']
}

/** EMS parameters for controlling Grid-tied inverters. */
export type EmsGridAceInput = {
  /** The mode identifier is used to select an ACE operation use-case. 0 = ACE Disabled; 1 = ACE Enabled */
  mode: Scalars['Int']['input']
  /** A symmetric current threshold below which ACE is disabled. */
  threshold: Scalars['Float']['input']
}

/** EMS parameters for controlling Grid-tied inverters. */
export type EmsGridInput = {
  /** EMS parameters for controlling Adaptive Current Equalization (ACE) functionality. */
  ace?: InputMaybe<EmsGridAceInput>
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['input']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['input']
  /** Asymmetric power thresholds for grid-feedback control. (thresholdHigh >= thresholdLow) */
  thresholds?: InputMaybe<EmsGridThresholdsInput>
}

export type EmsGridThresholds = {
  __typename?: 'EmsGridThresholds'
  high: Scalars['Float']['output']
  low: Scalars['Float']['output']
}

export type EmsGridThresholdsInput = {
  high: Scalars['Float']['input']
  low: Scalars['Float']['input']
}

/** EMS parameters for controlling PV. */
export type EmsPv = {
  __typename?: 'EmsPv'
  mode: Scalars['Int']['output']
}

/** EMS parameters for controlling PV. */
export type EmsPvInput = {
  mode: Scalars['Int']['input']
}

export enum EnergyDataSeriesType {
  Accumulated = 'Accumulated',
  Relative = 'Relative'
}

/** Desired timeperiod of data for given date range */
export enum EnergyDatePeriod {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export type EnergyDateRangeInput = {
  /**
   * For Daily, Weekly, Monthly and Yearly data.
   * Timezone relative to facility timezone, meaning 00:00 is defined as midnight
   * in the facility's timezone.
   */
  range: DateRangeQuery
  resolution?: EnergyDatePeriod
}

/** Desired timeperiod of data for given date-time range */
export enum EnergyDateTimePeriod {
  Hourly = 'Hourly',
  QuarterHour = 'QuarterHour'
}

export type EnergyDateTimeRangeInput = {
  /** For QuarterHourly and Hourly data only. Timezone is always UTC */
  range: DatetimeRangeQuery
  resolution?: EnergyDateTimePeriod
}

export type EnergyPeriodInput = {
  date?: InputMaybe<EnergyDateRangeInput>
  dateTime?: InputMaybe<EnergyDateTimeRangeInput>
}

export type EnergyStorage = {
  __typename?: 'EnergyStorage'
  /** Rated capacity of the sum of all energy storages installed at the facility, in [wH] */
  ratedCapacity: Scalars['Float']['output']
  /** State of charge in percentage [%] */
  soc: Scalars['Float']['output']
  /** State of health in [%] */
  soh: Scalars['Float']['output']
  /** Timestamp of data */
  timestamp: Scalars['DateTimeISO']['output']
}

/** Converts into specified unit of energy */
export enum EnergyUnit {
  MWh = 'MWh',
  Wh = 'Wh',
  KWh = 'kWh',
  MJ = 'mJ'
}

export type EnumAlarmCodeFilter = {
  equals?: InputMaybe<AlarmCode>
  in?: InputMaybe<Array<AlarmCode>>
  not?: InputMaybe<NestedEnumAlarmCodeFilter>
  notIn?: InputMaybe<Array<AlarmCode>>
}

export type EnumCommunicationStatesNullableFilter = {
  equals?: InputMaybe<CommunicationStates>
  in?: InputMaybe<Array<CommunicationStates>>
  not?: InputMaybe<NestedEnumCommunicationStatesNullableFilter>
  notIn?: InputMaybe<Array<CommunicationStates>>
}

export type EnumConfigurationVerbFilter = {
  equals?: InputMaybe<ConfigurationVerb>
  in?: InputMaybe<Array<ConfigurationVerb>>
  not?: InputMaybe<NestedEnumConfigurationVerbFilter>
  notIn?: InputMaybe<Array<ConfigurationVerb>>
}

export type EnumFacilityLifeCycleStagesFilter = {
  equals?: InputMaybe<FacilityLifeCycleStages>
  in?: InputMaybe<Array<FacilityLifeCycleStages>>
  not?: InputMaybe<NestedEnumFacilityLifeCycleStagesFilter>
  notIn?: InputMaybe<Array<FacilityLifeCycleStages>>
}

export type EnumFacilityUserRolesFilter = {
  equals?: InputMaybe<FacilityUserRoles>
  in?: InputMaybe<Array<FacilityUserRoles>>
  not?: InputMaybe<NestedEnumFacilityUserRolesFilter>
  notIn?: InputMaybe<Array<FacilityUserRoles>>
}

export type EnumPowershareUserRolesFilter = {
  equals?: InputMaybe<PowershareUserRoles>
  in?: InputMaybe<Array<PowershareUserRoles>>
  not?: InputMaybe<NestedEnumPowershareUserRolesFilter>
  notIn?: InputMaybe<Array<PowershareUserRoles>>
}

export type EnumResourceTypeFilter = {
  equals?: InputMaybe<ResourceType>
  in?: InputMaybe<Array<ResourceType>>
  not?: InputMaybe<NestedEnumResourceTypeFilter>
  notIn?: InputMaybe<Array<ResourceType>>
}

export type EnumSingleBoardComputerLifeCycleStagesFilter = {
  equals?: InputMaybe<SingleBoardComputerLifeCycleStages>
  in?: InputMaybe<Array<SingleBoardComputerLifeCycleStages>>
  not?: InputMaybe<NestedEnumSingleBoardComputerLifeCycleStagesFilter>
  notIn?: InputMaybe<Array<SingleBoardComputerLifeCycleStages>>
}

export type EnumSubscriptionTypeFilter = {
  equals?: InputMaybe<SubscriptionType>
  in?: InputMaybe<Array<SubscriptionType>>
  not?: InputMaybe<NestedEnumSubscriptionTypeFilter>
  notIn?: InputMaybe<Array<SubscriptionType>>
}

export type EpocLog = {
  __typename?: 'EpocLog'
  epocRelativeTime?: Maybe<Scalars['Float']['output']>
  errorCode?: Maybe<Scalars['Float']['output']>
  pid: Scalars['String']['output']
  timestamp: Scalars['DateTimeISO']['output']
}

/** Namespace for application that emits the event. Useful to know what component/application are triggering what events. */
export enum EventNamespace {
  Battery = 'Battery',
  Ecloud = 'Ecloud',
  Inverter = 'Inverter',
  Photovoltaic = 'Photovoltaic',
  SbcCoprocessor = 'SbcCoprocessor',
  SingleBoardComputer = 'SingleBoardComputer'
}

/** Granual information on emitter of event within namespace */
export enum EventSource {
  BatteryEnergyStorageSystem = 'BatteryEnergyStorageSystem',
  ConfigCommunicator = 'ConfigCommunicator',
  CtConfiguration = 'CtConfiguration',
  EcloudCommunicator = 'EcloudCommunicator',
  EhubEcloudGateway = 'EhubEcloudGateway',
  EnergyStorageOptimizer = 'EnergyStorageOptimizer',
  Main = 'Main',
  PowerUnitProxy = 'PowerUnitProxy',
  SystemConfigurationManager = 'SystemConfigurationManager',
  SystemStateControl = 'SystemStateControl',
  Systemd = 'Systemd',
  YetAnotherDataAggregator = 'YetAnotherDataAggregator',
  YetAnotherSystemState = 'YetAnotherSystemState'
}

export type Evse = {
  __typename?: 'Evse'
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: Maybe<Scalars['Int']['output']>
  metadata?: Maybe<Metadata>
  param?: Maybe<EvseParam>
  terminals?: Maybe<Array<Maybe<EvseTerminal>>>
}

export type EvseAttributes = {
  __typename?: 'EvseAttributes'
  pid?: Maybe<Scalars['String']['output']>
  ratedPower?: Maybe<Scalars['Float']['output']>
}

export type EvseAttributesInput = {
  pid?: InputMaybe<Scalars['String']['input']>
  ratedPower?: InputMaybe<Scalars['Float']['input']>
}

export type EvseInput = {
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: InputMaybe<Scalars['Int']['input']>
  metadata?: InputMaybe<MetadataInput>
  param?: InputMaybe<EvseParamInput>
  terminals?: InputMaybe<Array<InputMaybe<EvseTerminalInput>>>
}

export type EvseMeterValues = {
  __typename?: 'EvseMeterValues'
  connectorId?: Maybe<Scalars['Float']['output']>
  currentL1?: Maybe<Scalars['Float']['output']>
  currentL2?: Maybe<Scalars['Float']['output']>
  currentL3?: Maybe<Scalars['Float']['output']>
  energyActiveMeter?: Maybe<Scalars['Float']['output']>
  powerActive?: Maybe<Scalars['Float']['output']>
  terminalId: Scalars['String']['output']
  timestamp: Scalars['DateTimeISO']['output']
}

export type EvseParam = {
  __typename?: 'EvseParam'
  chargerType: ChargerType
}

export type EvseParamInput = {
  chargerType: ChargerType
}

export type EvseTerminal = {
  __typename?: 'EvseTerminal'
  attributes?: Maybe<EvseTerminalAttributes>
  config?: Maybe<EvseTerminalConfig>
  id?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Metadata>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of an EVSE-terminal. */
export type EvseTerminalAttributes = {
  __typename?: 'EvseTerminalAttributes'
  pid: Scalars['String']['output']
  /** Maximum rated power capability (in Watts) of the EVSE-terminal. */
  ratedPower?: Maybe<Scalars['Float']['output']>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of an EVSE-terminal. */
export type EvseTerminalAttributesInput = {
  pid: Scalars['String']['input']
  /** Maximum rated power capability (in Watts) of the EVSE-terminal. */
  ratedPower?: InputMaybe<Scalars['Float']['input']>
}

export type EvseTerminalConfig = {
  __typename?: 'EvseTerminalConfig'
  limitProfile?: Maybe<EvseTerminalConfigLimitProfile>
  runtime?: Maybe<EvseTerminalConfigRuntime>
}

export type EvseTerminalConfigInput = {
  limitProfile?: InputMaybe<EvseTerminalConfigLimitProfileInput>
  runtime?: InputMaybe<EvseTerminalConfigRuntimeInput>
}

export type EvseTerminalConfigLimitProfile = {
  __typename?: 'EvseTerminalConfigLimitProfile'
  level?: Maybe<Scalars['Int']['output']>
  purpose?: Maybe<EvseTerminalConfigLimitProfilePurpose>
}

export type EvseTerminalConfigLimitProfileInput = {
  level?: InputMaybe<Scalars['Int']['input']>
  purpose?: InputMaybe<EvseTerminalConfigLimitProfilePurposeInput>
}

export enum EvseTerminalConfigLimitProfilePurpose {
  ChargePointMaxProfile = 'ChargePointMaxProfile',
  TxDefaultProfile = 'TxDefaultProfile',
  TxProfile = 'TxProfile'
}

export enum EvseTerminalConfigLimitProfilePurposeInput {
  ChargePointMaxProfile = 'ChargePointMaxProfile',
  TxDefaultProfile = 'TxDefaultProfile',
  TxProfile = 'TxProfile'
}

export type EvseTerminalConfigRuntime = {
  __typename?: 'EvseTerminalConfigRuntime'
  triggerMeterValues?: Maybe<EvseTerminalConfigRuntimeOnOffAuto>
}

export type EvseTerminalConfigRuntimeInput = {
  triggerMeterValues?: InputMaybe<EvseTerminalConfigRuntimeOnOffAutoInput>
}

export enum EvseTerminalConfigRuntimeOnOffAuto {
  Auto = 'auto',
  Off = 'off',
  On = 'on'
}

export enum EvseTerminalConfigRuntimeOnOffAutoInput {
  Auto = 'auto',
  Off = 'off',
  On = 'on'
}

export type EvseTerminalInput = {
  attributes?: InputMaybe<EvseTerminalAttributesInput>
  config?: InputMaybe<EvseTerminalConfigInput>
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<MetadataInput>
}

export type FacilitiesConnection = IOffsetPagination & {
  __typename?: 'FacilitiesConnection'
  edges: Array<Facility>
  totalCount: Scalars['Int']['output']
}

export type Facility = {
  __typename?: 'Facility'
  _count?: Maybe<FacilityCount>
  alarm: Alarm
  capacity?: Maybe<FacilityCapacity>
  configurations: Configurations
  createdAt: Scalars['DateTimeISO']['output']
  defaultEmsConfigSchedule?: Maybe<DefaultEmsConfigSchedule>
  discontinuedAt?: Maybe<Scalars['DateTimeISO']['output']>
  emsConfig?: Maybe<EmsConfig>
  emsConfigSchedules?: Maybe<Array<Maybe<EmsConfigSchedule>>>
  features: Array<FacilityFeatures>
  history: Array<FacilityHistory>
  hostname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  info?: Maybe<FacilityInfo>
  installedOn?: Maybe<Scalars['DateTimeISO']['output']>
  lastUpdatedBy?: Maybe<FacilityUser>
  lifeCycle: FacilityLifeCycles
  location?: Maybe<FacilityLocation>
  logs: Logs
  measurements: Measurements
  misc?: Maybe<FacilityMisc>
  name: Scalars['String']['output']
  /** only available for facilities connected in a powershare */
  powershare?: Maybe<Powershare>
  settings: FacilitySettings
  /** The single board computer which acts as the interface to eCloud from the facility */
  singleBoardComputer?: Maybe<SingleBoardComputer>
  ssos?: Maybe<Array<Maybe<SsoProduct>>>
  systemControlTopologyConfiguration?: Maybe<SystemControlTopologyConfiguration>
  timezone?: Maybe<Scalars['String']['output']>
  uiComponents: UiComponents
  updatedAt: Scalars['DateTimeISO']['output']
  users: Array<FacilityUser>
}

export type FacilityEmsConfigSchedulesArgs = {
  excludeDefault?: InputMaybe<Scalars['Boolean']['input']>
}

export type FacilityHistoryArgs = {
  orderBy?: InputMaybe<Array<FacilityHistoryEntryOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityHistoryEntryWhereWithTableFilterInput>
}

export type FacilityCapacity = {
  __typename?: 'FacilityCapacity'
  installedPv?: Maybe<Scalars['Float']['output']>
}

export type FacilityCapacityCreateWithoutFacilityInput = {
  installedPv?: InputMaybe<Scalars['Float']['input']>
}

export type FacilityCapacityNullableRelationFilter = {
  is?: InputMaybe<FacilityCapacityWhereInput>
  isNot?: InputMaybe<FacilityCapacityWhereInput>
}

export type FacilityCapacityOrderByWithRelationInput = {
  installedPv?: InputMaybe<SortOrderInput>
}

export type FacilityCapacityWhereInput = {
  AND?: InputMaybe<Array<FacilityCapacityWhereInput>>
  NOT?: InputMaybe<Array<FacilityCapacityWhereInput>>
  OR?: InputMaybe<Array<FacilityCapacityWhereInput>>
  installedPv?: InputMaybe<FloatNullableFilter>
}

export type FacilityConfigurationCapabilitiesWhereInput = {
  AND?: InputMaybe<Array<FacilityConfigurationCapabilitiesWhereInput>>
  NOT?: InputMaybe<Array<FacilityConfigurationCapabilitiesWhereInput>>
  OR?: InputMaybe<Array<FacilityConfigurationCapabilitiesWhereInput>>
  canBeNamed?: InputMaybe<BoolFilter>
  canBeScheduled?: InputMaybe<BoolFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  resourceType?: InputMaybe<EnumResourceTypeFilter>
  schemaContentId?: InputMaybe<IntFilter>
}

export type FacilityConfigurationStatusWhereInput = {
  AND?: InputMaybe<Array<FacilityConfigurationStatusWhereInput>>
  NOT?: InputMaybe<Array<FacilityConfigurationStatusWhereInput>>
  OR?: InputMaybe<Array<FacilityConfigurationStatusWhereInput>>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  generation?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  resourceType?: InputMaybe<EnumResourceTypeFilter>
  schemaContentId?: InputMaybe<IntNullableFilter>
  status?: InputMaybe<JsonNullableFilter>
}

export type FacilityConfigurationWhereInput = {
  AND?: InputMaybe<Array<FacilityConfigurationWhereInput>>
  NOT?: InputMaybe<Array<FacilityConfigurationWhereInput>>
  OR?: InputMaybe<Array<FacilityConfigurationWhereInput>>
  applicationInstanceId?: InputMaybe<StringFilter>
  communicationState?: InputMaybe<EnumCommunicationStatesNullableFilter>
  configuration?: InputMaybe<JsonNullableFilter>
  configurationContentId?: InputMaybe<IntFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  generation?: InputMaybe<StringFilter>
  historic?: InputMaybe<BoolFilter>
  name?: InputMaybe<StringFilter>
  rejectionEvent?: InputMaybe<JsonNullableFilter>
  rejectionEventContentId?: InputMaybe<IntNullableFilter>
  resourceType?: InputMaybe<EnumResourceTypeFilter>
  verb?: InputMaybe<EnumConfigurationVerbFilter>
}

export type FacilityCount = {
  __typename?: 'FacilityCount'
  FacilityConfiguration: Scalars['Int']['output']
  FacilityConfigurationCapabilities: Scalars['Int']['output']
  FacilityConfigurationStatus: Scalars['Int']['output']
  alarmEntries: Scalars['Int']['output']
  alarmLogs: Scalars['Int']['output']
  alarmSubscribers: Scalars['Int']['output']
  facilityUserMapping: Scalars['Int']['output']
  history: Scalars['Int']['output']
  lifeCycle: Scalars['Int']['output']
  powershareFacilityMapping: Scalars['Int']['output']
}

export type FacilityCountFacilityConfigurationArgs = {
  where?: InputMaybe<FacilityConfigurationWhereInput>
}

export type FacilityCountFacilityConfigurationCapabilitiesArgs = {
  where?: InputMaybe<FacilityConfigurationCapabilitiesWhereInput>
}

export type FacilityCountFacilityConfigurationStatusArgs = {
  where?: InputMaybe<FacilityConfigurationStatusWhereInput>
}

export type FacilityCountAlarmEntriesArgs = {
  where?: InputMaybe<AlarmEntryWhereInput>
}

export type FacilityCountAlarmLogsArgs = {
  where?: InputMaybe<AlarmLogWhereInput>
}

export type FacilityCountAlarmSubscribersArgs = {
  where?: InputMaybe<AlarmSubscriberWhereInput>
}

export type FacilityCountFacilityUserMappingArgs = {
  where?: InputMaybe<FacilityUserMappingWhereInput>
}

export type FacilityCountHistoryArgs = {
  where?: InputMaybe<FacilityHistoryEntryWhereInput>
}

export type FacilityCountLifeCycleArgs = {
  where?: InputMaybe<FacilityLifeCycleWhereInput>
}

export type FacilityCountPowershareFacilityMappingArgs = {
  where?: InputMaybe<PowershareFacilityWhereInput>
}

export type FacilityCreateInput = {
  capacity?: InputMaybe<FacilityCapacityCreateWithoutFacilityInput>
  installedOn?: InputMaybe<Scalars['DateTimeISO']['input']>
  location?: InputMaybe<FacilityLocationCreateWithoutFacilityInput>
  misc?: InputMaybe<FacilityMiscCreateWithoutFacilityInput>
  name: Scalars['String']['input']
  timezone: Scalars['String']['input']
}

export type FacilityCurrentLifeCycleNullableRelationFilter = {
  is?: InputMaybe<FacilityCurrentLifeCycleWhereInput>
  isNot?: InputMaybe<FacilityCurrentLifeCycleWhereInput>
}

export type FacilityCurrentLifeCycleOrderByWithRelationInput = {
  createdBy?: InputMaybe<SortOrderInput>
  facility?: InputMaybe<FacilityOrderByWithRelationInput>
  facilityId?: InputMaybe<SortOrder>
  stage?: InputMaybe<SortOrder>
  updatedBy?: InputMaybe<SortOrderInput>
}

export type FacilityCurrentLifeCycleWhereInput = {
  AND?: InputMaybe<Array<FacilityCurrentLifeCycleWhereInput>>
  NOT?: InputMaybe<Array<FacilityCurrentLifeCycleWhereInput>>
  OR?: InputMaybe<Array<FacilityCurrentLifeCycleWhereInput>>
  createdBy?: InputMaybe<StringNullableFilter>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  stage?: InputMaybe<EnumFacilityLifeCycleStagesFilter>
  updatedBy?: InputMaybe<StringNullableFilter>
}

/** The different features a facility has depending on system version */
export enum FacilityFeatures {
  EmsConfigV2 = 'EmsConfigV2',
  SystemControlTopology = 'SystemControlTopology'
}

export type FacilityHistory = {
  __typename?: 'FacilityHistory'
  changes: Array<FacilityHistoryChange>
  correlationId: Scalars['String']['output']
  createdAt: Scalars['DateTimeISO']['output']
  userId?: Maybe<Scalars['ID']['output']>
}

export type FacilityHistoryChange = {
  path: Scalars['String']['output']
}

export type FacilityHistoryChangeDelete = FacilityHistoryChange & {
  __typename?: 'FacilityHistoryChangeDelete'
  before?: Maybe<Scalars['String']['output']>
  path: Scalars['String']['output']
}

export enum FacilityHistoryChangeFilter {
  Capacity = 'Capacity',
  Facility = 'Facility',
  LifeCycle = 'LifeCycle',
  Location = 'Location',
  Misc = 'Misc',
  User = 'User'
}

export type FacilityHistoryChangeInsert = FacilityHistoryChange & {
  __typename?: 'FacilityHistoryChangeInsert'
  after?: Maybe<Scalars['String']['output']>
  path: Scalars['String']['output']
}

export type FacilityHistoryChangeUpdate = FacilityHistoryChange & {
  __typename?: 'FacilityHistoryChangeUpdate'
  after?: Maybe<Scalars['String']['output']>
  before?: Maybe<Scalars['String']['output']>
  path: Scalars['String']['output']
}

export type FacilityHistoryEntryOrderByWithRelationInput = {
  appId?: InputMaybe<SortOrderInput>
  correlationId?: InputMaybe<SortOrder>
  userId?: InputMaybe<SortOrderInput>
}

export type FacilityHistoryEntryWhereInput = {
  AND?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  NOT?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  OR?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  appId?: InputMaybe<StringNullableFilter>
  correlationId?: InputMaybe<StringFilter>
  userId?: InputMaybe<StringNullableFilter>
}

export type FacilityHistoryEntryWhereWithTableFilterInput = {
  AND?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  NOT?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  OR?: InputMaybe<Array<FacilityHistoryEntryWhereInput>>
  appId?: InputMaybe<StringNullableFilter>
  changesIn?: InputMaybe<Array<FacilityHistoryChangeFilter>>
  correlationId?: InputMaybe<StringFilter>
  userId?: InputMaybe<StringNullableFilter>
}

export type FacilityInfo = {
  __typename?: 'FacilityInfo'
  version?: Maybe<Scalars['String']['output']>
}

export type FacilityLifeCycle = {
  __typename?: 'FacilityLifeCycle'
  stage: FacilityLifeCycleStages
  updatedAt: Scalars['DateTimeISO']['output']
}

export type FacilityLifeCycleHistoryOrderBy = {
  stage?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type FacilityLifeCycleHistoryWhereInput = {
  stage?: InputMaybe<EnumFacilityLifeCycleStagesFilter>
}

export enum FacilityLifeCycleStages {
  Active = 'ACTIVE',
  Discontinued = 'DISCONTINUED',
  Installed = 'INSTALLED',
  Pending = 'PENDING'
}

export type FacilityLifeCycleTransitionError = {
  __typename?: 'FacilityLifeCycleTransitionError'
  allowedTransitions: Array<FacilityLifeCycleStages>
}

export type FacilityLifeCycleWhereInput = {
  AND?: InputMaybe<Array<FacilityLifeCycleWhereInput>>
  NOT?: InputMaybe<Array<FacilityLifeCycleWhereInput>>
  OR?: InputMaybe<Array<FacilityLifeCycleWhereInput>>
  stage?: InputMaybe<EnumFacilityLifeCycleStagesFilter>
}

export type FacilityLifeCycles = {
  __typename?: 'FacilityLifeCycles'
  allowedTransitions: Array<AllowedFacilityLifeCycleTransitions>
  current: FacilityLifeCycle
  history: Array<FacilityLifeCycle>
}

export type FacilityLifeCyclesHistoryArgs = {
  includeCurrent?: InputMaybe<Scalars['Boolean']['input']>
  orderBy?: InputMaybe<FacilityLifeCycleHistoryOrderBy>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityLifeCycleHistoryWhereInput>
}

export type FacilityLocation = {
  __typename?: 'FacilityLocation'
  address?: Maybe<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  electricalGridId?: Maybe<Scalars['String']['output']>
  latitude?: Maybe<Scalars['Float']['output']>
  longitude?: Maybe<Scalars['Float']['output']>
  propertyDesignation?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTimeISO']['output']
}

export type FacilityLocationCreateWithoutFacilityInput = {
  address?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  electricalGridId?: InputMaybe<Scalars['String']['input']>
  latitude?: InputMaybe<Scalars['Float']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
  propertyDesignation?: InputMaybe<Scalars['String']['input']>
}

export type FacilityLocationNullableRelationFilter = {
  is?: InputMaybe<FacilityLocationWhereInput>
  isNot?: InputMaybe<FacilityLocationWhereInput>
}

export type FacilityLocationOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>
  city?: InputMaybe<SortOrderInput>
  country?: InputMaybe<SortOrderInput>
  electricalGridId?: InputMaybe<SortOrderInput>
  latitude?: InputMaybe<SortOrderInput>
  longitude?: InputMaybe<SortOrderInput>
  propertyDesignation?: InputMaybe<SortOrderInput>
}

export type FacilityLocationWhereInput = {
  AND?: InputMaybe<Array<FacilityLocationWhereInput>>
  NOT?: InputMaybe<Array<FacilityLocationWhereInput>>
  OR?: InputMaybe<Array<FacilityLocationWhereInput>>
  address?: InputMaybe<StringNullableFilter>
  city?: InputMaybe<StringNullableFilter>
  country?: InputMaybe<StringNullableFilter>
  electricalGridId?: InputMaybe<StringNullableFilter>
  latitude?: InputMaybe<FloatNullableFilter>
  longitude?: InputMaybe<FloatNullableFilter>
  propertyDesignation?: InputMaybe<StringNullableFilter>
}

export type FacilityMapping = {
  __typename?: 'FacilityMapping'
  facilityId: Scalars['Int']['output']
  hostname?: Maybe<Scalars['String']['output']>
  macAddress?: Maybe<Scalars['String']['output']>
  pid: Scalars['String']['output']
  updatedAt: Scalars['DateTimeISO']['output']
}

export type FacilityMappingNullableRelationFilter = {
  is?: InputMaybe<FacilityMappingWhereInput>
  isNot?: InputMaybe<FacilityMappingWhereInput>
}

export type FacilityMappingOrderByWithRelationInput = {
  facility?: InputMaybe<FacilityOrderByWithRelationInput>
  facilityId?: InputMaybe<SortOrder>
  hostname?: InputMaybe<SortOrderInput>
  macAddress?: InputMaybe<SortOrderInput>
  pid?: InputMaybe<SortOrder>
  singleBoardComputer?: InputMaybe<SingleBoardComputerOrderByWithRelationInput>
}

export type FacilityMappingWhereInput = {
  AND?: InputMaybe<Array<FacilityMappingWhereInput>>
  NOT?: InputMaybe<Array<FacilityMappingWhereInput>>
  OR?: InputMaybe<Array<FacilityMappingWhereInput>>
  facility?: InputMaybe<FacilityNullableRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  hostname?: InputMaybe<StringNullableFilter>
  macAddress?: InputMaybe<StringNullableFilter>
  pid?: InputMaybe<StringFilter>
  singleBoardComputer?: InputMaybe<SingleBoardComputerNullableRelationFilter>
}

export type FacilityMisc = {
  __typename?: 'FacilityMisc'
  costElectricityKWh?: Maybe<Scalars['Float']['output']>
  currencyCode?: Maybe<Scalars['String']['output']>
  currencyCountry?: Maybe<Scalars['String']['output']>
  revenueSoldToGridkWh?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['DateTimeISO']['output']
}

export type FacilityMiscCreateWithoutFacilityInput = {
  costElectricityKWh?: InputMaybe<Scalars['Float']['input']>
  currencyCode?: InputMaybe<Scalars['String']['input']>
  currencyCountry?: InputMaybe<Scalars['String']['input']>
  revenueSoldToGridkWh?: InputMaybe<Scalars['Float']['input']>
}

export type FacilityMiscNullableRelationFilter = {
  is?: InputMaybe<FacilityMiscWhereInput>
  isNot?: InputMaybe<FacilityMiscWhereInput>
}

export type FacilityMiscOrderByWithRelationInput = {
  costElectricityKWh?: InputMaybe<SortOrderInput>
  currencyCode?: InputMaybe<SortOrderInput>
  currencyCountry?: InputMaybe<SortOrderInput>
  revenueSoldToGridkWh?: InputMaybe<SortOrderInput>
}

export type FacilityMiscWhereInput = {
  AND?: InputMaybe<Array<FacilityMiscWhereInput>>
  NOT?: InputMaybe<Array<FacilityMiscWhereInput>>
  OR?: InputMaybe<Array<FacilityMiscWhereInput>>
  costElectricityKWh?: InputMaybe<FloatNullableFilter>
  currencyCode?: InputMaybe<StringNullableFilter>
  currencyCountry?: InputMaybe<StringNullableFilter>
  revenueSoldToGridkWh?: InputMaybe<FloatNullableFilter>
}

/** Occurs if the facility doesn't exists */
export type FacilityNotFound = {
  __typename?: 'FacilityNotFound'
  facilityId: Scalars['ID']['output']
}

export type FacilityNullableRelationFilter = {
  is?: InputMaybe<FacilityWhereInput>
  isNot?: InputMaybe<FacilityWhereInput>
}

export type FacilityOnUser = {
  __typename?: 'FacilityOnUser'
  _count?: Maybe<FacilityCount>
  alarm: Alarm
  capacity?: Maybe<FacilityCapacity>
  createdAt: Scalars['DateTimeISO']['output']
  discontinuedAt?: Maybe<Scalars['DateTimeISO']['output']>
  /** @deprecated Use params from the FacilityOnUser. Example `user { facilities { facility { name } } }` becomes  `users { facilities { name } }` */
  facility: FacilityOnUser
  /** @deprecated Use `id` instead */
  facilityId: Scalars['ID']['output']
  features: Array<FacilityFeatures>
  history: Array<FacilityHistory>
  hostname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  info?: Maybe<FacilityInfo>
  installedOn?: Maybe<Scalars['DateTimeISO']['output']>
  lastUpdatedBy?: Maybe<FacilityUser>
  lifeCycle: FacilityLifeCycles
  location?: Maybe<FacilityLocation>
  misc?: Maybe<FacilityMisc>
  name: Scalars['String']['output']
  /** only available for facilities connected in a powershare */
  powershare?: Maybe<Powershare>
  relation: FacilityUserRoles
  settings: FacilitySettings
  /** The single board computer which acts as the interface to eCloud from the facility */
  singleBoardComputer?: Maybe<SingleBoardComputer>
  timezone?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTimeISO']['output']
  users: Array<FacilityUser>
}

export type FacilityOnUserHistoryArgs = {
  orderBy?: InputMaybe<Array<FacilityHistoryEntryOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityHistoryEntryWhereWithTableFilterInput>
}

export type FacilityOrderByWithRelationInput = {
  capacity?: InputMaybe<FacilityCapacityOrderByWithRelationInput>
  currentLifeCycle?: InputMaybe<FacilityCurrentLifeCycleOrderByWithRelationInput>
  id?: InputMaybe<SortOrder>
  installedOn?: InputMaybe<SortOrderInput>
  location?: InputMaybe<FacilityLocationOrderByWithRelationInput>
  misc?: InputMaybe<FacilityMiscOrderByWithRelationInput>
  name?: InputMaybe<SortOrder>
  timezone?: InputMaybe<SortOrderInput>
}

export type FacilityRelationFilter = {
  is?: InputMaybe<FacilityWhereInput>
  isNot?: InputMaybe<FacilityWhereInput>
}

export enum FacilityScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DiscontinuedAt = 'discontinuedAt',
  Id = 'id',
  InstalledOn = 'installedOn',
  LastModifiedBy = 'lastModifiedBy',
  Name = 'name',
  Timezone = 'timezone',
  UpdatedAt = 'updatedAt'
}

export type FacilitySettings = {
  __typename?: 'FacilitySettings'
  cloudApi?: Maybe<CloudApiSettings>
  id: Scalars['ID']['output']
}

export type FacilityUser = IUser & {
  __typename?: 'FacilityUser'
  colors?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTimeISO']['output']
  email: Scalars['EmailAddress']['output']
  emailVerified: Scalars['Boolean']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  legacyId?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['Locale']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  relation: FacilityUserRoles
  /** @deprecated Use params from the FacilityUser. Example `facility { users { user { name } } }` becomes  `facility { users { name } }` */
  user: FacilityUser
  /** @deprecated Use `id` instead */
  userId: Scalars['ID']['output']
  username?: Maybe<Scalars['String']['output']>
}

export type FacilityUserMappingWhereInput = {
  AND?: InputMaybe<Array<FacilityUserMappingWhereInput>>
  NOT?: InputMaybe<Array<FacilityUserMappingWhereInput>>
  OR?: InputMaybe<Array<FacilityUserMappingWhereInput>>
  facility?: InputMaybe<FacilityNullableRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  relation?: InputMaybe<EnumFacilityUserRolesFilter>
  userId?: InputMaybe<StringFilter>
  userIdOld?: InputMaybe<IntNullableFilter>
}

export enum FacilityUserRoles {
  FacilityAdmin = 'FACILITY_ADMIN',
  FacilityReader = 'FACILITY_READER'
}

export type FacilityWhereInput = {
  AND?: InputMaybe<Array<FacilityWhereInput>>
  NOT?: InputMaybe<Array<FacilityWhereInput>>
  OR?: InputMaybe<Array<FacilityWhereInput>>
  capacity?: InputMaybe<FacilityCapacityNullableRelationFilter>
  currentLifeCycle?: InputMaybe<FacilityCurrentLifeCycleNullableRelationFilter>
  id?: InputMaybe<IntFilter>
  installedOn?: InputMaybe<DateTimeNullableFilter>
  location?: InputMaybe<FacilityLocationNullableRelationFilter>
  misc?: InputMaybe<FacilityMiscNullableRelationFilter>
  name?: InputMaybe<StringFilter>
  timezone?: InputMaybe<StringNullableFilter>
}

export type FacilityWhereUniqueInput = {
  AND?: InputMaybe<Array<FacilityWhereInput>>
  NOT?: InputMaybe<Array<FacilityWhereInput>>
  OR?: InputMaybe<Array<FacilityWhereInput>>
  capacity?: InputMaybe<FacilityCapacityNullableRelationFilter>
  currentLifeCycle?: InputMaybe<FacilityCurrentLifeCycleNullableRelationFilter>
  id?: InputMaybe<Scalars['Int']['input']>
  installedOn?: InputMaybe<DateTimeNullableFilter>
  location?: InputMaybe<FacilityLocationNullableRelationFilter>
  misc?: InputMaybe<FacilityMiscNullableRelationFilter>
  name?: InputMaybe<StringFilter>
  timezone?: InputMaybe<StringNullableFilter>
}

/** Failed to send verify email to the user */
export type FailedToSendVerify = {
  __typename?: 'FailedToSendVerify'
  email: Scalars['String']['output']
}

/** Indicates which Event has been triggered by the device */
export enum FerroampEvent {
  /** The configuration of the unit has been overriden by a third party, for example the Distribution System Owner.  */
  AcDsoOverride = 'AcDsoOverride',
  /** Loss of mains detected on the AC grid */
  AcLossOfMains = 'AcLossOfMains',
  /** Overfrequency event detected on AC grid, protection stage 1 */
  AcOverFrequencyS1 = 'AcOverFrequencyS1',
  /** Overfrequency event detected on AC grid, protection stage 2 */
  AcOverFrequencyS2 = 'AcOverFrequencyS2',
  /** Persistent overvoltage detected over the past 10 minutes on the AC grid */
  AcOverVoltage10Min = 'AcOverVoltage10Min',
  /** Overvoltage event detected on AC grid, protection stage 1 */
  AcOverVoltageS1 = 'AcOverVoltageS1',
  /** Overvoltage event detected on AC grid, protection stage 2 */
  AcOverVoltageS2 = 'AcOverVoltageS2',
  /** The converter can not detect a valid AC mains frequency */
  AcPllLockFail = 'AcPllLockFail',
  /** The converter can not detect a valid AC mains frequency for 24h  */
  AcPllLockFail24H = 'AcPllLockFail24H',
  /** Underfrequency event detected on AC grid, protection stage 1 */
  AcUnderFrequencyS1 = 'AcUnderFrequencyS1',
  /** Underfrequency event detected on AC grid, protection stage 2 */
  AcUnderFrequencyS2 = 'AcUnderFrequencyS2',
  /** Undervoltage event detected on AC grid, protection stage 1 */
  AcUnderVoltageS1 = 'AcUnderVoltageS1',
  /** Undervoltage event detected on AC grid, protection stage 2 */
  AcUnderVoltageS2 = 'AcUnderVoltageS2',
  /** The voltage has risen above the threshold set for Zero Current Mode */
  AcZerocurrentOverVoltage = 'AcZerocurrentOverVoltage',
  /** The voltage has fallen below the threshold set for Zero Current Mode */
  AcZerocurrentUnderVoltage = 'AcZerocurrentUnderVoltage',
  /** The Battery Monitoring system has encountered an internal error.  */
  BatBmsInternalError = 'BatBmsInternalError',
  /** The battery has detected one or several cells with overvoltage for more than 24h.  */
  BatCellOvervoltage24H = 'BatCellOvervoltage24H',
  /** The battery has detected a too low temperature for it to operate properly.  */
  BatCellUndertemperature = 'BatCellUndertemperature',
  /** The battery has detected one or several cells with undervoltage for more than 24h.  */
  BatCellUndervoltage24H = 'BatCellUndervoltage24H',
  /** The battery has started critical maintance */
  BatCriticalMaintenanceStarted = 'BatCriticalMaintenanceStarted',
  /** The battery has started protecting itself from deep discharge */
  BatDeepDischargeProtection = 'BatDeepDischargeProtection',
  /** The battery has identified an error during critical maintenance.  */
  BatMaintenanceError = 'BatMaintenanceError',
  /** The battery has started regular maintance */
  BatRegularMaintenanceStarted = 'BatRegularMaintenanceStarted',
  /** The unit could not blackstart the DC nanogrid, charge it from no voltage to nominal voltage.  */
  DcGridBlackstartFailure = 'DcGridBlackstartFailure',
  /** The device is unable to form/regulate a valid DC grid.  */
  DcGridPreconditionFailure = 'DcGridPreconditionFailure',
  /** The converter has not been able to precharge the internal capacitors from the AC side */
  HwAcPrechargeFail = 'HwAcPrechargeFail',
  /** The converter has not been able to precharge the internal capacitors from the Battery side */
  HwBatPrechargeFail = 'HwBatPrechargeFail',
  /** The converter has not been able to precharge the internal capacitors from the DC side */
  HwDcPrechargeFail = 'HwDcPrechargeFail',
  /** The unit is reading a higher temperature than allowed at the heatsink / semiconductors.  */
  HwHeatsinkOvertemp = 'HwHeatsinkOvertemp',
  /** The converter residual current detection device has detected a residual current.  */
  HwRcdError = 'HwRcdError',
  /** Invalid CT configuration detected, ACE functionality disabled */
  InsAceSanityError = 'InsAceSanityError',
  /** System has detected that CT mapping is not correct. ACE functionality has been deactivated. */
  InsCtConfError = 'InsCtConfError',
  /** The unit does not detect the external Current Transducers even though they had been detected before */
  InsExtCtDisconnected = 'InsExtCtDisconnected',
  /** The unit interlock is open, emergency button might have been pressed.  */
  InsInterlockDisconnected = 'InsInterlockDisconnected',
  /** The unit has not detected a valid network connection.  */
  InsNetworkConfInvalid = 'InsNetworkConfInvalid',
  /** The unit has lost communication with external HW via CAN.  */
  InsNoCanComm = 'InsNoCanComm',
  /** The unit has lost communication with external HW via RS485.  */
  InsNoRs485Comm = 'InsNoRs485Comm',
  /** Incorrect polarity detected in one of the ports of the terminal.  */
  InsReversePolarityDetected = 'InsReversePolarityDetected',
  /** Nothing is detected in the Single Pair Ethernet port.  */
  InsSpeDisconnected = 'InsSpeDisconnected',
  /** The converter's insulation monitoring device has detected an insulation fault.  */
  PvImdTriggered = 'PvImdTriggered',
  /** The unit has not produced any solar power in the last 24h */
  PvNoProduction24H = 'PvNoProduction24H',
  /** The converter residual current detection device has detected a residual current.  */
  PvRcdTriggered = 'PvRcdTriggered',
  /** Network communication not completely working */
  SwNetworkCommDegraded = 'SwNetworkCommDegraded',
  /** A terminal that is not part of the System Control Topology has been detected */
  SwUnconfiguredTerminalDetected = 'SwUnconfiguredTerminalDetected'
}

export type FindManyUsersWhereArgs = {
  /** A String contained in email, or the complete email, if param "exact" is true */
  email?: InputMaybe<Scalars['String']['input']>
  /** whether the email has been verified */
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>
  /** Boolean representing if user is enabled or not   */
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  /** Boolean which defines whether the params "last", "first", "email" and "username" must match exactly */
  exact?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']['input']>
  /** A String contained in username, first or last name, or email. Default search behavior is prefix-based (e.g., foo or foo*). Use foo for infix search and &quot;foo&quot; for exact search. */
  search?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<NestedFloatNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type Grid = {
  __typename?: 'Grid'
  /** System-defined ID, used to identify one out of multiple clusters. */
  id: Scalars['Int']['output']
  metadata: Metadata
  param: GridParam
  /** A list of terminals of type grid-tied inverter. This contains both highlevel and low level. */
  terminals: Array<GridTerminal>
}

/** Grid-terminal configuration parameters (low-level), as computed by parameter abstraction layer (PAL) */
export type GridConfig = {
  __typename?: 'GridConfig'
  installation?: Maybe<GridInstallation>
  runtime: GridRuntime
}

/** Grid-terminal configuration parameters (low-level), as computed by parameter abstraction layer (PAL) */
export type GridConfigInput = {
  installation?: InputMaybe<GridInstallationInput>
  runtime: GridRuntimeInput
}

/**
 * General Descriptions of current fields
 * - i = Current
 * - Inv = Inverter - currents measured internally in the inverter
 * - Ext = External - currents measured at the main fuses
 * - RMS = Root Mean Square - currents is measured as Total Current
 * - Q = Active Current  -  currents is measured as Peak Active Current
 * - D = Reactive Current - currents is measured as Peak Reactive Current
 * - 1 = Phase 1
 * - 2 = Phase 2
 * - 3 = Phase 3
 *
 * Voltage Fields:
 * - uDCpos - Voltage of Positive conductor in Ferroamps DC Nanogrid
 * - uDCneg - Voltage of Negative conductor in Ferroamps DC Nanogrid
 * - u1 - Voltage of Grid Phase 1
 * - u2 - Voltage of Grid Phase 2
 * - u3 - Voltage of Grid Phase 3
 */
export type GridData = {
  __typename?: 'GridData'
  iExtD1: Scalars['Float']['output']
  iExtD2: Scalars['Float']['output']
  iExtD3: Scalars['Float']['output']
  iExtQ1: Scalars['Float']['output']
  iExtQ2: Scalars['Float']['output']
  iExtQ3: Scalars['Float']['output']
  iExtRms1: Scalars['Float']['output']
  iExtRms2: Scalars['Float']['output']
  iExtRms3: Scalars['Float']['output']
  iInvD1: Scalars['Float']['output']
  iInvD2: Scalars['Float']['output']
  iInvD3: Scalars['Float']['output']
  iInvQ1: Scalars['Float']['output']
  iInvQ2: Scalars['Float']['output']
  iInvQ3: Scalars['Float']['output']
  iInvRms1: Scalars['Float']['output']
  iInvRms2: Scalars['Float']['output']
  iInvRms3: Scalars['Float']['output']
  timestamp: Scalars['DateTimeISO']['output']
  u1: Scalars['Float']['output']
  u2: Scalars['Float']['output']
  u3: Scalars['Float']['output']
  uDCneg: Scalars['Float']['output']
  uDCpos: Scalars['Float']['output']
}

/** External current sensor attributes. */
export type GridExtCurrentSensor = {
  __typename?: 'GridExtCurrentSensor'
  /** TO-BE-IMPLEMENTED. Part-number of external current sensor. */
  pn?: Maybe<Scalars['String']['output']>
  /** Transfer ratio of external current sensor. */
  ratio: Scalars['Int']['output']
}

/** External current sensor attributes. */
export type GridExtCurrentSensorInput = {
  /** TO-BE-IMPLEMENTED. Part-number of external current sensor. */
  pn?: InputMaybe<Scalars['String']['input']>
  /** Transfer ratio of external current sensor. */
  ratio: Scalars['Int']['input']
}

export type GridInput = {
  /** System-defined ID, used to identify one out of multiple clusters. */
  id: Scalars['Int']['input']
  metadata: MetadataInput
  param: GridParamInput
  /** A list of terminals of type grid-tied inverter. This contains both highlevel and low level. */
  terminals: Array<GridTerminalInput>
}

/** Installation parameters are meant to be static for a specific installation. These parameters only take affect after a transition from Idle to Running state, and/or a reboot. */
export type GridInstallation = {
  __typename?: 'GridInstallation'
  /** Filter coefficient (in seconds) for the lowpass filter, applied on power measured at the supply/metering point of the grid-terminal. This is used to filter out fast variations in facility load. */
  ctConfig: CtConfig
  gridFiltCoeff?: Maybe<Scalars['Int']['output']>
  gridLimits: GridLimits
  /** A flag that indicates whether this terminal is the only one in the Cluster. */
  isSingle: Scalars['Boolean']['output']
}

/** Installation parameters are meant to be static for a specific installation. These parameters only take affect after a transition from Idle to Running state, and/or a reboot. */
export type GridInstallationInput = {
  /** Filter coefficient (in seconds) for the lowpass filter, applied on power measured at the supply/metering point of the grid-terminal. This is used to filter out fast variations in facility load. */
  ctConfig: CtConfigInput
  gridFiltCoeff?: InputMaybe<Scalars['Int']['input']>
  gridLimits: GridLimitsInput
  /** A flag that indicates whether this terminal is the only one in the Cluster. */
  isSingle: Scalars['Boolean']['input']
}

/** Complex limits on current (in Amperes) measured at the supply/metering point of the grid-terminal. */
export type GridLimits = {
  __typename?: 'GridLimits'
  imag?: Maybe<Scalars['Float']['output']>
  real?: Maybe<Scalars['Float']['output']>
}

/** Complex limits on current (in Amperes) measured at the supply/metering point of the grid-terminal. */
export type GridLimitsInput = {
  imag?: InputMaybe<Scalars['Float']['input']>
  real?: InputMaybe<Scalars['Float']['input']>
}

/** User-defined cluster parameters (high-level) for grid-clusters. */
export type GridParam = {
  __typename?: 'GridParam'
  extCurrentSensor: GridExtCurrentSensor
  /** Rated Current (in Amperes) of facility mains fuse, installed at the supply/metering point. */
  fuseCurrentRating: Scalars['Float']['output']
  /** TO-BE-IMPLEMENTED. Response time (in seconds) from the instance a step-change in load occurs, to when the Grid clusters respond to that impulse. This is used to filter out fast variations in facility load. */
  loadResponseTime?: Maybe<Scalars['Int']['output']>
}

/** User-defined cluster parameters (high-level) for grid-clusters. */
export type GridParamInput = {
  extCurrentSensor: GridExtCurrentSensorInput
  /** Rated Current (in Amperes) of facility mains fuse, installed at the supply/metering point. */
  fuseCurrentRating: Scalars['Float']['input']
  /** TO-BE-IMPLEMENTED. Response time (in seconds) from the instance a step-change in load occurs, to when the Grid clusters respond to that impulse. This is used to filter out fast variations in facility load. */
  loadResponseTime?: InputMaybe<Scalars['Int']['input']>
}

/** Runtime parameters are meant to be configured during runtime, and the terminal handles smooth tranisition of its operation based on the parameter values. */
export type GridRuntime = {
  __typename?: 'GridRuntime'
  aceMode: AceMode
  apwrMode: ApwrMode
  currentLimits: CurrentLimits
  droopDeadband: DroopDeadband
  droopLimits: DroopLimits
  pffLimits: PffLimits
  powerLimits: PowerLimits
}

/** Runtime parameters are meant to be configured during runtime, and the terminal handles smooth tranisition of its operation based on the parameter values. */
export type GridRuntimeInput = {
  aceMode: AceModeInput
  apwrMode: ApwrModeInput
  currentLimits: CurrentLimitsInput
  droopDeadband: DroopDeadbandInput
  droopLimits: DroopLimitsInput
  pffLimits: PffLimitsInput
  powerLimits: PowerLimitsInput
}

export type GridTerminal = {
  __typename?: 'GridTerminal'
  attributes: GridTerminalAttributes
  config: GridConfig
  /** Identifier (MAC address) for a MQTT client associated with a terminal. For Ferroamp EnergyHubs, it is the integrated linux platform (BBB and/or STM32). For Ferroamp products that act as PLC slaves, it is the MAC address of the PLC master/aggregator. */
  id: Scalars['String']['output']
  metadata: Metadata
  param: GridTerminalParam
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a grid-terminal. */
export type GridTerminalAttributes = {
  __typename?: 'GridTerminalAttributes'
  /** Unique product idenifier for a Ferroamp product, which is configured at production */
  pid: Scalars['String']['output']
  /** Maximum rated single-phase RMS current capability (in Amperes) of the grid-terminal. */
  ratedCurrent: Scalars['Float']['output']
  /** Maximum rated 3-phase active power (in Watts) capability of the grid-terminal. */
  ratedPower: Scalars['Float']['output']
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a grid-terminal. */
export type GridTerminalAttributesInput = {
  /** Unique product idenifier for a Ferroamp product, which is configured at production */
  pid: Scalars['String']['input']
  /** Maximum rated single-phase RMS current capability (in Amperes) of the grid-terminal. */
  ratedCurrent: Scalars['Float']['input']
  /** Maximum rated 3-phase active power (in Watts) capability of the grid-terminal. */
  ratedPower: Scalars['Float']['input']
}

export type GridTerminalInput = {
  attributes: GridTerminalAttributesInput
  config: GridConfigInput
  /** Identifier (MAC address) for a MQTT client associated with a terminal. For Ferroamp EnergyHubs, it is the integrated linux platform (BBB and/or STM32). For Ferroamp products that act as PLC slaves, it is the MAC address of the PLC master/aggregator. */
  id: Scalars['String']['input']
  metadata: MetadataInput
  param: GridTerminalParamInput
}

/** User-defined terminal parameters (high-level) for grid-terminals. */
export type GridTerminalParam = {
  __typename?: 'GridTerminalParam'
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable: Scalars['Boolean']['output']
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override: Scalars['Boolean']['output']
}

/** User-defined terminal parameters (high-level) for grid-terminals. */
export type GridTerminalParamInput = {
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable: Scalars['Boolean']['input']
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override: Scalars['Boolean']['input']
}

export type IConfigurationBaseType = {
  generation: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  state: ConfigurationCommunicationState
}

export type IEmsConfigSchedule = {
  emsParam: EmsConfig
  id: Scalars['ID']['output']
  lastApplied?: Maybe<Scalars['DateTime']['output']>
  lastUpdate?: Maybe<Scalars['DateTime']['output']>
  lastUpdateBy?: Maybe<Scalars['ID']['output']>
}

export type IOffsetPagination = {
  totalCount: Scalars['Int']['output']
}

export type ISystemEnergy = {
  /**
   * Energy consumed by the facility, total
   *     calculated 3 phase.
   */
  consumption: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 1. */
  consumptionL1: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 2. */
  consumptionL2: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 3. */
  consumptionL3: Scalars['Float']['output']
  /**
   * Energy produced by the facility, total
   *     calculated 3 phase. This can occur if there is another producing entity
   *     in the facility, for example another solar production system.
   */
  consumptionProduced: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 1. */
  consumptionProducedL1: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 2. */
  consumptionProducedL2: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 3. */
  consumptionProducedL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for energy consumed
   *     by EV Charges controlled by system.
   *     Will be null if system has no EV Chargers connected
   */
  evseConsumed?: Maybe<Scalars['Float']['output']>
  /**
   * Energy exported over the main fuse
   *     at external current transformers, total calculated 3 phase
   */
  gridExport: Scalars['Float']['output']
  /** Energy exported to the grid on phase 1. */
  gridExportL1: Scalars['Float']['output']
  /** Energy exported to the grid on phase 2. */
  gridExportL2: Scalars['Float']['output']
  /** Energy exported to the grid on phase 3. */
  gridExportL3: Scalars['Float']['output']
  /**
   * Energy imported over the main fuse at
   *     external current transformers, total calculated 3 phase
   */
  gridImport: Scalars['Float']['output']
  /** Energy imported from the grid on phase 1. */
  gridImportL1: Scalars['Float']['output']
  /** Energy imported from the grid on phase 2. */
  gridImportL2: Scalars['Float']['output']
  /** Energy imported from the grid on phase 3. */
  gridImportL3: Scalars['Float']['output']
  /** Energy exported from inverter, total calculated 3 phase. */
  inverterExport: Scalars['Float']['output']
  /** Energy exported from inverted on phase 1. */
  inverterExportL1: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 2. */
  inverterExportL2: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 3. */
  inverterExportL3: Scalars['Float']['output']
  /** Energy import to inverter, total calculated 3 phase. */
  inverterImport: Scalars['Float']['output']
  /** Energy imported to inverted on phase 1. */
  inverterImportL1: Scalars['Float']['output']
  /** Energy imported to inverted on phase 2. */
  inverterImportL2: Scalars['Float']['output']
  /** Energy imported to inverted on phase 3. */
  inverterImportL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for Solar
   *      Energy produced by system. Will be null if system
   *       has no PV connected
   */
  pvProduced?: Maybe<Scalars['Float']['output']>
  /**
   * Signalling if the record describes a complete
   *   time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
}

/** Interface for UI Gauge */
export type IuiCapacity = {
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** Interface for UI Gauge */
export type IuiGauge = {
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

export type IUnitEnergy = {
  /** Energycounter, consumed energy of timeperiod */
  consumed: Scalars['Float']['output']
  /** Energycounter, produced energy of timeperiod */
  produced: Scalars['Float']['output']
  /**
   * The domain of the Reporting Unit.
   *       Currently only one domain is recognized,
   *       so this field is for future expansion purposes
   */
  reportingUnitDomain: Scalars['String']['output']
  /** ID within above domain for the Reporting Unit. For the case of EMS System Topology, this is the terminal ID */
  reportingUnitId: Scalars['String']['output']
  /**
   * Signalling if the record describes a complete
   * time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
}

export type IUser = {
  colors?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTimeISO']['output']
  email: Scalars['EmailAddress']['output']
  emailVerified: Scalars['Boolean']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  legacyId?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['Locale']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  username?: Maybe<Scalars['String']['output']>
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<NestedIntFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<NestedIntNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>
  equals?: InputMaybe<Scalars['JSON']['input']>
  gt?: InputMaybe<Scalars['JSON']['input']>
  gte?: InputMaybe<Scalars['JSON']['input']>
  lt?: InputMaybe<Scalars['JSON']['input']>
  lte?: InputMaybe<Scalars['JSON']['input']>
  not?: InputMaybe<Scalars['JSON']['input']>
  path?: InputMaybe<Array<Scalars['String']['input']>>
  string_contains?: InputMaybe<Scalars['String']['input']>
  string_ends_with?: InputMaybe<Scalars['String']['input']>
  string_starts_with?: InputMaybe<Scalars['String']['input']>
}

export type Logs = {
  __typename?: 'Logs'
  bessLog: Array<BessLog>
  id: Scalars['ID']['output']
  rawEpocLog: Array<EpocLog>
  rawEvseStartStopTransaction: Array<RawEvseStartStopTransaction>
  rawEvseStatusNotifications: Array<RawEvseStatusNotifications>
  rawOcpp: Array<RawOcpp>
}

export type LogsBessLogArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type LogsRawEpocLogArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type LogsRawEvseStartStopTransactionArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type LogsRawEvseStatusNotificationsArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type LogsRawOcppArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type Me = IUser & {
  __typename?: 'Me'
  colors?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTimeISO']['output']
  email: Scalars['EmailAddress']['output']
  emailVerified: Scalars['Boolean']['output']
  facilities?: Maybe<Array<Facility>>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  legacyId?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['Locale']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  powershares?: Maybe<Array<Powershare>>
  username?: Maybe<Scalars['String']['output']>
}

export type Measurements = {
  __typename?: 'Measurements'
  dataReceivedAt?: Maybe<Scalars['DateTimeISO']['output']>
  energy: Array<ISystemEnergy>
  energyStorage: Array<EnergyStorage>
  /** @deprecated Replaced with facility.measurements.rawUidata */
  gridData: Array<GridData>
  id: Scalars['Int']['output']
  latestGridData: GridData
  latestPower: PowerData
  power: Array<PowerData>
  rawEvseMeterValues: Array<EvseMeterValues>
  rawPower: Array<PowerData>
  rawUidata: Array<GridData>
  unitEnergy: Array<IUnitEnergy>
}

export type MeasurementsEnergyArgs = {
  dataSeriesType?: InputMaybe<EnergyDataSeriesType>
  last?: InputMaybe<Scalars['Int']['input']>
  period: EnergyPeriodInput
  unit?: EnergyUnit
}

export type MeasurementsEnergyStorageArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
  timeRange: DatetimeRangeQuery
}

export type MeasurementsGridDataArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
  timeRange: DatetimeRangeQuery
}

export type MeasurementsPowerArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
  resolution?: PowerdataResolution
  timeRange: DatetimeRangeQuery
}

export type MeasurementsRawEvseMeterValuesArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type MeasurementsRawPowerArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type MeasurementsRawUidataArgs = {
  orderBy?: InputMaybe<BaseRawDataOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: BaseRawDataWhereInput
}

export type MeasurementsUnitEnergyArgs = {
  dataSeriesType?: InputMaybe<EnergyDataSeriesType>
  last?: InputMaybe<Scalars['Int']['input']>
  period: EnergyPeriodInput
  reportingUnitDomain: ReportingUnitDomain
  reportingUnitId: Scalars['String']['input']
  unit?: EnergyUnit
}

/** User-defined meta-data describing a cluster/terminal. */
export type Metadata = {
  __typename?: 'Metadata'
  description: Scalars['String']['output']
  name: Scalars['String']['output']
}

/** User-defined meta-data describing a cluster/terminal. */
export type MetadataInput = {
  description: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptRequiredAction: UserRequiredAction
  addAlarmSubscriber: AddAlarmSubscriberMutationResponse
  addExistingUserToFacility: AddExistingUserToFacilityMutationResponse
  addManyFacilitiesToPowershare: Powershare
  addManyUserToPowershare: Powershare
  addNewUserToFacility: AddNewUserToFacilityMutationResponse
  addOneFacilityToPowershare: Powershare
  addOneUserToPowershare: Powershare
  createCloudApiSettings?: Maybe<CloudApiMutationResponse>
  createEmsConfigSchedule: EmsConfigSchedule
  createOneFacility: CreateOneFacilityMutationResponse
  createOneGenerationOneFacility: CreateOneFacilityMutationResponse
  createOnePowershare: Powershare
  deleteEmsConfigSchedule: EmsConfigScheduleDeleteResponse
  editFacilitySingleBoardComputerMapping: EditFacilityMappingMutationResponse
  removeAlarmSubscriber: RemoveAlarmSubscriberMutationResponse
  removeCloudApiSettings?: Maybe<CloudApiMutationResponse>
  removeOneFacilityFromPowershare: Powershare
  removeOnePowershare: Powershare
  removeOneUserFromPowershare: Powershare
  removeUserFacilityRelation: RemoveUserFacilityRelationMutationResponse
  setDefaultEmsConfigSchedule: DefaultEmsConfigSchedule
  setInstalledPvCapacity: FacilityCapacity
  setSingleBoardComputerLifeCycleStage: SetSingleBoardComputerLifeCycleStageResponse
  setSystemControlTopology?: Maybe<SystemControlTopologySendPayload>
  updateCloudApiSettings?: Maybe<CloudApiMutationResponse>
  updateEmsConfigSchedule: EmsConfigSchedule
  updateOneFacility: UpdateOneFacilityMutationResponse
  updateOneFacilityLifeCycle: UpdateFacilityLifeCycleMutationResponse
  updateOneFacilityLocation: UpdateOneFacilityLocationMutationResponse
  updateOneFacilityMisc: UpdateOneFacilityMiscMutationResponse
  updateOnePowershare: Powershare
  updateUserFacilityRelation: UpdateUserFacilityRelationMutationResponse
  upsertEmsConfiguration: UpsertEmsConfiguration
}

export type MutationAcceptRequiredActionArgs = {
  requiredActionId: Scalars['Int']['input']
  userId: Scalars['ID']['input']
}

export type MutationAddAlarmSubscriberArgs = {
  data: AddAlarmSubscriberInput
}

export type MutationAddExistingUserToFacilityArgs = {
  data?: InputMaybe<AddExistingUserToFacilityDataInput>
}

export type MutationAddManyFacilitiesToPowershareArgs = {
  data: AddManyFacilitiesToPowershareInput
  where: WherePowershareId
}

export type MutationAddManyUserToPowershareArgs = {
  data: AddManyPowershareUsersInput
  where: WherePowershareId
}

export type MutationAddNewUserToFacilityArgs = {
  data?: InputMaybe<AddNewUserToFacilityDataInput>
}

export type MutationAddOneFacilityToPowershareArgs = {
  data: WhereFacilityId
  where: WherePowershareId
}

export type MutationAddOneUserToPowershareArgs = {
  data: AddOneUserToPowershareInputs
  where: WherePowershareId
}

export type MutationCreateCloudApiSettingsArgs = {
  data?: InputMaybe<CloudApiCreateInput>
  id: Scalars['Int']['input']
}

export type MutationCreateEmsConfigScheduleArgs = {
  emsConfigSchedule: EmsConfigScheduleInput
  facilityId: Scalars['ID']['input']
}

export type MutationCreateOneFacilityArgs = {
  facilityData: FacilityCreateInput
  singleBoardComputerData: CreateOneFacilityGenerationTwoSbcInput
}

export type MutationCreateOneGenerationOneFacilityArgs = {
  facilityData: FacilityCreateInput
  singleBoardComputerData: CreateOneFacilityGenerationOneSbcInput
}

export type MutationCreateOnePowershareArgs = {
  data: CreateOnePowershareInputs
}

export type MutationDeleteEmsConfigScheduleArgs = {
  facilityId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type MutationEditFacilitySingleBoardComputerMappingArgs = {
  set: EditFacilityMappingDataInput
  where: EditFacilityMappingWhereInput
}

export type MutationRemoveAlarmSubscriberArgs = {
  data: RemoveAlarmSubscriberInput
}

export type MutationRemoveCloudApiSettingsArgs = {
  id: Scalars['Int']['input']
}

export type MutationRemoveOneFacilityFromPowershareArgs = {
  where: PowershareFacilityInput
}

export type MutationRemoveOnePowershareArgs = {
  where: WherePowershareId
}

export type MutationRemoveOneUserFromPowershareArgs = {
  where: PowershareUserInputs
}

export type MutationRemoveUserFacilityRelationArgs = {
  data?: InputMaybe<RemoveUserFacilityRelationDataInput>
}

export type MutationSetDefaultEmsConfigScheduleArgs = {
  defaultEmsConfigSchedule: DefaultEmsConfigScheduleInput
  facilityId: Scalars['ID']['input']
}

export type MutationSetInstalledPvCapacityArgs = {
  set: SetCapacity
  where: WhereFacilityId
}

export type MutationSetSingleBoardComputerLifeCycleStageArgs = {
  data: SetSingleBoardComputerLifeCycleStageDataInput
  where: SetSingleBoardComputerLifeCycleStageWhereInput
}

export type MutationSetSystemControlTopologyArgs = {
  data: SystemControlTopologyInput
  facilityId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationUpdateCloudApiSettingsArgs = {
  data: CloudApiUpdateInput
  id: Scalars['Int']['input']
}

export type MutationUpdateEmsConfigScheduleArgs = {
  emsConfigSchedule: EmsConfigScheduleInput
  facilityId: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type MutationUpdateOneFacilityArgs = {
  data: UpdateOneFacilityDataInput
  where: WhereFacilityId
}

export type MutationUpdateOneFacilityLifeCycleArgs = {
  data: UpdateOneFacilityLifeCycleData
  where: WhereFacilityId
}

export type MutationUpdateOneFacilityLocationArgs = {
  data: UpdateOneFacilityLocationDataInput
  where: WhereFacilityId
}

export type MutationUpdateOneFacilityMiscArgs = {
  data: UpdateOneFacilityMiscDataInput
  where: WhereFacilityId
}

export type MutationUpdateOnePowershareArgs = {
  data: UpdateOnePowershareInputs
  where: WherePowershareId
}

export type MutationUpdateUserFacilityRelationArgs = {
  data?: InputMaybe<UpdateUserFacilityRelationDataInput>
}

export type MutationUpsertEmsConfigurationArgs = {
  data: UpsertEmsConfigurationDataArgs
  where: BaseConfigurationWhereInput
}

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<NestedBoolFilter>
}

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>
  not?: InputMaybe<NestedDateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>
  not?: InputMaybe<NestedDateTimeNullableFilter>
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>
}

export type NestedEnumAlarmCodeFilter = {
  equals?: InputMaybe<AlarmCode>
  in?: InputMaybe<Array<AlarmCode>>
  not?: InputMaybe<NestedEnumAlarmCodeFilter>
  notIn?: InputMaybe<Array<AlarmCode>>
}

export type NestedEnumCommunicationStatesNullableFilter = {
  equals?: InputMaybe<CommunicationStates>
  in?: InputMaybe<Array<CommunicationStates>>
  not?: InputMaybe<NestedEnumCommunicationStatesNullableFilter>
  notIn?: InputMaybe<Array<CommunicationStates>>
}

export type NestedEnumConfigurationVerbFilter = {
  equals?: InputMaybe<ConfigurationVerb>
  in?: InputMaybe<Array<ConfigurationVerb>>
  not?: InputMaybe<NestedEnumConfigurationVerbFilter>
  notIn?: InputMaybe<Array<ConfigurationVerb>>
}

export type NestedEnumFacilityLifeCycleStagesFilter = {
  equals?: InputMaybe<FacilityLifeCycleStages>
  in?: InputMaybe<Array<FacilityLifeCycleStages>>
  not?: InputMaybe<NestedEnumFacilityLifeCycleStagesFilter>
  notIn?: InputMaybe<Array<FacilityLifeCycleStages>>
}

export type NestedEnumFacilityUserRolesFilter = {
  equals?: InputMaybe<FacilityUserRoles>
  in?: InputMaybe<Array<FacilityUserRoles>>
  not?: InputMaybe<NestedEnumFacilityUserRolesFilter>
  notIn?: InputMaybe<Array<FacilityUserRoles>>
}

export type NestedEnumPowershareUserRolesFilter = {
  equals?: InputMaybe<PowershareUserRoles>
  in?: InputMaybe<Array<PowershareUserRoles>>
  not?: InputMaybe<NestedEnumPowershareUserRolesFilter>
  notIn?: InputMaybe<Array<PowershareUserRoles>>
}

export type NestedEnumResourceTypeFilter = {
  equals?: InputMaybe<ResourceType>
  in?: InputMaybe<Array<ResourceType>>
  not?: InputMaybe<NestedEnumResourceTypeFilter>
  notIn?: InputMaybe<Array<ResourceType>>
}

export type NestedEnumSingleBoardComputerLifeCycleStagesFilter = {
  equals?: InputMaybe<SingleBoardComputerLifeCycleStages>
  in?: InputMaybe<Array<SingleBoardComputerLifeCycleStages>>
  not?: InputMaybe<NestedEnumSingleBoardComputerLifeCycleStagesFilter>
  notIn?: InputMaybe<Array<SingleBoardComputerLifeCycleStages>>
}

export type NestedEnumSubscriptionTypeFilter = {
  equals?: InputMaybe<SubscriptionType>
  in?: InputMaybe<Array<SubscriptionType>>
  not?: InputMaybe<NestedEnumSubscriptionTypeFilter>
  notIn?: InputMaybe<Array<SubscriptionType>>
}

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<NestedFloatNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<NestedIntFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<NestedIntNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<NestedStringNullableFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

/** The user can't be saved */
export type NewUserError = {
  __typename?: 'NewUserError'
  error: Scalars['String']['output']
}

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

/** Feedforward Active Power limits (in Watts). */
export type PffLimits = {
  __typename?: 'PffLimits'
  neg: Scalars['Float']['output']
  pos: Scalars['Float']['output']
}

/** Feedforward Active Power limits (in Watts). */
export type PffLimitsInput = {
  neg: Scalars['Float']['input']
  pos: Scalars['Float']['input']
}

export type PowerData = {
  __typename?: 'PowerData'
  /** Power of Battery */
  batteryPower?: Maybe<Scalars['Float']['output']>
  /** Power measured at incoming electrical feed */
  gridPower: Scalars['Float']['output']
  /** Power measured in and out from inverters */
  inverterPower: Scalars['Float']['output']
  /** Total Consumption Active Power. */
  loadPower: Scalars['Float']['output']
  /**
   *  Reactive External Power on Phase 1 Only available for resolution SECONDS
   * @deprecated Unused
   */
  pExtD1?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive External Power on Phase 2
   * @deprecated Unused
   */
  pExtD2?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive External Power on Phase 3. Only available for resolution SECONDS
   * @deprecated Unused
   */
  pExtD3?: Maybe<Scalars['Float']['output']>
  /**
   * Active External Power on Phase 1, Only available for resolution SECONDS
   * @deprecated Unused
   */
  pExtQ1?: Maybe<Scalars['Float']['output']>
  /**
   * Active External Power on Phase 2  Only available for resolution SECONDS
   * @deprecated Unused
   */
  pExtQ2?: Maybe<Scalars['Float']['output']>
  /**
   * Active External Power on Phase 3 Only available for resolution SECONDS
   * @deprecated Unused
   */
  pExtQ3?: Maybe<Scalars['Float']['output']>
  /**
   *  Reactive Inverter Power on Phase 1.  Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvD1?: Maybe<Scalars['Float']['output']>
  /**
   *  Reactive Inverter Power on Phase 2. Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvD2?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive Inverter Power on Phase 3. Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvD3?: Maybe<Scalars['Float']['output']>
  /**
   *  Active Inverter Power On phase 1.  Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvQ1?: Maybe<Scalars['Float']['output']>
  /**
   *  Active Inverter Power on Phase 2. Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvQ2?: Maybe<Scalars['Float']['output']>
  /**
   * Active Inverter Power on Phase 3. Only available for resolution SECONDS
   * @deprecated Unused
   */
  pInvQ3?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive Load Power on Phase 1. Important to note that we are not measuring Load Power and this is just a calculated value (pExtD1-pInvD1) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadD1?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive Load Power on Phase 2. Important to note that we are not measuring Load Power and this is just a calculated value (pExtD2-pInvD2) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadD2?: Maybe<Scalars['Float']['output']>
  /**
   * Reactive Load Power on Phase 3. Important to note that we are not measuring Load Power and this is just a calculated value (pExtD3-pInvD3) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadD3?: Maybe<Scalars['Float']['output']>
  /**
   * Active Load Power on Phase 1. Important to note that we are not measuring Load Power and this is just a calculated value (pExtQ1-pInvQ1) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadQ1?: Maybe<Scalars['Float']['output']>
  /**
   * Active Load Power on Phase 2. Important to note that we are not measuring Load Power and this is just a calculated value (pExtQ2-pInvQ2) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadQ2?: Maybe<Scalars['Float']['output']>
  /**
   * Active Load Power on Phase 3. Important to note that we are not measuring Load Power and this is just a calculated value (pExtQ3-pInvQ3) Only available for resolution SECONDS
   * @deprecated Unused
   */
  pLoadQ3?: Maybe<Scalars['Float']['output']>
  /** Power from solar strings optimizers */
  pvPower?: Maybe<Scalars['Float']['output']>
  /**
   * Complex Power Only available for resolution SECONDS
   * @deprecated Unused
   */
  sExt?: Maybe<Scalars['Float']['output']>
  /** Timestamp of data */
  timestamp: Scalars['DateTimeISO']['output']
}

/** Active Power limits (in Watts). */
export type PowerLimits = {
  __typename?: 'PowerLimits'
  neg: Scalars['Float']['output']
  pos: Scalars['Float']['output']
}

/** Active Power limits (in Watts). */
export type PowerLimitsInput = {
  neg: Scalars['Float']['input']
  pos: Scalars['Float']['input']
}

export enum PowerdataResolution {
  /** @deprecated Gone. */
  Hourly = 'Hourly',
  Minutely = 'Minutely',
  /** @deprecated replaced with facility.measurements.rawPower */
  Seconds = 'Seconds'
}

export type Powershare = {
  __typename?: 'Powershare'
  _count?: Maybe<PowershareCount>
  createdAt: Scalars['DateTimeISO']['output']
  discontinuedAt?: Maybe<Scalars['DateTimeISO']['output']>
  /** Facilities which are connected to this powershare */
  facilities?: Maybe<Array<Facility>>
  id: Scalars['ID']['output']
  installedOn?: Maybe<Scalars['DateTimeISO']['output']>
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTimeISO']['output']
  /** User which has a relation to a certain powershare */
  users?: Maybe<Array<PowershareUser>>
}

export type PowershareCount = {
  __typename?: 'PowershareCount'
  PowershareFacilityMapping: Scalars['Int']['output']
  PowershareUserMapping: Scalars['Int']['output']
}

export type PowershareCountPowershareFacilityMappingArgs = {
  where?: InputMaybe<PowershareFacilityWhereInput>
}

export type PowershareCountPowershareUserMappingArgs = {
  where?: InputMaybe<PowershareUserWhereInput>
}

export type PowershareFacilityInput = {
  facilityId: Scalars['Int']['input']
  powershareId: Scalars['Int']['input']
}

export type PowershareFacilityListRelationFilter = {
  every?: InputMaybe<PowershareFacilityWhereInput>
  none?: InputMaybe<PowershareFacilityWhereInput>
  some?: InputMaybe<PowershareFacilityWhereInput>
}

export type PowershareFacilityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PowershareFacilityWhereInput = {
  AND?: InputMaybe<Array<PowershareFacilityWhereInput>>
  NOT?: InputMaybe<Array<PowershareFacilityWhereInput>>
  OR?: InputMaybe<Array<PowershareFacilityWhereInput>>
  facility?: InputMaybe<FacilityRelationFilter>
  facilityId?: InputMaybe<IntFilter>
  powershare?: InputMaybe<PowershareRelationFilter>
  powershareId?: InputMaybe<IntFilter>
  updatedAt?: InputMaybe<DateTimeFilter>
}

export type PowershareOnUser = {
  __typename?: 'PowershareOnUser'
  _count?: Maybe<PowershareCount>
  createdAt: Scalars['DateTimeISO']['output']
  discontinuedAt?: Maybe<Scalars['DateTimeISO']['output']>
  /** Facilities which are connected to this powershare */
  facilities?: Maybe<Array<Facility>>
  id: Scalars['ID']['output']
  installedOn?: Maybe<Scalars['DateTimeISO']['output']>
  name: Scalars['String']['output']
  /** @deprecated Use params from the PowershareOnUser. Example `user { powershares { powershare { name } } }` becomes  `users { powershares { name } }` */
  powershare: PowershareOnUser
  /** @deprecated Use `id` instead */
  powershareId: Scalars['ID']['output']
  relation: PowershareUserRoles
  updatedAt: Scalars['DateTimeISO']['output']
  /** User which has a relation to a certain powershare */
  users?: Maybe<Array<PowershareUser>>
}

export type PowershareOrderByWithRelationInput = {
  PowershareFacilityMapping?: InputMaybe<PowershareFacilityOrderByRelationAggregateInput>
  PowershareUserMapping?: InputMaybe<PowershareUserOrderByRelationAggregateInput>
  createdAt?: InputMaybe<SortOrder>
  id?: InputMaybe<SortOrder>
  installedOn?: InputMaybe<SortOrderInput>
  name?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type PowershareRelationFilter = {
  is?: InputMaybe<PowershareWhereInput>
  isNot?: InputMaybe<PowershareWhereInput>
}

export enum PowershareScalarFieldEnum {
  CreatedAt = 'createdAt',
  DiscontinuedAt = 'discontinuedAt',
  Id = 'id',
  InstalledOn = 'installedOn',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type PowershareUser = IUser & {
  __typename?: 'PowershareUser'
  colors?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTimeISO']['output']
  email: Scalars['EmailAddress']['output']
  emailVerified: Scalars['Boolean']['output']
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  legacyId?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['Locale']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  relation: PowershareUserRoles
  /** @deprecated Use params from the PowershareUser. Example `powershare { users { user { name } } }` becomes  `powershare { users { name } }` */
  user: PowershareUser
  /** @deprecated Use `id` instead */
  userId: Scalars['ID']['output']
  username?: Maybe<Scalars['String']['output']>
}

export type PowershareUserInputs = {
  powershareId: Scalars['Int']['input']
  userId: Scalars['String']['input']
}

export type PowershareUserListRelationFilter = {
  every?: InputMaybe<PowershareUserWhereInput>
  none?: InputMaybe<PowershareUserWhereInput>
  some?: InputMaybe<PowershareUserWhereInput>
}

export type PowershareUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type PowershareUserRelationInput = {
  relation: PowershareUserRoles
  userId: Scalars['String']['input']
}

export enum PowershareUserRoles {
  PowershareAdmin = 'POWERSHARE_ADMIN',
  PowershareReader = 'POWERSHARE_READER'
}

export type PowershareUserWhereInput = {
  AND?: InputMaybe<Array<PowershareUserWhereInput>>
  NOT?: InputMaybe<Array<PowershareUserWhereInput>>
  OR?: InputMaybe<Array<PowershareUserWhereInput>>
  powershare?: InputMaybe<PowershareRelationFilter>
  powershareId?: InputMaybe<IntFilter>
  relation?: InputMaybe<EnumPowershareUserRolesFilter>
  updatedAt?: InputMaybe<DateTimeFilter>
  userId?: InputMaybe<StringFilter>
}

export type PowershareWhereInput = {
  AND?: InputMaybe<Array<PowershareWhereInput>>
  NOT?: InputMaybe<Array<PowershareWhereInput>>
  OR?: InputMaybe<Array<PowershareWhereInput>>
  PowershareFacilityMapping?: InputMaybe<PowershareFacilityListRelationFilter>
  PowershareUserMapping?: InputMaybe<PowershareUserListRelationFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  id?: InputMaybe<IntFilter>
  installedOn?: InputMaybe<DateTimeNullableFilter>
  name?: InputMaybe<StringFilter>
  updatedAt?: InputMaybe<DateTimeFilter>
}

export type PowershareWhereUniqueInput = {
  AND?: InputMaybe<Array<PowershareWhereInput>>
  NOT?: InputMaybe<Array<PowershareWhereInput>>
  OR?: InputMaybe<Array<PowershareWhereInput>>
  PowershareFacilityMapping?: InputMaybe<PowershareFacilityListRelationFilter>
  PowershareUserMapping?: InputMaybe<PowershareUserListRelationFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  id?: InputMaybe<Scalars['Int']['input']>
  installedOn?: InputMaybe<DateTimeNullableFilter>
  name?: InputMaybe<StringFilter>
  updatedAt?: InputMaybe<DateTimeFilter>
}

export type PowersharesConnection = IOffsetPagination & {
  __typename?: 'PowersharesConnection'
  edges: Array<Powershare>
  totalCount: Scalars['Int']['output']
}

export type Product = {
  displayName: Scalars['String']['output']
  facility: Facility
  id: Scalars['ID']['output']
}

export type Pv = {
  __typename?: 'Pv'
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: Maybe<Scalars['Int']['output']>
  metadata?: Maybe<Metadata>
  param?: Maybe<PvParam>
  terminals?: Maybe<Array<Maybe<PvTerminal>>>
}

export type PvAttributes = {
  __typename?: 'PvAttributes'
  pid?: Maybe<Scalars['String']['output']>
  ratedPower?: Maybe<Scalars['Float']['output']>
}

export type PvAttributesInput = {
  pid?: InputMaybe<Scalars['String']['input']>
  ratedPower?: InputMaybe<Scalars['Float']['input']>
}

export type PvConfig = {
  __typename?: 'PvConfig'
  runtime?: Maybe<PvRuntime>
}

export type PvConfigInput = {
  runtime?: InputMaybe<PvRuntimeInput>
}

export type PvInput = {
  /** System-defined ID, used to identify one out of multiple clusters. */
  id?: InputMaybe<Scalars['Int']['input']>
  metadata?: InputMaybe<MetadataInput>
  param?: InputMaybe<PvParamInput>
  terminals?: InputMaybe<Array<InputMaybe<PvTerminalInput>>>
}

/** User-defined cluster parameters (high-level) for pv-clusters. */
export type PvParam = {
  __typename?: 'PvParam'
  installedCapacity: Scalars['Float']['output']
}

/** User-defined cluster parameters (high-level) for pv-clusters. */
export type PvParamInput = {
  installedCapacity: Scalars['Float']['input']
}

export type PvRuntime = {
  __typename?: 'PvRuntime'
  droopLimits?: Maybe<DroopLimits>
  runtimeMode?: Maybe<RuntimeMode>
}

export type PvRuntimeInput = {
  droopLimits?: InputMaybe<DroopLimitsInput>
  runtimeMode?: InputMaybe<RuntimeModeInput>
}

export type PvTerminal = {
  __typename?: 'PvTerminal'
  attributes?: Maybe<PvTerminalAttributes>
  config?: Maybe<PvConfig>
  id?: Maybe<Scalars['String']['output']>
  metadata?: Maybe<Metadata>
  param?: Maybe<PvTerminalParam>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a pv-terminal. */
export type PvTerminalAttributes = {
  __typename?: 'PvTerminalAttributes'
  /** Unique product idenifier for a Ferroamp product, which is configured at production */
  pid: Scalars['String']['output']
  /** Maximum rated power capability (in Watts) of the pv-terminal. */
  ratedPower?: Maybe<Scalars['Float']['output']>
}

/** Read-only attributes from a terminal that describe the identity and capabilities of a pv-terminal. */
export type PvTerminalAttributesInput = {
  /** Unique product idenifier for a Ferroamp product, which is configured at production */
  pid: Scalars['String']['input']
  /** Maximum rated power capability (in Watts) of the pv-terminal. */
  ratedPower?: InputMaybe<Scalars['Float']['input']>
}

export type PvTerminalInput = {
  attributes?: InputMaybe<PvTerminalAttributesInput>
  config?: InputMaybe<PvConfigInput>
  id?: InputMaybe<Scalars['String']['input']>
  metadata?: InputMaybe<MetadataInput>
  param?: InputMaybe<PvTerminalParamInput>
}

/** User-defined terminal parameters (high-level) for pv-terminals. */
export type PvTerminalParam = {
  __typename?: 'PvTerminalParam'
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable: Scalars['Boolean']['output']
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override: Scalars['Boolean']['output']
}

/** User-defined terminal parameters (high-level) for pv-terminals. */
export type PvTerminalParamInput = {
  /** A flag that controls whether the terminal should be Idle or Running. */
  enable: Scalars['Boolean']['input']
  /** A flag that controls whether the terminal is excluded from the configuration via PAL. This flag can be used to configure a terminal manually (for special case). */
  override: Scalars['Boolean']['input']
}

export type Query = {
  __typename?: 'Query'
  api?: Maybe<Api>
  dataRetentions: DataRetentions
  facilities?: Maybe<Array<Facility>>
  facilitiesConnection: FacilitiesConnection
  facility?: Maybe<Facility>
  me: Me
  powershare?: Maybe<Powershare>
  powershares: Array<Powershare>
  powersharesConnection: PowersharesConnection
  singleBoardComputer: SingleBoardComputer
  singleBoardComputers: Array<SingleBoardComputer>
  singleBoardComputersConnection: SingleBoardComputerConnections
  user?: Maybe<User>
  users: Array<User>
  usersConnection: UsersConnection
}

export type QueryFacilitiesArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
}

export type QueryFacilitiesConnectionArgs = {
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
}

export type QueryFacilityArgs = {
  cursor?: InputMaybe<FacilityWhereUniqueInput>
  distinct?: InputMaybe<Array<FacilityScalarFieldEnum>>
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
}

export type QueryPowershareArgs = {
  cursor?: InputMaybe<PowershareWhereUniqueInput>
  distinct?: InputMaybe<Array<PowershareScalarFieldEnum>>
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}

export type QueryPowersharesArgs = {
  cursor?: InputMaybe<PowershareWhereUniqueInput>
  distinct?: InputMaybe<Array<PowershareScalarFieldEnum>>
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}

export type QueryPowersharesConnectionArgs = {
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}

export type QuerySingleBoardComputerArgs = {
  cursor?: InputMaybe<SingleBoardComputerWhereUniqueInput>
  distinct?: InputMaybe<Array<SingleBoardComputerScalarFieldEnum>>
  orderBy?: InputMaybe<Array<SingleBoardComputerOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SingleBoardComputerWhereInput>
}

export type QuerySingleBoardComputersArgs = {
  cursor?: InputMaybe<SingleBoardComputerWhereUniqueInput>
  distinct?: InputMaybe<Array<SingleBoardComputerScalarFieldEnum>>
  orderBy?: InputMaybe<Array<SingleBoardComputerOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SingleBoardComputerWhereInput>
}

export type QuerySingleBoardComputersConnectionArgs = {
  orderBy?: InputMaybe<Array<SingleBoardComputerOrderByWithRelationInput>>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SingleBoardComputerWhereInput>
}

export type QueryUserArgs = {
  id: Scalars['String']['input']
}

export type QueryUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FindManyUsersWhereArgs>
}

export type QueryUsersConnectionArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FindManyUsersWhereArgs>
}

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RawEvseStartStopTransaction = {
  __typename?: 'RawEvseStartStopTransaction'
  connectorId?: Maybe<Scalars['Float']['output']>
  idTag?: Maybe<Scalars['String']['output']>
  meterValue?: Maybe<Scalars['Float']['output']>
  reason?: Maybe<Scalars['String']['output']>
  reservationId?: Maybe<Scalars['Float']['output']>
  startOrStop?: Maybe<Scalars['String']['output']>
  terminalId: Scalars['String']['output']
  timestamp: Scalars['DateTimeISO']['output']
  transactionId?: Maybe<Scalars['Float']['output']>
}

export type RawEvseStatusNotifications = {
  __typename?: 'RawEvseStatusNotifications'
  connectorId?: Maybe<Scalars['Float']['output']>
  errorCode?: Maybe<Scalars['String']['output']>
  info?: Maybe<Scalars['String']['output']>
  terminalId: Scalars['String']['output']
  timestamp: Scalars['DateTimeISO']['output']
  vendorErrorCode?: Maybe<Scalars['String']['output']>
  vendorId?: Maybe<Scalars['String']['output']>
}

export type RawOcpp = {
  __typename?: 'RawOCPP'
  messageType?: Maybe<Scalars['String']['output']>
  ocppMessage?: Maybe<Scalars['String']['output']>
  ocppUniqueId?: Maybe<Scalars['String']['output']>
  origin?: Maybe<Scalars['String']['output']>
  terminalId: Scalars['String']['output']
  timestamp: Scalars['DateTimeISO']['output']
}

export type RemoveAlarmSubscriberInput = {
  id: Scalars['ID']['input']
}

export type RemoveAlarmSubscriberMutationResponse = {
  __typename?: 'RemoveAlarmSubscriberMutationResponse'
  code: Scalars['Float']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  validationErrors?: Maybe<Array<ValidationError>>
}

export type RemoveUserFacilityRelationDataInput = {
  /** ID for the facility */
  facilityId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type RemoveUserFacilityRelationMutationResponse = {
  __typename?: 'RemoveUserFacilityRelationMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<AddExistingUserToFacilityError>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<FacilityUser>
}

export enum ReportingUnitDomain {
  EmsSystemTopology = 'EMS_SYSTEM_TOPOLOGY'
}

export enum RequiredActionFilter {
  Accepted = 'ACCEPTED',
  All = 'ALL',
  NotAccepted = 'NOT_ACCEPTED'
}

export enum RequiredActionType {
  Global = 'GLOBAL',
  Personal = 'PERSONAL'
}

export enum ResourceType {
  AncillaryService = 'ANCILLARY_SERVICE',
  EmsConfiguration = 'EMS_CONFIGURATION',
  SoftwareEhubConfiguration = 'SOFTWARE_EHUB_CONFIGURATION',
  UpstreamDataConfiguration = 'UPSTREAM_DATA_CONFIGURATION'
}

export type RuntimeMode = {
  __typename?: 'RuntimeMode'
  mode?: Maybe<Scalars['Int']['output']>
  ref1?: Maybe<Scalars['Float']['output']>
  ref2?: Maybe<Scalars['Float']['output']>
}

export type RuntimeModeInput = {
  mode?: InputMaybe<Scalars['Int']['input']>
  ref1?: InputMaybe<Scalars['Float']['input']>
  ref2?: InputMaybe<Scalars['Float']['input']>
}

export enum SbcGeneration {
  Bbb = 'BBB',
  Core = 'CORE'
}

export type SbcTransitionError = {
  __typename?: 'SBCTransitionError'
  allowedTransitions: Array<SingleBoardComputerLifeCycleStages>
}

/** Occurs if the Single Board Computer that we want to map to is in a invalid stage. */
export type SbcBadLifeCycleStageError = {
  __typename?: 'SbcBadLifeCycleStageError'
  allowedStages: Array<SingleBoardComputerLifeCycleStages>
}

export type SetCapacity = {
  installedPv?: InputMaybe<Scalars['Float']['input']>
}

export type SetSingleBoardComputerLifeCycleStageDataInput = {
  stage: SingleBoardComputerLifeCycleStages
}

export type SetSingleBoardComputerLifeCycleStageErrors = SbcTransitionError

export type SetSingleBoardComputerLifeCycleStageResponse = {
  __typename?: 'SetSingleBoardComputerLifeCycleStageResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<SetSingleBoardComputerLifeCycleStageErrors>>
  message: Scalars['String']['output']
  singleBoardComputer?: Maybe<SingleBoardComputer>
  success: Scalars['Boolean']['output']
}

export type SetSingleBoardComputerLifeCycleStageWhereInput = {
  productId: Scalars['String']['input']
}

export type SingleBoardComputer = {
  __typename?: 'SingleBoardComputer'
  _count?: Maybe<SingleBoardComputerCount>
  allowedLifeCycleTransitions: Array<AllowedSbcLifeCycleTransitions>
  bbb?: Maybe<BeagleboardExtras>
  createdAt: Scalars['DateTimeISO']['output']
  /** If single board computer is connected to a facility. */
  facility?: Maybe<Facility>
  generation: SbcGeneration
  installedAt?: Maybe<Scalars['DateTimeISO']['output']>
  lastSeenAt?: Maybe<Scalars['DateTimeISO']['output']>
  lifeCycleStage: SingleBoardComputerLifeCycleStages
  productId: Scalars['ID']['output']
  version?: Maybe<Scalars['String']['output']>
  versionChangeLog: Array<SingleBoardComputerVersionChangeLog>
}

export type SingleBoardComputerVersionChangeLogArgs = {
  orderBy?: InputMaybe<SingleBoardComputerVersionChangeLogOrderByArg>
}

export type SingleBoardComputerConnections = IOffsetPagination & {
  __typename?: 'SingleBoardComputerConnections'
  edges: Array<SingleBoardComputer>
  totalCount: Scalars['Int']['output']
}

export type SingleBoardComputerCount = {
  __typename?: 'SingleBoardComputerCount'
  versionChangeLogs: Scalars['Int']['output']
}

export type SingleBoardComputerCountVersionChangeLogsArgs = {
  where?: InputMaybe<SingleBoardComputerVersionChangeLogWhereInput>
}

export enum SingleBoardComputerLifeCycleStages {
  Active = 'ACTIVE',
  Commissioning = 'COMMISSIONING',
  Decommissioned = 'DECOMMISSIONED',
  Installed = 'INSTALLED',
  Refurbished = 'REFURBISHED',
  ResearchUnit = 'RESEARCH_UNIT',
  Service = 'SERVICE',
  Shipped = 'SHIPPED'
}

export type SingleBoardComputerNullableRelationFilter = {
  is?: InputMaybe<SingleBoardComputerWhereInput>
  isNot?: InputMaybe<SingleBoardComputerWhereInput>
}

export type SingleBoardComputerOrderByWithRelationInput = {
  bbb?: InputMaybe<BeagleboardExtrasOrderByWithRelationInput>
  facilityMapping?: InputMaybe<FacilityMappingOrderByWithRelationInput>
  installedAt?: InputMaybe<SortOrderInput>
  lifeCycleStage?: InputMaybe<SortOrder>
  productId?: InputMaybe<SortOrder>
  systemUpdaterMaintenanceFlag?: InputMaybe<SystemUpdaterMaintenanceFlagOrderByWithRelationInput>
  versionChangeLogs?: InputMaybe<SingleBoardComputerVersionChangeLogOrderByRelationAggregateInput>
}

export type SingleBoardComputerRelationFilter = {
  is?: InputMaybe<SingleBoardComputerWhereInput>
  isNot?: InputMaybe<SingleBoardComputerWhereInput>
}

export enum SingleBoardComputerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Generation = 'generation',
  InstalledAt = 'installedAt',
  LastSeenAt = 'lastSeenAt',
  LifeCycleStage = 'lifeCycleStage',
  ProductId = 'productId',
  Version = 'version'
}

export type SingleBoardComputerVersionChangeLog = {
  __typename?: 'SingleBoardComputerVersionChangeLog'
  fromVersion?: Maybe<Scalars['String']['output']>
  productId: Scalars['String']['output']
  toVersion: Scalars['String']['output']
  updatedAt: Scalars['DateTimeISO']['output']
}

export type SingleBoardComputerVersionChangeLogListRelationFilter = {
  every?: InputMaybe<SingleBoardComputerVersionChangeLogWhereInput>
  none?: InputMaybe<SingleBoardComputerVersionChangeLogWhereInput>
  some?: InputMaybe<SingleBoardComputerVersionChangeLogWhereInput>
}

export type SingleBoardComputerVersionChangeLogOrderByArg = {
  fromVersion?: InputMaybe<SortOrder>
  toVersion?: InputMaybe<SortOrder>
  updatedAt?: InputMaybe<SortOrder>
}

export type SingleBoardComputerVersionChangeLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>
}

export type SingleBoardComputerVersionChangeLogWhereInput = {
  AND?: InputMaybe<Array<SingleBoardComputerVersionChangeLogWhereInput>>
  NOT?: InputMaybe<Array<SingleBoardComputerVersionChangeLogWhereInput>>
  OR?: InputMaybe<Array<SingleBoardComputerVersionChangeLogWhereInput>>
  fromVersion?: InputMaybe<StringNullableFilter>
  productId?: InputMaybe<StringFilter>
  sbc?: InputMaybe<SingleBoardComputerRelationFilter>
  toVersion?: InputMaybe<StringFilter>
}

export type SingleBoardComputerWhereInput = {
  AND?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  NOT?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  OR?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  bbb?: InputMaybe<BeagleboardExtrasNullableRelationFilter>
  facilityMapping?: InputMaybe<FacilityMappingNullableRelationFilter>
  installedAt?: InputMaybe<DateTimeNullableFilter>
  lifeCycleStage?: InputMaybe<EnumSingleBoardComputerLifeCycleStagesFilter>
  productId?: InputMaybe<StringFilter>
  systemUpdaterMaintenanceFlag?: InputMaybe<SystemUpdaterMaintenanceFlagNullableRelationFilter>
  versionChangeLogs?: InputMaybe<SingleBoardComputerVersionChangeLogListRelationFilter>
}

export type SingleBoardComputerWhereUniqueInput = {
  AND?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  NOT?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  OR?: InputMaybe<Array<SingleBoardComputerWhereInput>>
  bbb?: InputMaybe<BeagleboardExtrasNullableRelationFilter>
  facilityMapping?: InputMaybe<FacilityMappingNullableRelationFilter>
  installedAt?: InputMaybe<DateTimeNullableFilter>
  lifeCycleStage?: InputMaybe<EnumSingleBoardComputerLifeCycleStagesFilter>
  productId?: InputMaybe<Scalars['String']['input']>
  systemUpdaterMaintenanceFlag?: InputMaybe<SystemUpdaterMaintenanceFlagNullableRelationFilter>
  versionChangeLogs?: InputMaybe<SingleBoardComputerVersionChangeLogListRelationFilter>
}

/** Asymmetric limits on state-of-charge (SoC) of the battery. */
export type SocLimits = {
  __typename?: 'SocLimits'
  high: Scalars['Int']['output']
  low: Scalars['Int']['output']
}

/** Asymmetric limits on state-of-charge (SoC) of the battery. */
export type SocLimitsInput = {
  high: Scalars['Int']['input']
  low: Scalars['Int']['input']
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>
  sort: SortOrder
}

export type SsoPower = {
  __typename?: 'SsoPower'
  /** Average SSO Power per minute */
  power: Scalars['Float']['output']
  /** Timestamp of the first second of the minute for which we're average */
  timestamp: Scalars['DateTimeISO']['output']
}

export type SsoProduct = Product & {
  __typename?: 'SsoProduct'
  displayName: Scalars['String']['output']
  facility: Facility
  id: Scalars['ID']['output']
  power: Array<SsoPower>
}

export type SsoProductPowerArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
  timeRange: DatetimeRangeQuery
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringNullableFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export enum SubscriptionType {
  Email = 'EMAIL',
  UserEmail = 'USER_EMAIL'
}

/** Payload for EMS Configuration. User level settings for how the system shall operate */
export type SystemApiEmsConfigurationInputV1 = {
  /** The mode identifier is used to select an EMS application use-case */
  systemMode: SystemModeV1
  /** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
  systemSettingsBattery: SystemLevelSettingsBatteryInputV1
  /** EMS parameters for controlling Grid-tied inverters. */
  systemSettingsGrid: SystemLevelSettingsGridInputV1
  /** EMS parameters for controlling PV. */
  systemSettingsPv: SystemLevelSettingsPvInputV1
}

/** Payload for EMS Configuration. User level settings for how the system shall operate */
export type SystemApiEmsConfigurationInputV2 = {
  /** The mode identifier is used to select an EMS application use-case */
  systemMode: SystemModeV2
  /** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
  systemSettingsBattery: SystemLevelSettingsBatteryInputV2
  /** EMS parameters for controlling Grid-tied inverters. */
  systemSettingsGrid: SystemLevelSettingsGridInputV2
  /** EMS parameters for controlling PV. */
  systemSettingsPv: SystemLevelSettingsPvInputV2
}

export type SystemApiEmsConfigurationTypes = SystemApiEmsConfigurationV1 | SystemApiEmsConfigurationV2

/** Payload for EMS Configuration. User level settings for how the system shall operate */
export type SystemApiEmsConfigurationV1 = {
  __typename?: 'SystemApiEmsConfigurationV1'
  /** ID used for message tracing or matching */
  correlationId: Scalars['String']['output']
  /** The mode identifier is used to select an EMS application use-case */
  systemMode: SystemModeV1
  /** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
  systemSettingsBattery: SystemLevelSettingsBatteryV1
  /** EMS parameters for controlling Grid-tied inverters. */
  systemSettingsGrid: SystemLevelSettingsGridV1
  /** EMS parameters for controlling PV. */
  systemSettingsPv: SystemLevelSettingsPvV1
  /** Time command was received or sent */
  timestamp: Scalars['DateTimeISO']['output']
}

/** Payload for EMS Configuration. User level settings for how the system shall operate */
export type SystemApiEmsConfigurationV2 = {
  __typename?: 'SystemApiEmsConfigurationV2'
  /** ID used for message tracing or matching */
  correlationId: Scalars['String']['output']
  /** The mode identifier is used to select an EMS application use-case */
  systemMode: SystemModeV2
  /** EMS parameters for controlling the Battery Energy Storage System (B-ESS). */
  systemSettingsBattery: SystemLevelSettingsBatteryV2
  /** EMS parameters for controlling Grid-tied inverters. */
  systemSettingsGrid: SystemLevelSettingsGridV2
  /** EMS parameters for controlling PV. */
  systemSettingsPv: SystemLevelSettingsPvV2
  /** Time command was received or sent */
  timestamp: Scalars['DateTimeISO']['output']
}

export type SystemControlTopology = {
  __typename?: 'SystemControlTopology'
  /** A list of clusters of Battery Energy Storage systems (B-ESS). */
  battery?: Maybe<Array<Maybe<Battery>>>
  /** A list of clusters of Electric Vehicle Service Equipment/EVSE (i.e. chargers). EVSEs can be connected to eitther AC or DC side of the system. */
  evse?: Maybe<Array<Maybe<Evse>>>
  /** A list of clusters of grid-tied inverters connected to distinct supply/metering points. */
  grid?: Maybe<Array<Maybe<Grid>>>
  /** A list of clusters of PV-connected DC/DC converters. */
  pv?: Maybe<Array<Maybe<Pv>>>
}

export type SystemControlTopologyConfiguration = {
  __typename?: 'SystemControlTopologyConfiguration'
  data: SystemControlTopology
  facility: Facility
  transTs: Scalars['Date']['output']
}

export type SystemControlTopologyInput = {
  /** A list of clusters of Battery Energy Storage systems (B-ESS). */
  battery?: InputMaybe<Array<InputMaybe<BatteryInput>>>
  /** A list of clusters of Electric Vehicle Service Equipment/EVSE (i.e. chargers). EVSEs can be connected to eitther AC or DC side of the system. */
  evse?: InputMaybe<Array<InputMaybe<EvseInput>>>
  /** A list of clusters of grid-tied inverters connected to distinct supply/metering points. */
  grid?: InputMaybe<Array<InputMaybe<GridInput>>>
  /** A list of clusters of PV-connected DC/DC converters. */
  pv?: InputMaybe<Array<InputMaybe<PvInput>>>
}

export type SystemControlTopologySendPayload = {
  __typename?: 'SystemControlTopologySendPayload'
  status: Scalars['String']['output']
}

export type SystemEnergyDate = ISystemEnergy & {
  __typename?: 'SystemEnergyDate'
  /**
   * Energy consumed by the facility, total
   *     calculated 3 phase.
   */
  consumption: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 1. */
  consumptionL1: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 2. */
  consumptionL2: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 3. */
  consumptionL3: Scalars['Float']['output']
  /**
   * Energy produced by the facility, total
   *     calculated 3 phase. This can occur if there is another producing entity
   *     in the facility, for example another solar production system.
   */
  consumptionProduced: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 1. */
  consumptionProducedL1: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 2. */
  consumptionProducedL2: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 3. */
  consumptionProducedL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for energy consumed
   *     by EV Charges controlled by system.
   *     Will be null if system has no EV Chargers connected
   */
  evseConsumed?: Maybe<Scalars['Float']['output']>
  /**
   * Energy exported over the main fuse
   *     at external current transformers, total calculated 3 phase
   */
  gridExport: Scalars['Float']['output']
  /** Energy exported to the grid on phase 1. */
  gridExportL1: Scalars['Float']['output']
  /** Energy exported to the grid on phase 2. */
  gridExportL2: Scalars['Float']['output']
  /** Energy exported to the grid on phase 3. */
  gridExportL3: Scalars['Float']['output']
  /**
   * Energy imported over the main fuse at
   *     external current transformers, total calculated 3 phase
   */
  gridImport: Scalars['Float']['output']
  /** Energy imported from the grid on phase 1. */
  gridImportL1: Scalars['Float']['output']
  /** Energy imported from the grid on phase 2. */
  gridImportL2: Scalars['Float']['output']
  /** Energy imported from the grid on phase 3. */
  gridImportL3: Scalars['Float']['output']
  /** Energy exported from inverter, total calculated 3 phase. */
  inverterExport: Scalars['Float']['output']
  /** Energy exported from inverted on phase 1. */
  inverterExportL1: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 2. */
  inverterExportL2: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 3. */
  inverterExportL3: Scalars['Float']['output']
  /** Energy import to inverter, total calculated 3 phase. */
  inverterImport: Scalars['Float']['output']
  /** Energy imported to inverted on phase 1. */
  inverterImportL1: Scalars['Float']['output']
  /** Energy imported to inverted on phase 2. */
  inverterImportL2: Scalars['Float']['output']
  /** Energy imported to inverted on phase 3. */
  inverterImportL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for Solar
   *      Energy produced by system. Will be null if system
   *       has no PV connected
   */
  pvProduced?: Maybe<Scalars['Float']['output']>
  /**
   * Signalling if the record describes a complete
   *   time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
  /**
   * Date of the energycounter, format: "YYYY-MM-DD".
   * Available only for YEARLY,MONTHLY,WEEKLY and DAILY
   * Timezone: FacilityTimezone
   */
  timeperiodStartDay: Scalars['FacilityDate']['output']
}

export type SystemEnergyDateTime = ISystemEnergy & {
  __typename?: 'SystemEnergyDateTime'
  /**
   * Energy consumed by the facility, total
   *     calculated 3 phase.
   */
  consumption: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 1. */
  consumptionL1: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 2. */
  consumptionL2: Scalars['Float']['output']
  /** Energy consumed by the facility on phase 3. */
  consumptionL3: Scalars['Float']['output']
  /**
   * Energy produced by the facility, total
   *     calculated 3 phase. This can occur if there is another producing entity
   *     in the facility, for example another solar production system.
   */
  consumptionProduced: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 1. */
  consumptionProducedL1: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 2. */
  consumptionProducedL2: Scalars['Float']['output']
  /** Energy from external production system in the facility phase 3. */
  consumptionProducedL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for energy consumed
   *     by EV Charges controlled by system.
   *     Will be null if system has no EV Chargers connected
   */
  evseConsumed?: Maybe<Scalars['Float']['output']>
  /**
   * Energy exported over the main fuse
   *     at external current transformers, total calculated 3 phase
   */
  gridExport: Scalars['Float']['output']
  /** Energy exported to the grid on phase 1. */
  gridExportL1: Scalars['Float']['output']
  /** Energy exported to the grid on phase 2. */
  gridExportL2: Scalars['Float']['output']
  /** Energy exported to the grid on phase 3. */
  gridExportL3: Scalars['Float']['output']
  /**
   * Energy imported over the main fuse at
   *     external current transformers, total calculated 3 phase
   */
  gridImport: Scalars['Float']['output']
  /** Energy imported from the grid on phase 1. */
  gridImportL1: Scalars['Float']['output']
  /** Energy imported from the grid on phase 2. */
  gridImportL2: Scalars['Float']['output']
  /** Energy imported from the grid on phase 3. */
  gridImportL3: Scalars['Float']['output']
  /** Energy exported from inverter, total calculated 3 phase. */
  inverterExport: Scalars['Float']['output']
  /** Energy exported from inverted on phase 1. */
  inverterExportL1: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 2. */
  inverterExportL2: Scalars['Float']['output']
  /** Energy  exported from inverted on phase 3. */
  inverterExportL3: Scalars['Float']['output']
  /** Energy import to inverter, total calculated 3 phase. */
  inverterImport: Scalars['Float']['output']
  /** Energy imported to inverted on phase 1. */
  inverterImportL1: Scalars['Float']['output']
  /** Energy imported to inverted on phase 2. */
  inverterImportL2: Scalars['Float']['output']
  /** Energy imported to inverted on phase 3. */
  inverterImportL3: Scalars['Float']['output']
  /**
   * Aggregated Energy for Solar
   *      Energy produced by system. Will be null if system
   *       has no PV connected
   */
  pvProduced?: Maybe<Scalars['Float']['output']>
  /**
   * Signalling if the record describes a complete
   *   time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
  /**
   * Timestamp of the start of the timeperiod we represent, i.e. the first millisecond
   * of the 15 minute period we are reporting on. Timestamp is always in UTC
   * Only available for HOURLY AND 15M
   */
  timeperiodStart: Scalars['DateTimeISO']['output']
}

export type SystemLevelSettingsBatteryInputV1 = {
  /** Asymmetric charge power references for battery. positive values only. [Unit: W] */
  powerRefCharge: Scalars['Float']['input']
  /** Asymmetric discharge power references for battery. positive values only. [Unit: W] */
  powerRefDischarge: Scalars['Float']['input']
  /** Asymmetric reference for battery state-of-charge (SoC) high. [Default Value: 100] [Max: 100] [Unit: percentage, 0-1] */
  socRefHigh: Scalars['Float']['input']
  /** Asymmetric reference for battery state-of-charge (SoC) low. [Max: 100] [Unit: percentage, 0-1] */
  socRefLow: Scalars['Float']['input']
}

export type SystemLevelSettingsBatteryInputV2 = {
  /** Asymmetric charge power references for battery. positive values only. [Unit: W] */
  powerRefCharge: Scalars['Float']['input']
  /** Asymmetric discharge power references for battery. positive values only. [Unit: W] */
  powerRefDischarge: Scalars['Float']['input']
  /** Asymmetric reference for battery state-of-charge (SoC) high. [Default Value: 100] [Max: 100] [Unit: percentage, 0-1] */
  socRefHigh: Scalars['Float']['input']
  /** Asymmetric reference for battery state-of-charge (SoC) low. [Max: 100] [Unit: percentage, 0-1] */
  socRefLow: Scalars['Float']['input']
}

export type SystemLevelSettingsBatteryV1 = {
  __typename?: 'SystemLevelSettingsBatteryV1'
  /** Asymmetric charge power references for battery. positive values only. [Unit: W] */
  powerRefCharge: Scalars['Float']['output']
  /** Asymmetric discharge power references for battery. positive values only. [Unit: W] */
  powerRefDischarge: Scalars['Float']['output']
  /** Asymmetric reference for battery state-of-charge (SoC) high. [Default Value: 100] [Max: 100] [Unit: percentage, 0-1] */
  socRefHigh: Scalars['Float']['output']
  /** Asymmetric reference for battery state-of-charge (SoC) low. [Max: 100] [Unit: percentage, 0-1] */
  socRefLow: Scalars['Float']['output']
}

export type SystemLevelSettingsBatteryV2 = {
  __typename?: 'SystemLevelSettingsBatteryV2'
  /** Asymmetric charge power references for battery. positive values only. [Unit: W] */
  powerRefCharge: Scalars['Float']['output']
  /** Asymmetric discharge power references for battery. positive values only. [Unit: W] */
  powerRefDischarge: Scalars['Float']['output']
  /** Asymmetric reference for battery state-of-charge (SoC) high. [Default Value: 100] [Max: 100] [Unit: percentage, 0-1] */
  socRefHigh: Scalars['Float']['output']
  /** Asymmetric reference for battery state-of-charge (SoC) low. [Max: 100] [Unit: percentage, 0-1] */
  socRefLow: Scalars['Float']['output']
}

export type SystemLevelSettingsGridInputV1 = {
  /**  The mode identifier is used to select an ACE operation use-case. false = ACE Disabled; true = ACE Enabled */
  aceMode: Scalars['Boolean']['input']
  /** A symmetric current threshold below which ACE is disabled. [Unit: Arms] */
  aceThreshold: Scalars['Float']['input']
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['input']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['input']
  /** Asymmetric power threshold (high) for grid-feedback control. (power_threshold_high >= power_threshold_low). Positive values only. [Default Value: 10000000] [Unit: W] */
  powerThresholdHigh: Scalars['Float']['input']
  /** Asymmetric power threshold (low) for grid-feedback control. (power_threshold_high >= power_threshold_low. Negative values only.) [Default Value: -10000000] [Unit: W] */
  powerThresholdLow: Scalars['Float']['input']
}

export type SystemLevelSettingsGridInputV2 = {
  /**  The mode identifier is used to select an ACE operation use-case. false = ACE Disabled; true = ACE Enabled */
  aceMode: Scalars['Boolean']['input']
  /** A symmetric current threshold below which ACE is disabled. [Unit: Arms] */
  aceThreshold: Scalars['Float']['input']
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['input']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['input']
  /** Asymmetric power threshold (high) for grid-feedback control. (power_threshold_high >= power_threshold_low). Positive values only. [Default Value: 10000000] [Unit: W] */
  powerThresholdHigh: Scalars['Float']['input']
  /** Asymmetric power threshold (low) for grid-feedback control. (power_threshold_high >= power_threshold_low. Negative values only.) [Default Value: -10000000] [Unit: W] */
  powerThresholdLow: Scalars['Float']['input']
}

export type SystemLevelSettingsGridV1 = {
  __typename?: 'SystemLevelSettingsGridV1'
  /**  The mode identifier is used to select an ACE operation use-case. false = ACE Disabled; true = ACE Enabled */
  aceMode: Scalars['Boolean']['output']
  /** A symmetric current threshold below which ACE is disabled. [Unit: Arms] */
  aceThreshold: Scalars['Float']['output']
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['output']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['output']
  /** Asymmetric power threshold (high) for grid-feedback control. (power_threshold_high >= power_threshold_low). Positive values only. [Default Value: 10000000] [Unit: W] */
  powerThresholdHigh: Scalars['Float']['output']
  /** Asymmetric power threshold (low) for grid-feedback control. (power_threshold_high >= power_threshold_low. Negative values only.) [Default Value: -10000000] [Unit: W] */
  powerThresholdLow: Scalars['Float']['output']
}

export type SystemLevelSettingsGridV2 = {
  __typename?: 'SystemLevelSettingsGridV2'
  /**  The mode identifier is used to select an ACE operation use-case. false = ACE Disabled; true = ACE Enabled */
  aceMode: Scalars['Boolean']['output']
  /** A symmetric current threshold below which ACE is disabled. [Unit: Arms] */
  aceThreshold: Scalars['Float']['output']
  /** A flag that indicates whether grid-export threshold should be treated as a hard-limit. */
  limitExport: Scalars['Boolean']['output']
  /** A flag that indicates whether grid-import threshold should be treated as a hard-limit. */
  limitImport: Scalars['Boolean']['output']
  /** Asymmetric power threshold (high) for grid-feedback control. (power_threshold_high >= power_threshold_low). Positive values only. [Default Value: 10000000] [Unit: W] */
  powerThresholdHigh: Scalars['Float']['output']
  /** Asymmetric power threshold (low) for grid-feedback control. (power_threshold_high >= power_threshold_low. Negative values only.) [Default Value: -10000000] [Unit: W] */
  powerThresholdLow: Scalars['Float']['output']
}

export type SystemLevelSettingsPvInputV1 = {
  /** The mode identifier is used to select an PV operation use-case. false = PV Disabled; true = PV Enabled [Default Value: true] */
  pvMode: Scalars['Boolean']['input']
}

export type SystemLevelSettingsPvInputV2 = {
  /** The mode identifier is used to select an PV operation use-case. false = PV Disabled; true = PV Enabled [Default Value: true] */
  pvMode: Scalars['Boolean']['input']
}

export type SystemLevelSettingsPvV1 = {
  __typename?: 'SystemLevelSettingsPvV1'
  /** The mode identifier is used to select an PV operation use-case. false = PV Disabled; true = PV Enabled [Default Value: true] */
  pvMode: Scalars['Boolean']['output']
}

export type SystemLevelSettingsPvV2 = {
  __typename?: 'SystemLevelSettingsPvV2'
  /** The mode identifier is used to select an PV operation use-case. false = PV Disabled; true = PV Enabled [Default Value: true] */
  pvMode: Scalars['Boolean']['output']
}

/** Default = (PV + Battery CPC*); Peak Shaving, Maximize PV self-consumption. *CPC - Constant Power Control */
export enum SystemModeV1 {
  Default = 'Default',
  PeakShaving = 'PeakShaving',
  SelfConsumption = 'SelfConsumption'
}

/** EMS Operation Mode. For definition see EMS *Operation Modes in system requirements */
export enum SystemModeV2 {
  Default = 'Default',
  Measurement = 'Measurement',
  PeakShaving = 'PeakShaving',
  SelfConsumption = 'SelfConsumption'
}

export type SystemUpdaterMaintenanceFlagNullableRelationFilter = {
  is?: InputMaybe<SystemUpdaterMaintenanceFlagWhereInput>
  isNot?: InputMaybe<SystemUpdaterMaintenanceFlagWhereInput>
}

export type SystemUpdaterMaintenanceFlagOrderByWithRelationInput = {
  maintenanceFlag?: InputMaybe<SortOrder>
  productId?: InputMaybe<SortOrder>
  singleBoardComputer?: InputMaybe<SingleBoardComputerOrderByWithRelationInput>
}

export type SystemUpdaterMaintenanceFlagWhereInput = {
  AND?: InputMaybe<Array<SystemUpdaterMaintenanceFlagWhereInput>>
  NOT?: InputMaybe<Array<SystemUpdaterMaintenanceFlagWhereInput>>
  OR?: InputMaybe<Array<SystemUpdaterMaintenanceFlagWhereInput>>
  maintenanceFlag?: InputMaybe<BoolFilter>
  productId?: InputMaybe<StringFilter>
  singleBoardComputer?: InputMaybe<SingleBoardComputerNullableRelationFilter>
}

/** UI Battery Power Gauge */
export type UiBatteryPowerGauge = IuiGauge & {
  __typename?: 'UIBatteryPowerGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

export type UiCapacityUnion =
  | UiEnergyStorageRatedCapacity
  | UiEnergyStorageRatedPowerCapacity
  | UiMainFuseCapacity
  | UiMaxInverterCurrentCapacity
  | UiMaxInverterPowerCapacity

export type UiComponents = {
  __typename?: 'UIComponents'
  capacities: Array<UiCapacityUnion>
  flowChart: UiFlowChart
  gauges: Array<UiGaugeUnion>
}

/** UI Consumption Power Gauge */
export type UiConsumptionPowerGauge = IuiGauge & {
  __typename?: 'UIConsumptionPowerGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

/** UI Energy Storage Rated Capacity. How much energy can the energy storage store. */
export type UiEnergyStorageRatedCapacity = IuiCapacity & {
  __typename?: 'UIEnergyStorageRatedCapacity'
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** UI Energy Storage Rated Power. Max power the energy storage can handle */
export type UiEnergyStorageRatedPowerCapacity = IuiCapacity & {
  __typename?: 'UIEnergyStorageRatedPowerCapacity'
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** UI EVSE Power Gauge */
export type UiEvsePowerGauge = IuiGauge & {
  __typename?: 'UIEvsePowerGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

export type UiFlowChart = {
  __typename?: 'UIFlowChart'
  /** Components to be enabled */
  enabledComponents: Array<UiFlowChartComponent>
}

export enum UiFlowChartComponent {
  Battery = 'Battery',
  Consumption = 'Consumption',
  Ehub = 'Ehub',
  Evse = 'Evse',
  Grid = 'Grid',
  Solar = 'Solar'
}

export type UiGaugeUnion =
  | UiBatteryPowerGauge
  | UiConsumptionPowerGauge
  | UiEvsePowerGauge
  | UiGridCurrentGauge
  | UiGridPowerGauge
  | UiSolarPowerGauge

/** UI Grid Current Gauge */
export type UiGridCurrentGauge = IuiGauge & {
  __typename?: 'UIGridCurrentGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

/** UI Grid Power Gauge */
export type UiGridPowerGauge = IuiGauge & {
  __typename?: 'UIGridPowerGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

/** UI Main Fuse Capacity. Size of the main fuse. */
export type UiMainFuseCapacity = IuiCapacity & {
  __typename?: 'UIMainFuseCapacity'
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** UI Max Inverter Current. Total current that can be generated from the inverters  */
export type UiMaxInverterCurrentCapacity = IuiCapacity & {
  __typename?: 'UIMaxInverterCurrentCapacity'
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** UI Max Inverter Power. Max power of the inverters  */
export type UiMaxInverterPowerCapacity = IuiCapacity & {
  __typename?: 'UIMaxInverterPowerCapacity'
  /** The capacity of the  */
  capacity?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  unit: Scalars['String']['output']
}

/** UI Solar Power Gauge */
export type UiSolarPowerGauge = IuiGauge & {
  __typename?: 'UISolarPowerGauge'
  id: Scalars['ID']['output']
  /** Maximum value of the gauge */
  max: Scalars['Float']['output']
  /** Minimum value of the gauge */
  min: Scalars['Float']['output']
  unit: Scalars['String']['output']
}

/** Payload for events, used by all Ferroamp products. */
export type UnifiedEvents = {
  __typename?: 'UnifiedEvents'
  /** Indicates the application version of the device. Useful to know what versions are triggering what events. */
  appVersion: Scalars['String']['output']
  /** ID used for message tracing or matching */
  correlationId: Scalars['String']['output']
  event: FerroampEvent
  /** Name of application emitting event */
  eventsource: EventSource
  namespace: EventNamespace
  /** Time event was sent, in milliseconds since epoch. */
  timestamp?: Maybe<Scalars['DateTimeISO']['output']>
}

export type UnitEnergyDate = IUnitEnergy & {
  __typename?: 'UnitEnergyDate'
  /** Energycounter, consumed energy of timeperiod */
  consumed: Scalars['Float']['output']
  /** Energycounter, produced energy of timeperiod */
  produced: Scalars['Float']['output']
  /**
   * The domain of the Reporting Unit.
   *       Currently only one domain is recognized,
   *       so this field is for future expansion purposes
   */
  reportingUnitDomain: Scalars['String']['output']
  /** ID within above domain for the Reporting Unit. For the case of EMS System Topology, this is the terminal ID */
  reportingUnitId: Scalars['String']['output']
  /**
   * Signalling if the record describes a complete
   * time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
  /**
   * Date of the energycounter, format: "YYYY-MM-DD".
   *     Available only for YEARLY,MONTHLY,WEEKLY and DAILY
   */
  timeperiodStartDay: Scalars['FacilityDate']['output']
}

export type UnitEnergyDateTime = IUnitEnergy & {
  __typename?: 'UnitEnergyDateTime'
  /** Energycounter, consumed energy of timeperiod */
  consumed: Scalars['Float']['output']
  /** Energycounter, produced energy of timeperiod */
  produced: Scalars['Float']['output']
  /**
   * The domain of the Reporting Unit.
   *       Currently only one domain is recognized,
   *       so this field is for future expansion purposes
   */
  reportingUnitDomain: Scalars['String']['output']
  /** ID within above domain for the Reporting Unit. For the case of EMS System Topology, this is the terminal ID */
  reportingUnitId: Scalars['String']['output']
  /**
   * Signalling if the record describes a complete
   * time period or a time period that is still being reported to.
   */
  timeperiodDone: Scalars['Boolean']['output']
  /**
   * Timestamp of the start of the timeperiod we represent, i.e. the first millisecond of the
   * 15 minute period we are reporting on. Timestamp is always in UTC.
   * Only available for HOURLY AND 15M
   */
  timeperiodStart: Scalars['DateTimeISO']['output']
}

export type UpdateFacilityLifeCycleErrors =
  | FacilityLifeCycleTransitionError
  | FacilityNotFound
  | ValidationError

export type UpdateFacilityLifeCycleMutationResponse = {
  __typename?: 'UpdateFacilityLifeCycleMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<UpdateFacilityLifeCycleErrors>>
  lifeCycle?: Maybe<FacilityLifeCycles>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type UpdateOnFacilityLocationError = FacilityNotFound | ValidationError

export type UpdateOneFacilityDataInput = {
  installedOn?: InputMaybe<Scalars['DateTimeISO']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOneFacilityErrors = FacilityNotFound | ValidationError

export type UpdateOneFacilityLifeCycleData = {
  transitionToStage: FacilityLifeCycleStages
}

export type UpdateOneFacilityLocationDataInput = {
  address?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  electricalGridId?: InputMaybe<Scalars['String']['input']>
  latitude?: InputMaybe<Scalars['Float']['input']>
  longitude?: InputMaybe<Scalars['Float']['input']>
  propertyDesignation?: InputMaybe<Scalars['String']['input']>
}

export type UpdateOneFacilityLocationMutationResponse = {
  __typename?: 'UpdateOneFacilityLocationMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<UpdateOnFacilityLocationError>>
  location?: Maybe<FacilityLocation>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type UpdateOneFacilityMiscDataInput = {
  costElectricityKWh?: InputMaybe<Scalars['Float']['input']>
  currencyCode?: InputMaybe<Scalars['String']['input']>
  revenueSoldToGridkWh?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateOneFacilityMiscError = FacilityNotFound | ValidationError

export type UpdateOneFacilityMiscMutationResponse = {
  __typename?: 'UpdateOneFacilityMiscMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<UpdateOneFacilityMiscError>>
  message: Scalars['String']['output']
  misc?: Maybe<FacilityMisc>
  success: Scalars['Boolean']['output']
}

export type UpdateOneFacilityMutationResponse = {
  __typename?: 'UpdateOneFacilityMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<UpdateOneFacilityErrors>>
  facility?: Maybe<Facility>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type UpdateOnePowershareInputs = {
  installedOn?: InputMaybe<Scalars['DateTimeISO']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserFacilityRelationDataInput = {
  /** ID for the facility */
  facilityId: Scalars['ID']['input']
  relation: FacilityUserRoles
  userId: Scalars['ID']['input']
}

export type UpdateUserFacilityRelationMutationResponse = {
  __typename?: 'UpdateUserFacilityRelationMutationResponse'
  /** Http Status Code */
  code: Scalars['Int']['output']
  errors?: Maybe<Array<AddExistingUserToFacilityError>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<FacilityUser>
}

export type UpsertEmsConfiguration = {
  __typename?: 'UpsertEmsConfiguration'
  generation: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  validationFailure?: Maybe<Scalars['String']['output']>
}

export type UpsertEmsConfigurationDataArgs = {
  v1?: InputMaybe<SystemApiEmsConfigurationInputV1>
  v2?: InputMaybe<SystemApiEmsConfigurationInputV2>
}

export type User = IUser & {
  __typename?: 'User'
  colors?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTimeISO']['output']
  email: Scalars['EmailAddress']['output']
  emailVerified: Scalars['Boolean']['output']
  facilities: Array<FacilityOnUser>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName?: Maybe<Scalars['String']['output']>
  legacyId?: Maybe<Scalars['Int']['output']>
  locale?: Maybe<Scalars['Locale']['output']>
  name?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['PhoneNumber']['output']>
  powershares: Array<PowershareOnUser>
  requiredActions: Array<UserRequiredAction>
  username?: Maybe<Scalars['String']['output']>
}

export type UserRequiredActionsArgs = {
  include?: RequiredActionFilter
}

export type UserRequiredAction = {
  __typename?: 'UserRequiredAction'
  acceptedAt?: Maybe<Scalars['DateTimeISO']['output']>
  content: Scalars['String']['output']
  createdAt: Scalars['DateTimeISO']['output']
  id: Scalars['ID']['output']
  title: Scalars['String']['output']
  type: RequiredActionType
}

/** Occurs if the user doesn't exists */
export type UserWithEmailNotFound = {
  __typename?: 'UserWithEmailNotFound'
  description: Scalars['String']['output']
  email: Scalars['String']['output']
}

export type UsersConnection = IOffsetPagination & {
  __typename?: 'UsersConnection'
  edges: Array<User>
  totalCount: Scalars['Int']['output']
}

export type ValidationError = {
  __typename?: 'ValidationError'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
}

export type WhereFacilityId = {
  facilityId: Scalars['Int']['input']
}

export type WherePowershareId = {
  powershareId: Scalars['Int']['input']
}

export type GetBatteryListQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
  gte: Scalars['DateTimeISO']['input']
  lte: Scalars['DateTimeISO']['input']
}>

export type GetBatteryListQuery = {
  __typename?: 'Query'
  facilities?: Array<{
    __typename?: 'Facility'
    id: string
    name: string
    measurements: {
      __typename?: 'Measurements'
      energyStorage: Array<{ __typename?: 'EnergyStorage'; soc: number }>
    }
    systemControlTopologyConfiguration?: {
      __typename?: 'SystemControlTopologyConfiguration'
      data: {
        __typename?: 'SystemControlTopology'
        battery?: Array<{
          __typename?: 'Battery'
          id?: number | null
          terminals?: Array<{
            __typename?: 'BatteryTerminal'
            attributes?: {
              __typename?: 'BatteryTerminalAttributes'
              pid: string
              manufacturer?: number | null
            } | null
          } | null> | null
        } | null> | null
      }
    } | null
  }> | null
}

export type GetFacilitySystemTopologyQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetFacilitySystemTopologyQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    singleBoardComputer?: {
      __typename?: 'SingleBoardComputer'
      productId: string
      bbb?: { __typename?: 'BeagleboardExtras'; hostname?: string | null; macAddress?: string | null } | null
    } | null
    systemControlTopologyConfiguration?: {
      __typename?: 'SystemControlTopologyConfiguration'
      transTs: any
      data: {
        __typename?: 'SystemControlTopology'
        battery?: Array<{
          __typename?: 'Battery'
          id?: number | null
          metadata?: { __typename?: 'Metadata'; name: string; description: string } | null
          param?: {
            __typename?: 'BatteryParam'
            batteryResponseTime?: number | null
            maxCRate?: { __typename?: 'BatteryMaxCRate'; charge: number; discharge: number } | null
            socLimits?: { __typename?: 'BatterySocLimits'; high: number; low: number } | null
          } | null
          terminals?: Array<{
            __typename?: 'BatteryTerminal'
            id?: string | null
            attributes?: {
              __typename?: 'BatteryTerminalAttributes'
              manufacturer?: number | null
              pid: string
              ratedCRate?: number | null
              ratedCapacity: number
              ratedPower: number
            } | null
            config?: {
              __typename?: 'BatteryConfig'
              installation?: { __typename?: 'BatteryInstallation'; isSingle?: boolean | null } | null
              runtime?: {
                __typename?: 'BatteryRuntime'
                droopDeadband: { __typename?: 'DroopDeadband'; left: number; right: number }
                droopLimits: { __typename?: 'DroopLimits'; neg: number; pos: number }
                powerLimits: { __typename?: 'PowerLimits'; neg: number; pos: number }
                runtimeMode: {
                  __typename?: 'RuntimeMode'
                  mode?: number | null
                  ref1?: number | null
                  ref2?: number | null
                }
                socLimits: { __typename?: 'SocLimits'; high: number; low: number }
              } | null
            } | null
            metadata?: { __typename?: 'Metadata'; description: string; name: string } | null
            param?: {
              __typename?: 'BatteryTerminalParam'
              enable?: boolean | null
              override?: boolean | null
            } | null
          } | null> | null
        } | null> | null
        evse?: Array<{
          __typename?: 'Evse'
          id?: number | null
          param?: { __typename?: 'EvseParam'; chargerType: ChargerType } | null
          metadata?: { __typename?: 'Metadata'; description: string; name: string } | null
          terminals?: Array<{
            __typename?: 'EvseTerminal'
            id?: string | null
            attributes?: {
              __typename?: 'EvseTerminalAttributes'
              pid: string
              ratedPower?: number | null
            } | null
            config?: {
              __typename?: 'EvseTerminalConfig'
              limitProfile?: {
                __typename?: 'EvseTerminalConfigLimitProfile'
                level?: number | null
                purpose?: EvseTerminalConfigLimitProfilePurpose | null
              } | null
              runtime?: {
                __typename?: 'EvseTerminalConfigRuntime'
                triggerMeterValues?: EvseTerminalConfigRuntimeOnOffAuto | null
              } | null
            } | null
            metadata?: { __typename?: 'Metadata'; description: string; name: string } | null
          } | null> | null
        } | null> | null
        grid?: Array<{
          __typename?: 'Grid'
          id: number
          metadata: { __typename?: 'Metadata'; description: string; name: string }
          param: {
            __typename?: 'GridParam'
            fuseCurrentRating: number
            loadResponseTime?: number | null
            extCurrentSensor: { __typename?: 'GridExtCurrentSensor'; pn?: string | null; ratio: number }
          }
          terminals: Array<{
            __typename?: 'GridTerminal'
            id: string
            attributes: {
              __typename?: 'GridTerminalAttributes'
              pid: string
              ratedCurrent: number
              ratedPower: number
            }
            config: {
              __typename?: 'GridConfig'
              installation?: {
                __typename?: 'GridInstallation'
                gridFiltCoeff?: number | null
                isSingle: boolean
                ctConfig: {
                  __typename?: 'CtConfig'
                  configDone?: boolean | null
                  ctRatio: number
                  lastConfigDate?: string | null
                }
                gridLimits: { __typename?: 'GridLimits'; imag?: number | null; real?: number | null }
              } | null
              runtime: {
                __typename?: 'GridRuntime'
                aceMode: { __typename?: 'AceMode'; mode: number; ref1: number; ref2: number }
                apwrMode: {
                  __typename?: 'ApwrMode'
                  mode?: number | null
                  ref1?: number | null
                  ref2?: number | null
                }
                currentLimits: { __typename?: 'CurrentLimits'; imag: number; real: number }
                droopDeadband: { __typename?: 'DroopDeadband'; left: number; right: number }
                droopLimits: { __typename?: 'DroopLimits'; neg: number; pos: number }
                powerLimits: { __typename?: 'PowerLimits'; neg: number; pos: number }
                pffLimits: { __typename?: 'PffLimits'; neg: number; pos: number }
              }
            }
            metadata: { __typename?: 'Metadata'; description: string; name: string }
            param: { __typename?: 'GridTerminalParam'; enable: boolean; override: boolean }
          }>
        } | null> | null
        pv?: Array<{
          __typename?: 'Pv'
          id?: number | null
          metadata?: { __typename?: 'Metadata'; description: string; name: string } | null
          param?: { __typename?: 'PvParam'; installedCapacity: number } | null
          terminals?: Array<{
            __typename?: 'PvTerminal'
            id?: string | null
            attributes?: {
              __typename?: 'PvTerminalAttributes'
              pid: string
              ratedPower?: number | null
            } | null
            config?: {
              __typename?: 'PvConfig'
              runtime?: {
                __typename?: 'PvRuntime'
                droopLimits?: { __typename?: 'DroopLimits'; neg: number; pos: number } | null
                runtimeMode?: {
                  __typename?: 'RuntimeMode'
                  mode?: number | null
                  ref1?: number | null
                  ref2?: number | null
                } | null
              } | null
            } | null
            metadata?: { __typename?: 'Metadata'; description: string; name: string } | null
            param?: { __typename?: 'PvTerminalParam'; override: boolean; enable: boolean } | null
          } | null> | null
        } | null> | null
      }
    } | null
  } | null
}

export type GetSbcInfoForTopologyQueryVariables = Exact<{
  productId: Scalars['String']['input']
}>

export type GetSbcInfoForTopologyQuery = {
  __typename?: 'Query'
  singleBoardComputer: {
    __typename?: 'SingleBoardComputer'
    version?: string | null
    bbb?: { __typename?: 'BeagleboardExtras'; hostname?: string | null } | null
  }
}

export type GetCloudApiSettingsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetCloudApiSettingsQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    name: string
    settings: {
      __typename?: 'FacilitySettings'
      cloudApi?: {
        __typename?: 'CloudApiSettings'
        allowedRequests?: number | null
        apiKey: string
        bindIp?: Array<string | null> | null
      } | null
    }
  } | null
}

export type EnableCloudApiMutationVariables = Exact<{
  facilityId: Scalars['Int']['input']
  data?: InputMaybe<CloudApiCreateInput>
}>

export type EnableCloudApiMutation = {
  __typename?: 'Mutation'
  createCloudApiSettings?: {
    __typename?: 'CloudApiMutationResponse'
    code: number
    message?: string | null
    success: boolean
    cloudApi?: {
      __typename?: 'CloudApiSettings'
      allowedRequests?: number | null
      apiKey: string
      bindIp?: Array<string | null> | null
    } | null
  } | null
}

export type DisableCloudApiMutationVariables = Exact<{
  facilityId: Scalars['Int']['input']
}>

export type DisableCloudApiMutation = {
  __typename?: 'Mutation'
  removeCloudApiSettings?: {
    __typename?: 'CloudApiMutationResponse'
    code: number
    message?: string | null
    success: boolean
    cloudApi?: {
      __typename?: 'CloudApiSettings'
      allowedRequests?: number | null
      apiKey: string
      bindIp?: Array<string | null> | null
    } | null
  } | null
}

export type UpdateCloudApiSettingsMutationVariables = Exact<{
  facilityId: Scalars['Int']['input']
  data: CloudApiUpdateInput
}>

export type UpdateCloudApiSettingsMutation = {
  __typename?: 'Mutation'
  updateCloudApiSettings?: {
    __typename?: 'CloudApiMutationResponse'
    code: number
    message?: string | null
    success: boolean
    cloudApi?: {
      __typename?: 'CloudApiSettings'
      allowedRequests?: number | null
      apiKey: string
      bindIp?: Array<string | null> | null
    } | null
  } | null
}

export type GetExampleEmsConfigurationsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetExampleEmsConfigurationsQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    configurations: {
      __typename?: 'Configurations'
      emsConfiguration: {
        __typename?: 'EmsConfigType'
        current: Array<{
          __typename?: 'EmsConfigBase'
          config:
            | { __typename?: 'SystemApiEmsConfigurationV1' }
            | {
                __typename: 'SystemApiEmsConfigurationV2'
                correlationId: string
                systemMode: SystemModeV2
                timestamp: any
                systemSettingsBattery: {
                  __typename?: 'SystemLevelSettingsBatteryV2'
                  powerRefCharge: number
                  socRefHigh: number
                  powerRefDischarge: number
                  socRefLow: number
                }
                systemSettingsGrid: {
                  __typename?: 'SystemLevelSettingsGridV2'
                  aceMode: boolean
                  aceThreshold: number
                  limitExport: boolean
                  limitImport: boolean
                  powerThresholdHigh: number
                  powerThresholdLow: number
                }
                systemSettingsPv: { __typename?: 'SystemLevelSettingsPvV2'; pvMode: boolean }
              }
        }>
      }
    }
  } | null
}

export type PollEmsConfigurationQueryVariables = Exact<{
  id: Scalars['Int']['input']
  generation: Scalars['String']['input']
}>

export type PollEmsConfigurationQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    configurations: {
      __typename?: 'Configurations'
      emsConfiguration: {
        __typename?: 'EmsConfigType'
        poll: Array<{
          __typename?: 'EmsConfigBase'
          state: ConfigurationCommunicationState
          name?: string | null
          rejection?: {
            __typename?: 'UnifiedEvents'
            timestamp?: any | null
            namespace: EventNamespace
            eventsource: EventSource
            correlationId: string
            event: FerroampEvent
            appVersion: string
          } | null
          status?: {
            __typename?: 'UnifiedEvents'
            correlationId: string
            appVersion: string
            event: FerroampEvent
            eventsource: EventSource
            namespace: EventNamespace
            timestamp?: any | null
          } | null
        }>
      }
    }
  } | null
}

export type UpsertEmsConfigurationMutationVariables = Exact<{
  id: Scalars['Int']['input']
  data: UpsertEmsConfigurationDataArgs
}>

export type UpsertEmsConfigurationMutation = {
  __typename?: 'Mutation'
  upsertEmsConfiguration: {
    __typename?: 'UpsertEmsConfiguration'
    generation: string
    success: boolean
    validationFailure?: string | null
  }
}

export type GetFacilitiesListQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput> | FacilityOrderByWithRelationInput>
}>

export type GetFacilitiesListQuery = {
  __typename?: 'Query'
  facilitiesConnection: {
    __typename?: 'FacilitiesConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'Facility'
      createdAt: any
      updatedAt: any
      id: string
      name: string
      info?: { __typename?: 'FacilityInfo'; version?: string | null } | null
      measurements: { __typename?: 'Measurements'; dataReceivedAt?: any | null }
      singleBoardComputer?: {
        __typename?: 'SingleBoardComputer'
        bbb?: { __typename?: 'BeagleboardExtras'; hostname?: string | null } | null
      } | null
      powershare?: { __typename?: 'Powershare'; id: string; name: string } | null
    }>
  }
}

export type GetFacilityInfoQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetFacilityInfoQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    id: string
    name: string
    installedOn?: any | null
    hostname?: string | null
    features: Array<FacilityFeatures>
    discontinuedAt?: any | null
    createdAt: any
    timezone?: string | null
    updatedAt: any
    info?: { __typename?: 'FacilityInfo'; version?: string | null } | null
    measurements: { __typename?: 'Measurements'; dataReceivedAt?: any | null }
    uiComponents: {
      __typename?: 'UIComponents'
      capacities: Array<
        | { __typename: 'UIEnergyStorageRatedCapacity'; capacity?: number | null; unit: string }
        | { __typename: 'UIEnergyStorageRatedPowerCapacity'; capacity?: number | null; unit: string }
        | { __typename: 'UIMainFuseCapacity'; capacity?: number | null; unit: string }
        | { __typename: 'UIMaxInverterCurrentCapacity'; capacity?: number | null; unit: string }
        | { __typename: 'UIMaxInverterPowerCapacity'; capacity?: number | null; unit: string }
      >
      flowChart: { __typename?: 'UIFlowChart'; enabledComponents: Array<UiFlowChartComponent> }
    }
    location?: {
      __typename?: 'FacilityLocation'
      address?: string | null
      city?: string | null
      country?: string | null
      electricalGridId?: string | null
      latitude?: number | null
      longitude?: number | null
      propertyDesignation?: string | null
    } | null
    misc?: {
      __typename?: 'FacilityMisc'
      costElectricityKWh?: number | null
      currencyCode?: string | null
      revenueSoldToGridkWh?: number | null
    } | null
    capacity?: { __typename?: 'FacilityCapacity'; installedPv?: number | null } | null
    singleBoardComputer?: {
      __typename?: 'SingleBoardComputer'
      productId: string
      version?: string | null
      lifeCycleStage: SingleBoardComputerLifeCycleStages
      bbb?: { __typename?: 'BeagleboardExtras'; hostname?: string | null } | null
    } | null
    lifeCycle: {
      __typename?: 'FacilityLifeCycles'
      current: { __typename?: 'FacilityLifeCycle'; stage: FacilityLifeCycleStages; updatedAt: any }
    }
    powershare?: { __typename?: 'Powershare'; id: string; name: string } | null
  } | null
}

export type GetFacilityLifeCycleHistoryQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetFacilityLifeCycleHistoryQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    lifeCycle: {
      __typename?: 'FacilityLifeCycles'
      history: Array<{ __typename?: 'FacilityLifeCycle'; stage: FacilityLifeCycleStages; updatedAt: any }>
      current: { __typename?: 'FacilityLifeCycle'; stage: FacilityLifeCycleStages; updatedAt: any }
      allowedTransitions: Array<{
        __typename?: 'AllowedFacilityLifeCycleTransitions'
        stage: FacilityLifeCycleStages
      }>
    }
  } | null
}

export type GetFacilityAvailableLifeCycleTransitionsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetFacilityAvailableLifeCycleTransitionsQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    lifeCycle: {
      __typename?: 'FacilityLifeCycles'
      allowedTransitions: Array<{
        __typename?: 'AllowedFacilityLifeCycleTransitions'
        stage: FacilityLifeCycleStages
      }>
    }
  } | null
}

export type FindFacilityWhereQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FacilityWhereInput>
  orderBy?: InputMaybe<Array<FacilityOrderByWithRelationInput> | FacilityOrderByWithRelationInput>
}>

export type FindFacilityWhereQuery = {
  __typename?: 'Query'
  facilities?: Array<{
    __typename?: 'Facility'
    createdAt: any
    id: string
    name: string
    hostname?: string | null
  }> | null
}

export type GetFacilityEnabledComponentsQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetFacilityEnabledComponentsQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    uiComponents: {
      __typename?: 'UIComponents'
      flowChart: { __typename?: 'UIFlowChart'; enabledComponents: Array<UiFlowChartComponent> }
    }
  } | null
}

export type GetFacilityUsersQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetFacilityUsersQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    id: string
    name: string
    users: Array<{
      __typename?: 'FacilityUser'
      name?: string | null
      email: any
      id: string
      relation: FacilityUserRoles
    }>
  } | null
}

export type EditFacilitySbcMappingMutationVariables = Exact<{
  set: EditFacilityMappingDataInput
  where: EditFacilityMappingWhereInput
}>

export type EditFacilitySbcMappingMutation = {
  __typename?: 'Mutation'
  editFacilitySingleBoardComputerMapping: {
    __typename?: 'EditFacilityMappingMutationResponse'
    code: number
    success: boolean
    message: string
    errors?:
      | { __typename: 'SBCTransitionError'; allowedTransitions: Array<SingleBoardComputerLifeCycleStages> }
      | { __typename: 'SbcBadLifeCycleStageError'; allowedStages: Array<SingleBoardComputerLifeCycleStages> }
      | null
  }
}

export type GetStatisticsQueryVariables = Exact<{
  where?: InputMaybe<FacilityWhereInput>
  period: EnergyPeriodInput
}>

export type GetStatisticsQuery = {
  __typename?: 'Query'
  facility?: {
    __typename: 'Facility'
    id: string
    createdAt: any
    measurements: {
      __typename: 'Measurements'
      id: number
      energy: Array<
        | {
            __typename: 'SystemEnergyDate'
            timeperiodStartDay: any
            pvProduced?: number | null
            consumption: number
            gridImport: number
            gridExport: number
          }
        | {
            __typename: 'SystemEnergyDateTime'
            timeperiodStart: any
            pvProduced?: number | null
            consumption: number
            gridImport: number
            gridExport: number
          }
      >
    }
  } | null
}

export type GetFacilitiesMapQueryQueryVariables = Exact<{
  latitude?: InputMaybe<FloatNullableFilter>
  longitude?: InputMaybe<FloatNullableFilter>
}>

export type GetFacilitiesMapQueryQuery = {
  __typename?: 'Query'
  facilities?: Array<{
    __typename?: 'Facility'
    id: string
    name: string
    location?: { __typename?: 'FacilityLocation'; latitude?: number | null; longitude?: number | null } | null
  }> | null
}

export type GetFacilityHistoryQueryVariables = Exact<{
  facilityId: Scalars['Int']['input']
}>

export type GetFacilityHistoryQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    users: Array<{ __typename?: 'FacilityUser'; email: any; id: string }>
    history: Array<{
      __typename?: 'FacilityHistory'
      correlationId: string
      createdAt: any
      userId?: string | null
      changes: Array<
        | { __typename: 'FacilityHistoryChangeDelete'; before?: string | null; path: string }
        | { __typename: 'FacilityHistoryChangeInsert'; after?: string | null; path: string }
        | {
            __typename: 'FacilityHistoryChangeUpdate'
            after?: string | null
            before?: string | null
            path: string
          }
      >
    }>
  } | null
}

export type GetFacilityPower1MinuteQueryVariables = Exact<{
  id: Scalars['Int']['input']
  from: Scalars['DateTimeISO']['input']
  to: Scalars['DateTimeISO']['input']
}>

export type GetFacilityPower1MinuteQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    createdAt: any
    measurements: {
      __typename?: 'Measurements'
      power: Array<{
        __typename?: 'PowerData'
        loadPower: number
        inverterPower: number
        gridPower: number
        batteryPower?: number | null
        timestamp: any
        pvPower?: number | null
      }>
    }
  } | null
}

export type GetFacilityPower1SecondQueryVariables = Exact<{
  id: Scalars['Int']['input']
  year: BaseRawDataWherePartitionInput
  month: BaseRawDataWherePartitionInput
  day: BaseRawDataWherePartitionInput
  hour: BaseRawDataWherePartitionInput
}>

export type GetFacilityPower1SecondQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    createdAt: any
    measurements: {
      __typename?: 'Measurements'
      rawPower: Array<{
        __typename?: 'PowerData'
        gridPower: number
        loadPower: number
        inverterPower: number
        batteryPower?: number | null
        pvPower?: number | null
        timestamp: any
      }>
    }
  } | null
}

export type GetFacilityRawGridDataQueryVariables = Exact<{
  id: Scalars['Int']['input']
  year: BaseRawDataWherePartitionInput
  month: BaseRawDataWherePartitionInput
  day: BaseRawDataWherePartitionInput
  hour: BaseRawDataWherePartitionInput
}>

export type GetFacilityRawGridDataQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    createdAt: any
    measurements: {
      __typename?: 'Measurements'
      rawUidata: Array<{
        __typename?: 'GridData'
        iExtD1: number
        iExtD2: number
        iExtD3: number
        iExtQ1: number
        iExtQ2: number
        iExtQ3: number
        iExtRms1: number
        iExtRms2: number
        iExtRms3: number
        iInvD1: number
        iInvD2: number
        iInvD3: number
        iInvQ1: number
        iInvQ2: number
        iInvQ3: number
        iInvRms1: number
        iInvRms2: number
        iInvRms3: number
        timestamp: any
        u1: number
        u2: number
        u3: number
        uDCneg: number
        uDCpos: number
      }>
    }
  } | null
}

export type GetBessLogQueryVariables = Exact<{
  id: Scalars['Int']['input']
  year: BaseRawDataWherePartitionInput
  month: BaseRawDataWherePartitionInput
  day: BaseRawDataWherePartitionInput
  hour: BaseRawDataWherePartitionInput
}>

export type GetBessLogQuery = {
  __typename?: 'Query'
  facility?: {
    __typename?: 'Facility'
    logs: {
      __typename?: 'Logs'
      bessLog: Array<{
        __typename?: 'BessLog'
        warningFlag?: number | null
        timestamp: any
        stateOfHealth?: number | null
        stateOfCharge?: number | null
        serial: string
        ratedPower?: number | null
        ratedCapacity?: number | null
        fault?: number | null
        errorFlag?: number | null
        cellVoltageMin?: number | null
        cellVoltageMax?: number | null
        cellTemperatureMin?: number | null
        cellTemperatureMax?: number | null
        bessState?: number | null
        bmsState?: number | null
        batteryManufacturer: string
        alarmFlag?: number | null
      }>
    }
  } | null
}

export type UpdateFacilityLifeCycleMutationVariables = Exact<{
  id: Scalars['Int']['input']
  stage: FacilityLifeCycleStages
}>

export type UpdateFacilityLifeCycleMutation = {
  __typename?: 'Mutation'
  updateOneFacilityLifeCycle: {
    __typename?: 'UpdateFacilityLifeCycleMutationResponse'
    success: boolean
    message: string
    code: number
    lifeCycle?: {
      __typename?: 'FacilityLifeCycles'
      current: { __typename?: 'FacilityLifeCycle'; stage: FacilityLifeCycleStages; updatedAt: any }
    } | null
    errors?: Array<
      | { __typename: 'FacilityLifeCycleTransitionError'; allowedTransitions: Array<FacilityLifeCycleStages> }
      | { __typename: 'FacilityNotFound'; facilityId: string }
      | { __typename: 'ValidationError'; path: Array<string>; message: string; code: string }
    > | null
  }
}

export type GetPowershareListQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput> | PowershareOrderByWithRelationInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}>

export type GetPowershareListQuery = {
  __typename?: 'Query'
  powersharesConnection: {
    __typename?: 'PowersharesConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'Powershare'
      id: string
      name: string
      updatedAt: any
      installedOn?: any | null
      discontinuedAt?: any | null
      createdAt: any
    }>
  }
}

export type GetOnePowershareQueryVariables = Exact<{
  cursor?: InputMaybe<PowershareWhereUniqueInput>
  distinct?: InputMaybe<Array<PowershareScalarFieldEnum> | PowershareScalarFieldEnum>
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput> | PowershareOrderByWithRelationInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}>

export type GetOnePowershareQuery = {
  __typename?: 'Query'
  powershare?: {
    __typename?: 'Powershare'
    createdAt: any
    discontinuedAt?: any | null
    id: string
    installedOn?: any | null
    name: string
    updatedAt: any
  } | null
}

export type GetPowershareUserRelationsQueryVariables = Exact<{
  cursor?: InputMaybe<PowershareWhereUniqueInput>
  distinct?: InputMaybe<Array<PowershareScalarFieldEnum> | PowershareScalarFieldEnum>
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput> | PowershareOrderByWithRelationInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}>

export type GetPowershareUserRelationsQuery = {
  __typename?: 'Query'
  powershare?: {
    __typename?: 'Powershare'
    users?: Array<{
      __typename?: 'PowershareUser'
      relation: PowershareUserRoles
      id: string
      legacyId?: number | null
      name?: string | null
      email: any
    }> | null
  } | null
}

export type GetPowershareFacilityRelationsQueryVariables = Exact<{
  cursor?: InputMaybe<PowershareWhereUniqueInput>
  distinct?: InputMaybe<Array<PowershareScalarFieldEnum> | PowershareScalarFieldEnum>
  orderBy?: InputMaybe<Array<PowershareOrderByWithRelationInput> | PowershareOrderByWithRelationInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PowershareWhereInput>
}>

export type GetPowershareFacilityRelationsQuery = {
  __typename?: 'Query'
  powershare?: {
    __typename?: 'Powershare'
    facilities?: Array<{ __typename?: 'Facility'; id: string; updatedAt: any; name: string }> | null
  } | null
}

export type RemoveOneFacilityFromPowershareMutationVariables = Exact<{
  powershareId: Scalars['Int']['input']
  facilityId: Scalars['Int']['input']
}>

export type RemoveOneFacilityFromPowershareMutation = {
  __typename?: 'Mutation'
  removeOneFacilityFromPowershare: { __typename?: 'Powershare'; updatedAt: any; id: string; name: string }
}

export type DeleteOnePowershareMutationVariables = Exact<{
  where: WherePowershareId
}>

export type DeleteOnePowershareMutation = {
  __typename?: 'Mutation'
  removeOnePowershare: {
    __typename?: 'Powershare'
    createdAt: any
    discontinuedAt?: any | null
    id: string
    installedOn?: any | null
    name: string
  }
}

export type UpdateOnePowershareMutationVariables = Exact<{
  data: UpdateOnePowershareInputs
  where: WherePowershareId
}>

export type UpdateOnePowershareMutation = {
  __typename?: 'Mutation'
  updateOnePowershare: {
    __typename?: 'Powershare'
    createdAt: any
    discontinuedAt?: any | null
    id: string
    installedOn?: any | null
    name: string
    updatedAt: any
  }
}

export type AddManyUserToPowershareMutationVariables = Exact<{
  data: AddManyPowershareUsersInput
  where: WherePowershareId
}>

export type AddManyUserToPowershareMutation = {
  __typename?: 'Mutation'
  addManyUserToPowershare: { __typename?: 'Powershare'; name: string; id: string }
}

export type AddManyFacilitiesToPowershareMutationVariables = Exact<{
  data: AddManyFacilitiesToPowershareInput
  where: WherePowershareId
}>

export type AddManyFacilitiesToPowershareMutation = {
  __typename?: 'Mutation'
  addManyFacilitiesToPowershare: { __typename?: 'Powershare'; name: string; id: string }
}

export type RemoveOneUserFromPowershareMutationVariables = Exact<{
  userId: Scalars['String']['input']
  powershareId: Scalars['Int']['input']
}>

export type RemoveOneUserFromPowershareMutation = {
  __typename?: 'Mutation'
  removeOneUserFromPowershare: { __typename?: 'Powershare'; updatedAt: any; id: string; name: string }
}

export type CreateOnePowershareMutationVariables = Exact<{
  data: CreateOnePowershareInputs
}>

export type CreateOnePowershareMutation = {
  __typename?: 'Mutation'
  createOnePowershare: { __typename?: 'Powershare'; id: string; name: string }
}

export type GetSearchableFacilityQueryVariables = Exact<{
  searchValue: Scalars['String']['input']
  searchNumber: Scalars['Int']['input']
}>

export type GetSearchableFacilityQuery = {
  __typename?: 'Query'
  facilities?: Array<{ __typename?: 'Facility'; id: string; name: string }> | null
}

export type GetSearchablePowershareQueryVariables = Exact<{
  searchValue: Scalars['String']['input']
  searchNumber: Scalars['Int']['input']
}>

export type GetSearchablePowershareQuery = {
  __typename?: 'Query'
  powershares: Array<{ __typename?: 'Powershare'; name: string; id: string }>
}

export type GetSearchableSingleBoardComputersQueryVariables = Exact<{
  searchValue: Scalars['String']['input']
}>

export type GetSearchableSingleBoardComputersQuery = {
  __typename?: 'Query'
  singleBoardComputers: Array<{
    __typename?: 'SingleBoardComputer'
    productId: string
    bbb?: { __typename?: 'BeagleboardExtras'; hostname?: string | null; macAddress?: string | null } | null
  }>
}

export type GetSearchableUsersQueryVariables = Exact<{
  searchValue: Scalars['String']['input']
}>

export type GetSearchableUsersQuery = {
  __typename?: 'Query'
  users: Array<{ __typename?: 'User'; name?: string | null; email: any; id: string }>
  user?: { __typename?: 'User'; id: string; email: any; name?: string | null } | null
}

export type GetOneSingleBoardComputerQueryVariables = Exact<{
  productId: Scalars['String']['input']
}>

export type GetOneSingleBoardComputerQuery = {
  __typename?: 'Query'
  singleBoardComputer: {
    __typename?: 'SingleBoardComputer'
    createdAt: any
    version?: string | null
    productId: string
    lifeCycleStage: SingleBoardComputerLifeCycleStages
    lastSeenAt?: any | null
    installedAt?: any | null
    generation: SbcGeneration
    bbb?: {
      __typename?: 'BeagleboardExtras'
      hostname?: string | null
      macAddress?: string | null
      portalRegistrationKey: string
    } | null
    facility?: { __typename?: 'Facility'; name: string; id: string } | null
  }
}

export type GetSingleBoardComputerListQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<SingleBoardComputerOrderByWithRelationInput> | SingleBoardComputerOrderByWithRelationInput
  >
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SingleBoardComputerWhereInput>
}>

export type GetSingleBoardComputerListQuery = {
  __typename?: 'Query'
  singleBoardComputersConnection: {
    __typename?: 'SingleBoardComputerConnections'
    totalCount: number
    edges: Array<{
      __typename?: 'SingleBoardComputer'
      productId: string
      version?: string | null
      lifeCycleStage: SingleBoardComputerLifeCycleStages
      createdAt: any
      generation: SbcGeneration
      bbb?: { __typename?: 'BeagleboardExtras'; macAddress?: string | null; hostname?: string | null } | null
      facility?: { __typename?: 'Facility'; id: string } | null
    }>
  }
}

export type GetSingleBoardComputerVersionChangeLogQueryVariables = Exact<{
  productId: Scalars['String']['input']
}>

export type GetSingleBoardComputerVersionChangeLogQuery = {
  __typename?: 'Query'
  singleBoardComputer: {
    __typename?: 'SingleBoardComputer'
    versionChangeLog: Array<{
      __typename?: 'SingleBoardComputerVersionChangeLog'
      fromVersion?: string | null
      toVersion: string
      updatedAt: any
    }>
  }
}

export type GetAllowedSbcLifeCycleTransitionsQueryVariables = Exact<{
  productId: Scalars['String']['input']
}>

export type GetAllowedSbcLifeCycleTransitionsQuery = {
  __typename?: 'Query'
  singleBoardComputer: {
    __typename?: 'SingleBoardComputer'
    lifeCycleStage: SingleBoardComputerLifeCycleStages
    allowedLifeCycleTransitions: Array<{
      __typename?: 'AllowedSbcLifeCycleTransitions'
      stage: SingleBoardComputerLifeCycleStages
    }>
  }
}

export type SetSingleBoardComputerLifeCycleStageMutationVariables = Exact<{
  stage: SingleBoardComputerLifeCycleStages
  productId: Scalars['String']['input']
}>

export type SetSingleBoardComputerLifeCycleStageMutation = {
  __typename?: 'Mutation'
  setSingleBoardComputerLifeCycleStage: {
    __typename?: 'SetSingleBoardComputerLifeCycleStageResponse'
    code: number
    message: string
    success: boolean
    errors?: Array<{
      __typename?: 'SBCTransitionError'
      allowedTransitions: Array<SingleBoardComputerLifeCycleStages>
      code: 'SBCTransitionError'
    }> | null
    singleBoardComputer?: {
      __typename?: 'SingleBoardComputer'
      lifeCycleStage: SingleBoardComputerLifeCycleStages
    } | null
  }
}

export type FindSingleBoardComputerForFacilityRemappingQueryVariables = Exact<{
  where?: InputMaybe<SingleBoardComputerWhereInput>
}>

export type FindSingleBoardComputerForFacilityRemappingQuery = {
  __typename?: 'Query'
  singleBoardComputers: Array<{
    __typename?: 'SingleBoardComputer'
    productId: string
    version?: string | null
    lifeCycleStage: SingleBoardComputerLifeCycleStages
  }>
}

export type GetUserSearchQueryVariables = Exact<{
  where?: InputMaybe<FindManyUsersWhereArgs>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['String']['input']
  idSearch: Scalars['Boolean']['input']
}>

export type GetUserSearchQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'User'
    id: string
    name?: string | null
    email: any
    phoneNumber?: any | null
    createdAt: any
  }>
  usersConnection: { __typename?: 'UsersConnection'; totalCount: number }
  user?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email: any
    phoneNumber?: any | null
    createdAt: any
  } | null
}

export type GetUserListQueryVariables = Exact<{
  where?: InputMaybe<FindManyUsersWhereArgs>
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type GetUserListQuery = {
  __typename?: 'Query'
  usersConnection: {
    __typename?: 'UsersConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'User'
      email: any
      name?: string | null
      phoneNumber?: any | null
      username?: string | null
      id: string
      createdAt: any
      legacyId?: number | null
    }>
  }
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetUserQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    colors?: string | null
    createdAt: any
    email: any
    emailVerified: boolean
    id: string
    lastName?: string | null
    legacyId?: number | null
    locale?: any | null
    name?: string | null
    phoneNumber?: any | null
  } | null
}

export type GetUserPowersharesQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type GetUserPowersharesQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    powershares: Array<{
      __typename?: 'PowershareOnUser'
      id: string
      name: string
      relation: PowershareUserRoles
    }>
  } | null
}

export type GetUserFacilitiesQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type GetUserFacilitiesQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    facilities: Array<{
      __typename?: 'FacilityOnUser'
      name: string
      id: string
      relation: FacilityUserRoles
      updatedAt: any
      info?: { __typename?: 'FacilityInfo'; version?: string | null } | null
    }>
  } | null
}

export type AddOneUserToPowershareMutationVariables = Exact<{
  userId: Scalars['String']['input']
  powershareId: Scalars['Int']['input']
}>

export type AddOneUserToPowershareMutation = {
  __typename?: 'Mutation'
  addOneUserToPowershare: { __typename?: 'Powershare'; id: string; name: string }
}

export type AddExistingUserToFacilityMutationVariables = Exact<{
  email: Scalars['String']['input']
  facilityId: Scalars['ID']['input']
  relation: FacilityUserRoles
}>

export type AddExistingUserToFacilityMutation = {
  __typename?: 'Mutation'
  addExistingUserToFacility: {
    __typename?: 'AddExistingUserToFacilityMutationResponse'
    code: number
    message: string
    success: boolean
    errors?: Array<
      | { __typename: 'FacilityNotFound'; facilityId: string }
      | { __typename: 'UserWithEmailNotFound'; description: string; email: string }
      | { __typename: 'ValidationError'; code: string; message: string; path: Array<string> }
    > | null
    user?: { __typename?: 'FacilityUser'; id: string; name?: string | null } | null
  }
}

export type RemoveUserFromFacilityMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  facilityId: Scalars['ID']['input']
}>

export type RemoveUserFromFacilityMutation = {
  __typename?: 'Mutation'
  removeUserFacilityRelation: {
    __typename?: 'RemoveUserFacilityRelationMutationResponse'
    code: number
    success: boolean
    message: string
    errors?: Array<
      | { __typename: 'FacilityNotFound'; facilityId: string }
      | { __typename: 'UserWithEmailNotFound'; description: string; email: string }
      | { __typename: 'ValidationError'; code: string; message: string; path: Array<string> }
    > | null
    user?: { __typename?: 'FacilityUser'; email: any; id: string } | null
  }
}

export const GetBatteryListDocument = gql`
  query getBatteryList(
    $take: Int
    $skip: Int
    $where: FacilityWhereInput
    $gte: DateTimeISO!
    $lte: DateTimeISO!
  ) {
    facilities(take: $take, skip: $skip, where: $where) {
      id
      name
      measurements {
        energyStorage(timeRange: { gte: $gte, lte: $lte }, last: 1) {
          soc
        }
      }
      systemControlTopologyConfiguration {
        data {
          battery {
            id
            terminals {
              attributes {
                pid
                manufacturer
              }
            }
          }
        }
      }
    }
  }
`

export function useGetBatteryListQuery(
  options: Omit<Urql.UseQueryArgs<GetBatteryListQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetBatteryListQuery, GetBatteryListQueryVariables>({
    query: GetBatteryListDocument,
    ...options
  })
}
export const GetFacilitySystemTopologyDocument = gql`
  query getFacilitySystemTopology($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      singleBoardComputer {
        productId
        bbb {
          hostname
          macAddress
        }
      }
      systemControlTopologyConfiguration {
        transTs
        data {
          battery {
            id
            metadata {
              name
              description
            }
            param {
              batteryResponseTime
              maxCRate {
                charge
                discharge
              }
              socLimits {
                high
                low
              }
            }
            terminals {
              attributes {
                manufacturer
                pid
                ratedCRate
                ratedCapacity
                ratedPower
              }
              config {
                installation {
                  isSingle
                }
                runtime {
                  droopDeadband {
                    left
                    right
                  }
                  droopLimits {
                    neg
                    pos
                  }
                  powerLimits {
                    neg
                    pos
                  }
                  runtimeMode {
                    mode
                    ref1
                    ref2
                  }
                  socLimits {
                    high
                    low
                  }
                }
              }
              id
              metadata {
                description
                name
              }
              param {
                enable
                override
              }
            }
          }
          evse {
            id
            param {
              chargerType
            }
            metadata {
              description
              name
            }
            terminals {
              attributes {
                pid
                ratedPower
              }
              config {
                limitProfile {
                  level
                  purpose
                }
                runtime {
                  triggerMeterValues
                }
              }
              id
              metadata {
                description
                name
              }
            }
          }
          grid {
            id
            metadata {
              description
              name
            }
            param {
              extCurrentSensor {
                pn
                ratio
              }
              fuseCurrentRating
              loadResponseTime
            }
            terminals {
              attributes {
                pid
                ratedCurrent
                ratedPower
              }
              config {
                installation {
                  ctConfig {
                    configDone
                    ctRatio
                    lastConfigDate
                  }
                  gridFiltCoeff
                  gridLimits {
                    imag
                    real
                  }
                  isSingle
                }
                runtime {
                  aceMode {
                    mode
                    ref1
                    ref2
                  }
                  apwrMode {
                    mode
                    ref1
                    ref2
                  }
                  currentLimits {
                    imag
                    real
                  }
                  droopDeadband {
                    left
                    right
                  }
                  droopLimits {
                    neg
                    pos
                  }
                  powerLimits {
                    neg
                    pos
                  }
                  pffLimits {
                    neg
                    pos
                  }
                }
              }
              id
              metadata {
                description
                name
              }
              param {
                enable
                override
              }
            }
          }
          pv {
            id
            metadata {
              description
              name
            }
            param {
              installedCapacity
            }
            terminals {
              attributes {
                pid
                ratedPower
              }
              config {
                runtime {
                  droopLimits {
                    neg
                    pos
                  }
                  runtimeMode {
                    mode
                    ref1
                    ref2
                  }
                }
              }
              id
              metadata {
                description
                name
              }
              param {
                override
                enable
              }
            }
          }
        }
      }
    }
  }
`

export function useGetFacilitySystemTopologyQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilitySystemTopologyQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilitySystemTopologyQuery, GetFacilitySystemTopologyQueryVariables>({
    query: GetFacilitySystemTopologyDocument,
    ...options
  })
}
export const GetSbcInfoForTopologyDocument = gql`
  query getSbcInfoForTopology($productId: String!) {
    singleBoardComputer(where: { productId: { equals: $productId } }) {
      bbb {
        hostname
      }
      version
    }
  }
`

export function useGetSbcInfoForTopologyQuery(
  options: Omit<Urql.UseQueryArgs<GetSbcInfoForTopologyQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSbcInfoForTopologyQuery, GetSbcInfoForTopologyQueryVariables>({
    query: GetSbcInfoForTopologyDocument,
    ...options
  })
}
export const GetCloudApiSettingsDocument = gql`
  query getCloudApiSettings($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      name
      settings {
        cloudApi {
          allowedRequests
          apiKey
          bindIp
        }
      }
    }
  }
`

export function useGetCloudApiSettingsQuery(
  options: Omit<Urql.UseQueryArgs<GetCloudApiSettingsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetCloudApiSettingsQuery, GetCloudApiSettingsQueryVariables>({
    query: GetCloudApiSettingsDocument,
    ...options
  })
}
export const EnableCloudApiDocument = gql`
  mutation enableCloudApi($facilityId: Int!, $data: CloudApiCreateInput) {
    createCloudApiSettings(id: $facilityId, data: $data) {
      cloudApi {
        allowedRequests
        apiKey
        bindIp
      }
      code
      message
      success
    }
  }
`

export function useEnableCloudApiMutation() {
  return Urql.useMutation<EnableCloudApiMutation, EnableCloudApiMutationVariables>(EnableCloudApiDocument)
}
export const DisableCloudApiDocument = gql`
  mutation disableCloudApi($facilityId: Int!) {
    removeCloudApiSettings(id: $facilityId) {
      cloudApi {
        allowedRequests
        apiKey
        bindIp
      }
      code
      message
      success
    }
  }
`

export function useDisableCloudApiMutation() {
  return Urql.useMutation<DisableCloudApiMutation, DisableCloudApiMutationVariables>(DisableCloudApiDocument)
}
export const UpdateCloudApiSettingsDocument = gql`
  mutation updateCloudApiSettings($facilityId: Int!, $data: CloudApiUpdateInput!) {
    updateCloudApiSettings(data: $data, id: $facilityId) {
      cloudApi {
        allowedRequests
        apiKey
        bindIp
      }
      code
      message
      success
    }
  }
`

export function useUpdateCloudApiSettingsMutation() {
  return Urql.useMutation<UpdateCloudApiSettingsMutation, UpdateCloudApiSettingsMutationVariables>(
    UpdateCloudApiSettingsDocument
  )
}
export const GetExampleEmsConfigurationsDocument = gql`
  query getExampleEmsConfigurations($id: Int) {
    facility(where: { id: { equals: $id } }) {
      configurations {
        emsConfiguration {
          current {
            config {
              ... on SystemApiEmsConfigurationV2 {
                __typename
                correlationId
                systemMode
                systemSettingsBattery {
                  powerRefCharge
                  socRefHigh
                  powerRefDischarge
                  socRefLow
                }
                systemSettingsGrid {
                  aceMode
                  aceThreshold
                  limitExport
                  limitImport
                  powerThresholdHigh
                  powerThresholdLow
                }
                systemSettingsPv {
                  pvMode
                }
                timestamp
              }
            }
          }
        }
      }
    }
  }
`

export function useGetExampleEmsConfigurationsQuery(
  options?: Omit<Urql.UseQueryArgs<GetExampleEmsConfigurationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetExampleEmsConfigurationsQuery, GetExampleEmsConfigurationsQueryVariables>({
    query: GetExampleEmsConfigurationsDocument,
    ...options
  })
}
export const PollEmsConfigurationDocument = gql`
  query pollEmsConfiguration($id: Int!, $generation: String!) {
    facility(where: { id: { equals: $id } }) {
      configurations {
        emsConfiguration {
          poll(generation: $generation) {
            state
            rejection {
              timestamp
              namespace
              eventsource
              correlationId
              event
              appVersion
            }
            status {
              correlationId
              appVersion
              event
              eventsource
              namespace
              timestamp
            }
            name
          }
        }
      }
    }
  }
`

export function usePollEmsConfigurationQuery(
  options: Omit<Urql.UseQueryArgs<PollEmsConfigurationQueryVariables>, 'query'>
) {
  return Urql.useQuery<PollEmsConfigurationQuery, PollEmsConfigurationQueryVariables>({
    query: PollEmsConfigurationDocument,
    ...options
  })
}
export const UpsertEmsConfigurationDocument = gql`
  mutation upsertEmsConfiguration($id: Int!, $data: UpsertEmsConfigurationDataArgs!) {
    upsertEmsConfiguration(data: $data, where: { id: $id }) {
      generation
      success
      validationFailure
    }
  }
`

export function useUpsertEmsConfigurationMutation() {
  return Urql.useMutation<UpsertEmsConfigurationMutation, UpsertEmsConfigurationMutationVariables>(
    UpsertEmsConfigurationDocument
  )
}
export const GetFacilitiesListDocument = gql`
  query GetFacilitiesList(
    $skip: Int
    $take: Int
    $where: FacilityWhereInput
    $orderBy: [FacilityOrderByWithRelationInput!]
  ) {
    facilitiesConnection(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      edges {
        createdAt
        updatedAt
        id
        info {
          version
        }
        name
        measurements {
          dataReceivedAt
        }
        singleBoardComputer {
          bbb {
            hostname
          }
        }
        powershare {
          id
          name
        }
      }
      totalCount
    }
  }
`

export function useGetFacilitiesListQuery(
  options?: Omit<Urql.UseQueryArgs<GetFacilitiesListQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilitiesListQuery, GetFacilitiesListQueryVariables>({
    query: GetFacilitiesListDocument,
    ...options
  })
}
export const GetFacilityInfoDocument = gql`
  query getFacilityInfo($id: Int) {
    facility(where: { id: { equals: $id } }) {
      info {
        version
      }
      measurements {
        dataReceivedAt
      }
      uiComponents {
        capacities {
          ... on UIMainFuseCapacity {
            __typename
            capacity
            unit
          }
          ... on UIMaxInverterCurrentCapacity {
            __typename
            capacity
            unit
          }
          ... on UIEnergyStorageRatedPowerCapacity {
            __typename
            capacity
            unit
          }
          ... on UIMaxInverterPowerCapacity {
            __typename
            capacity
            unit
          }
          ... on UIEnergyStorageRatedCapacity {
            __typename
            capacity
            unit
          }
        }
        flowChart {
          enabledComponents
        }
      }
      location {
        address
        city
        country
        electricalGridId
        latitude
        longitude
        propertyDesignation
      }
      misc {
        costElectricityKWh
        currencyCode
        revenueSoldToGridkWh
      }
      capacity {
        installedPv
      }
      id
      name
      installedOn
      hostname
      features
      discontinuedAt
      createdAt
      timezone
      updatedAt
      singleBoardComputer {
        productId
        version
        lifeCycleStage
        bbb {
          hostname
        }
      }
      lifeCycle {
        current {
          stage
          updatedAt
        }
      }
      powershare {
        id
        name
      }
    }
  }
`

export function useGetFacilityInfoQuery(
  options?: Omit<Urql.UseQueryArgs<GetFacilityInfoQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityInfoQuery, GetFacilityInfoQueryVariables>({
    query: GetFacilityInfoDocument,
    ...options
  })
}
export const GetFacilityLifeCycleHistoryDocument = gql`
  query GetFacilityLifeCycleHistory($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      lifeCycle {
        history(includeCurrent: true) {
          stage
          updatedAt
        }
        current {
          stage
          updatedAt
        }
        allowedTransitions {
          stage
        }
      }
    }
  }
`

export function useGetFacilityLifeCycleHistoryQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityLifeCycleHistoryQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityLifeCycleHistoryQuery, GetFacilityLifeCycleHistoryQueryVariables>({
    query: GetFacilityLifeCycleHistoryDocument,
    ...options
  })
}
export const GetFacilityAvailableLifeCycleTransitionsDocument = gql`
  query GetFacilityAvailableLifeCycleTransitions($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      lifeCycle {
        allowedTransitions {
          stage
        }
      }
    }
  }
`

export function useGetFacilityAvailableLifeCycleTransitionsQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityAvailableLifeCycleTransitionsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetFacilityAvailableLifeCycleTransitionsQuery,
    GetFacilityAvailableLifeCycleTransitionsQueryVariables
  >({ query: GetFacilityAvailableLifeCycleTransitionsDocument, ...options })
}
export const FindFacilityWhereDocument = gql`
  query findFacilityWhere(
    $skip: Int
    $take: Int
    $where: FacilityWhereInput
    $orderBy: [FacilityOrderByWithRelationInput!]
  ) {
    facilities(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      createdAt
      id
      name
      hostname
    }
  }
`

export function useFindFacilityWhereQuery(
  options?: Omit<Urql.UseQueryArgs<FindFacilityWhereQueryVariables>, 'query'>
) {
  return Urql.useQuery<FindFacilityWhereQuery, FindFacilityWhereQueryVariables>({
    query: FindFacilityWhereDocument,
    ...options
  })
}
export const GetFacilityEnabledComponentsDocument = gql`
  query getFacilityEnabledComponents($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      uiComponents {
        flowChart {
          enabledComponents
        }
      }
    }
  }
`

export function useGetFacilityEnabledComponentsQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityEnabledComponentsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityEnabledComponentsQuery, GetFacilityEnabledComponentsQueryVariables>({
    query: GetFacilityEnabledComponentsDocument,
    ...options
  })
}
export const GetFacilityUsersDocument = gql`
  query getFacilityUsers($id: Int!) {
    facility(where: { id: { equals: $id } }) {
      id
      name
      users {
        name
        email
        id
        relation
      }
    }
  }
`

export function useGetFacilityUsersQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityUsersQuery, GetFacilityUsersQueryVariables>({
    query: GetFacilityUsersDocument,
    ...options
  })
}
export const EditFacilitySbcMappingDocument = gql`
  mutation EditFacilitySbcMapping(
    $set: EditFacilityMappingDataInput!
    $where: EditFacilityMappingWhereInput!
  ) {
    editFacilitySingleBoardComputerMapping(where: $where, set: $set) {
      errors {
        ... on SBCTransitionError {
          __typename
          allowedTransitions
        }
        ... on SbcBadLifeCycleStageError {
          __typename
          allowedStages
        }
      }
      code
      success
      message
    }
  }
`

export function useEditFacilitySbcMappingMutation() {
  return Urql.useMutation<EditFacilitySbcMappingMutation, EditFacilitySbcMappingMutationVariables>(
    EditFacilitySbcMappingDocument
  )
}
export const GetStatisticsDocument = gql`
  query GetStatistics($where: FacilityWhereInput, $period: EnergyPeriodInput!) {
    facility: facility(where: $where) {
      __typename
      id
      createdAt
      measurements {
        __typename
        id
        energy(period: $period, unit: Wh, dataSeriesType: Relative) {
          __typename
          ... on SystemEnergyDate {
            __typename
            timeperiodStartDay
          }
          ... on SystemEnergyDateTime {
            __typename
            timeperiodStart
          }
          pvProduced
          consumption
          gridImport
          gridExport
        }
      }
    }
  }
`

export function useGetStatisticsQuery(
  options: Omit<Urql.UseQueryArgs<GetStatisticsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetStatisticsQuery, GetStatisticsQueryVariables>({
    query: GetStatisticsDocument,
    ...options
  })
}
export const GetFacilitiesMapQueryDocument = gql`
  query GetFacilitiesMapQuery($latitude: FloatNullableFilter, $longitude: FloatNullableFilter) {
    facilities(where: { location: { is: { latitude: $latitude, longitude: $longitude } } }) {
      id
      name
      location {
        latitude
        longitude
      }
    }
  }
`

export function useGetFacilitiesMapQueryQuery(
  options?: Omit<Urql.UseQueryArgs<GetFacilitiesMapQueryQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilitiesMapQueryQuery, GetFacilitiesMapQueryQueryVariables>({
    query: GetFacilitiesMapQueryDocument,
    ...options
  })
}
export const GetFacilityHistoryDocument = gql`
  query GetFacilityHistory($facilityId: Int!) {
    facility(where: { id: { equals: $facilityId } }) {
      users {
        email
        id
      }
      history {
        changes {
          ... on FacilityHistoryChangeDelete {
            __typename
            before
            path
          }
          ... on FacilityHistoryChangeInsert {
            __typename
            after
            path
          }
          ... on FacilityHistoryChangeUpdate {
            __typename
            after
            before
            path
          }
        }
        correlationId
        createdAt
        userId
      }
    }
  }
`

export function useGetFacilityHistoryQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityHistoryQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityHistoryQuery, GetFacilityHistoryQueryVariables>({
    query: GetFacilityHistoryDocument,
    ...options
  })
}
export const GetFacilityPower1MinuteDocument = gql`
  query GetFacilityPower1Minute($id: Int!, $from: DateTimeISO!, $to: DateTimeISO!) {
    facility(where: { id: { equals: $id } }) {
      createdAt
      measurements {
        power(timeRange: { gte: $from, lte: $to }, resolution: Minutely) {
          loadPower
          inverterPower
          gridPower
          batteryPower
          timestamp
          pvPower
        }
      }
    }
  }
`

export function useGetFacilityPower1MinuteQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityPower1MinuteQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityPower1MinuteQuery, GetFacilityPower1MinuteQueryVariables>({
    query: GetFacilityPower1MinuteDocument,
    ...options
  })
}
export const GetFacilityPower1SecondDocument = gql`
  query GetFacilityPower1Second(
    $id: Int!
    $year: BaseRawDataWherePartitionInput!
    $month: BaseRawDataWherePartitionInput!
    $day: BaseRawDataWherePartitionInput!
    $hour: BaseRawDataWherePartitionInput!
  ) {
    facility(where: { id: { equals: $id } }) {
      createdAt
      measurements {
        rawPower(where: { day: $day, hour: $hour, month: $month, year: $year }) {
          gridPower
          loadPower
          inverterPower
          batteryPower
          pvPower
          timestamp
        }
      }
    }
  }
`

export function useGetFacilityPower1SecondQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityPower1SecondQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityPower1SecondQuery, GetFacilityPower1SecondQueryVariables>({
    query: GetFacilityPower1SecondDocument,
    ...options
  })
}
export const GetFacilityRawGridDataDocument = gql`
  query GetFacilityRawGridData(
    $id: Int!
    $year: BaseRawDataWherePartitionInput!
    $month: BaseRawDataWherePartitionInput!
    $day: BaseRawDataWherePartitionInput!
    $hour: BaseRawDataWherePartitionInput!
  ) {
    facility(where: { id: { equals: $id } }) {
      createdAt
      measurements {
        rawUidata(where: { day: $day, hour: $hour, month: $month, year: $year }) {
          iExtD1
          iExtD2
          iExtD3
          iExtQ1
          iExtQ2
          iExtQ3
          iExtRms1
          iExtRms2
          iExtRms3
          iInvD1
          iInvD2
          iInvD3
          iInvQ1
          iInvQ2
          iInvQ3
          iInvRms1
          iInvRms2
          iInvRms3
          timestamp
          u1
          u2
          u3
          uDCneg
          uDCpos
        }
      }
    }
  }
`

export function useGetFacilityRawGridDataQuery(
  options: Omit<Urql.UseQueryArgs<GetFacilityRawGridDataQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetFacilityRawGridDataQuery, GetFacilityRawGridDataQueryVariables>({
    query: GetFacilityRawGridDataDocument,
    ...options
  })
}
export const GetBessLogDocument = gql`
  query GetBessLog(
    $id: Int!
    $year: BaseRawDataWherePartitionInput!
    $month: BaseRawDataWherePartitionInput!
    $day: BaseRawDataWherePartitionInput!
    $hour: BaseRawDataWherePartitionInput!
  ) {
    facility(where: { id: { equals: $id } }) {
      logs {
        bessLog(where: { day: $day, hour: $hour, month: $month, year: $year }) {
          warningFlag
          timestamp
          stateOfHealth
          stateOfCharge
          serial
          ratedPower
          ratedCapacity
          fault
          errorFlag
          cellVoltageMin
          cellVoltageMax
          cellTemperatureMin
          cellTemperatureMax
          bessState
          bmsState
          batteryManufacturer
          alarmFlag
        }
      }
    }
  }
`

export function useGetBessLogQuery(options: Omit<Urql.UseQueryArgs<GetBessLogQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBessLogQuery, GetBessLogQueryVariables>({ query: GetBessLogDocument, ...options })
}
export const UpdateFacilityLifeCycleDocument = gql`
  mutation UpdateFacilityLifeCycle($id: Int!, $stage: FacilityLifeCycleStages!) {
    updateOneFacilityLifeCycle(data: { transitionToStage: $stage }, where: { facilityId: $id }) {
      success
      message
      lifeCycle {
        current {
          stage
          updatedAt
        }
      }
      errors {
        ... on ValidationError {
          __typename
          path
          message
          code
        }
        ... on FacilityNotFound {
          __typename
          facilityId
        }
        ... on FacilityLifeCycleTransitionError {
          __typename
          allowedTransitions
        }
      }
      code
    }
  }
`

export function useUpdateFacilityLifeCycleMutation() {
  return Urql.useMutation<UpdateFacilityLifeCycleMutation, UpdateFacilityLifeCycleMutationVariables>(
    UpdateFacilityLifeCycleDocument
  )
}
export const GetPowershareListDocument = gql`
  query getPowershareList(
    $orderBy: [PowershareOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: PowershareWhereInput
  ) {
    powersharesConnection(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
      totalCount
      edges {
        id
        name
        updatedAt
        installedOn
        discontinuedAt
        createdAt
      }
    }
  }
`

export function useGetPowershareListQuery(
  options?: Omit<Urql.UseQueryArgs<GetPowershareListQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPowershareListQuery, GetPowershareListQueryVariables>({
    query: GetPowershareListDocument,
    ...options
  })
}
export const GetOnePowershareDocument = gql`
  query getOnePowershare(
    $cursor: PowershareWhereUniqueInput
    $distinct: [PowershareScalarFieldEnum!]
    $orderBy: [PowershareOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: PowershareWhereInput
  ) {
    powershare(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      createdAt
      discontinuedAt
      id
      installedOn
      name
      updatedAt
    }
  }
`

export function useGetOnePowershareQuery(
  options?: Omit<Urql.UseQueryArgs<GetOnePowershareQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOnePowershareQuery, GetOnePowershareQueryVariables>({
    query: GetOnePowershareDocument,
    ...options
  })
}
export const GetPowershareUserRelationsDocument = gql`
  query getPowershareUserRelations(
    $cursor: PowershareWhereUniqueInput
    $distinct: [PowershareScalarFieldEnum!]
    $orderBy: [PowershareOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: PowershareWhereInput
  ) {
    powershare(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      users {
        relation
        id
        legacyId
        name
        email
      }
    }
  }
`

export function useGetPowershareUserRelationsQuery(
  options?: Omit<Urql.UseQueryArgs<GetPowershareUserRelationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPowershareUserRelationsQuery, GetPowershareUserRelationsQueryVariables>({
    query: GetPowershareUserRelationsDocument,
    ...options
  })
}
export const GetPowershareFacilityRelationsDocument = gql`
  query getPowershareFacilityRelations(
    $cursor: PowershareWhereUniqueInput
    $distinct: [PowershareScalarFieldEnum!]
    $orderBy: [PowershareOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: PowershareWhereInput
  ) {
    powershare(
      cursor: $cursor
      distinct: $distinct
      orderBy: $orderBy
      skip: $skip
      take: $take
      where: $where
    ) {
      facilities {
        id
        updatedAt
        name
      }
    }
  }
`

export function useGetPowershareFacilityRelationsQuery(
  options?: Omit<Urql.UseQueryArgs<GetPowershareFacilityRelationsQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetPowershareFacilityRelationsQuery, GetPowershareFacilityRelationsQueryVariables>({
    query: GetPowershareFacilityRelationsDocument,
    ...options
  })
}
export const RemoveOneFacilityFromPowershareDocument = gql`
  mutation removeOneFacilityFromPowershare($powershareId: Int!, $facilityId: Int!) {
    removeOneFacilityFromPowershare(where: { powershareId: $powershareId, facilityId: $facilityId }) {
      updatedAt
      id
      name
    }
  }
`

export function useRemoveOneFacilityFromPowershareMutation() {
  return Urql.useMutation<
    RemoveOneFacilityFromPowershareMutation,
    RemoveOneFacilityFromPowershareMutationVariables
  >(RemoveOneFacilityFromPowershareDocument)
}
export const DeleteOnePowershareDocument = gql`
  mutation deleteOnePowershare($where: WherePowershareId!) {
    removeOnePowershare(where: $where) {
      createdAt
      discontinuedAt
      id
      installedOn
      name
    }
  }
`

export function useDeleteOnePowershareMutation() {
  return Urql.useMutation<DeleteOnePowershareMutation, DeleteOnePowershareMutationVariables>(
    DeleteOnePowershareDocument
  )
}
export const UpdateOnePowershareDocument = gql`
  mutation updateOnePowershare($data: UpdateOnePowershareInputs!, $where: WherePowershareId!) {
    updateOnePowershare(where: $where, data: $data) {
      createdAt
      discontinuedAt
      id
      installedOn
      name
      updatedAt
    }
  }
`

export function useUpdateOnePowershareMutation() {
  return Urql.useMutation<UpdateOnePowershareMutation, UpdateOnePowershareMutationVariables>(
    UpdateOnePowershareDocument
  )
}
export const AddManyUserToPowershareDocument = gql`
  mutation addManyUserToPowershare($data: AddManyPowershareUsersInput!, $where: WherePowershareId!) {
    addManyUserToPowershare(data: $data, where: $where) {
      name
      id
    }
  }
`

export function useAddManyUserToPowershareMutation() {
  return Urql.useMutation<AddManyUserToPowershareMutation, AddManyUserToPowershareMutationVariables>(
    AddManyUserToPowershareDocument
  )
}
export const AddManyFacilitiesToPowershareDocument = gql`
  mutation addManyFacilitiesToPowershare(
    $data: AddManyFacilitiesToPowershareInput!
    $where: WherePowershareId!
  ) {
    addManyFacilitiesToPowershare(data: $data, where: $where) {
      name
      id
    }
  }
`

export function useAddManyFacilitiesToPowershareMutation() {
  return Urql.useMutation<
    AddManyFacilitiesToPowershareMutation,
    AddManyFacilitiesToPowershareMutationVariables
  >(AddManyFacilitiesToPowershareDocument)
}
export const RemoveOneUserFromPowershareDocument = gql`
  mutation removeOneUserFromPowershare($userId: String!, $powershareId: Int!) {
    removeOneUserFromPowershare(where: { powershareId: $powershareId, userId: $userId }) {
      updatedAt
      id
      name
    }
  }
`

export function useRemoveOneUserFromPowershareMutation() {
  return Urql.useMutation<RemoveOneUserFromPowershareMutation, RemoveOneUserFromPowershareMutationVariables>(
    RemoveOneUserFromPowershareDocument
  )
}
export const CreateOnePowershareDocument = gql`
  mutation createOnePowershare($data: CreateOnePowershareInputs!) {
    createOnePowershare(data: $data) {
      id
      name
    }
  }
`

export function useCreateOnePowershareMutation() {
  return Urql.useMutation<CreateOnePowershareMutation, CreateOnePowershareMutationVariables>(
    CreateOnePowershareDocument
  )
}
export const GetSearchableFacilityDocument = gql`
  query getSearchableFacility($searchValue: String!, $searchNumber: Int!) {
    facilities(
      where: {
        OR: [
          { name: { contains: $searchValue, mode: insensitive } }
          { id: { equals: $searchNumber } }
          { location: { is: { address: { contains: $searchValue, mode: insensitive } } } }
        ]
      }
      take: 3
    ) {
      id
      name
    }
  }
`

export function useGetSearchableFacilityQuery(
  options: Omit<Urql.UseQueryArgs<GetSearchableFacilityQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSearchableFacilityQuery, GetSearchableFacilityQueryVariables>({
    query: GetSearchableFacilityDocument,
    ...options
  })
}
export const GetSearchablePowershareDocument = gql`
  query getSearchablePowershare($searchValue: String!, $searchNumber: Int!) {
    powershares(
      take: 3
      where: {
        OR: [{ name: { contains: $searchValue, mode: insensitive } }, { id: { equals: $searchNumber } }]
      }
    ) {
      name
      id
    }
  }
`

export function useGetSearchablePowershareQuery(
  options: Omit<Urql.UseQueryArgs<GetSearchablePowershareQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSearchablePowershareQuery, GetSearchablePowershareQueryVariables>({
    query: GetSearchablePowershareDocument,
    ...options
  })
}
export const GetSearchableSingleBoardComputersDocument = gql`
  query getSearchableSingleBoardComputers($searchValue: String!) {
    singleBoardComputers(
      where: {
        OR: [
          { productId: { contains: $searchValue, mode: insensitive } }
          { bbb: { is: { macAddress: { contains: $searchValue, mode: insensitive } } } }
          { bbb: { is: { hostname: { contains: $searchValue, mode: insensitive } } } }
        ]
      }
      take: 3
    ) {
      productId
      bbb {
        hostname
        macAddress
      }
    }
  }
`

export function useGetSearchableSingleBoardComputersQuery(
  options: Omit<Urql.UseQueryArgs<GetSearchableSingleBoardComputersQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetSearchableSingleBoardComputersQuery,
    GetSearchableSingleBoardComputersQueryVariables
  >({ query: GetSearchableSingleBoardComputersDocument, ...options })
}
export const GetSearchableUsersDocument = gql`
  query getSearchableUsers($searchValue: String!) {
    users(where: { search: $searchValue }, take: 3) {
      name
      email
      id
    }
    user(id: $searchValue) {
      id
      email
      name
    }
  }
`

export function useGetSearchableUsersQuery(
  options: Omit<Urql.UseQueryArgs<GetSearchableUsersQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSearchableUsersQuery, GetSearchableUsersQueryVariables>({
    query: GetSearchableUsersDocument,
    ...options
  })
}
export const GetOneSingleBoardComputerDocument = gql`
  query getOneSingleBoardComputer($productId: String!) {
    singleBoardComputer(where: { productId: { equals: $productId } }) {
      createdAt
      bbb {
        hostname
        macAddress
        portalRegistrationKey
      }
      version
      productId
      lifeCycleStage
      lastSeenAt
      installedAt
      generation
      facility {
        name
        id
      }
    }
  }
`

export function useGetOneSingleBoardComputerQuery(
  options: Omit<Urql.UseQueryArgs<GetOneSingleBoardComputerQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetOneSingleBoardComputerQuery, GetOneSingleBoardComputerQueryVariables>({
    query: GetOneSingleBoardComputerDocument,
    ...options
  })
}
export const GetSingleBoardComputerListDocument = gql`
  query getSingleBoardComputerList(
    $orderBy: [SingleBoardComputerOrderByWithRelationInput!]
    $skip: Int
    $take: Int
    $where: SingleBoardComputerWhereInput
  ) {
    singleBoardComputersConnection(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      edges {
        productId
        version
        lifeCycleStage
        bbb {
          macAddress
          hostname
        }
        createdAt
        generation
        facility {
          id
        }
      }
      totalCount
    }
  }
`

export function useGetSingleBoardComputerListQuery(
  options?: Omit<Urql.UseQueryArgs<GetSingleBoardComputerListQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetSingleBoardComputerListQuery, GetSingleBoardComputerListQueryVariables>({
    query: GetSingleBoardComputerListDocument,
    ...options
  })
}
export const GetSingleBoardComputerVersionChangeLogDocument = gql`
  query getSingleBoardComputerVersionChangeLog($productId: String!) {
    singleBoardComputer(where: { productId: { equals: $productId } }) {
      versionChangeLog(orderBy: { updatedAt: desc }) {
        fromVersion
        toVersion
        updatedAt
      }
    }
  }
`

export function useGetSingleBoardComputerVersionChangeLogQuery(
  options: Omit<Urql.UseQueryArgs<GetSingleBoardComputerVersionChangeLogQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetSingleBoardComputerVersionChangeLogQuery,
    GetSingleBoardComputerVersionChangeLogQueryVariables
  >({ query: GetSingleBoardComputerVersionChangeLogDocument, ...options })
}
export const GetAllowedSbcLifeCycleTransitionsDocument = gql`
  query getAllowedSbcLifeCycleTransitions($productId: String!) {
    singleBoardComputer(where: { productId: { equals: $productId } }) {
      lifeCycleStage
      allowedLifeCycleTransitions {
        stage
      }
    }
  }
`

export function useGetAllowedSbcLifeCycleTransitionsQuery(
  options: Omit<Urql.UseQueryArgs<GetAllowedSbcLifeCycleTransitionsQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    GetAllowedSbcLifeCycleTransitionsQuery,
    GetAllowedSbcLifeCycleTransitionsQueryVariables
  >({ query: GetAllowedSbcLifeCycleTransitionsDocument, ...options })
}
export const SetSingleBoardComputerLifeCycleStageDocument = gql`
  mutation setSingleBoardComputerLifeCycleStage(
    $stage: SingleBoardComputerLifeCycleStages!
    $productId: String!
  ) {
    setSingleBoardComputerLifeCycleStage(data: { stage: $stage }, where: { productId: $productId }) {
      code
      errors {
        code: __typename
        ... on SBCTransitionError {
          allowedTransitions
        }
      }
      message
      success
      singleBoardComputer {
        lifeCycleStage
      }
    }
  }
`

export function useSetSingleBoardComputerLifeCycleStageMutation() {
  return Urql.useMutation<
    SetSingleBoardComputerLifeCycleStageMutation,
    SetSingleBoardComputerLifeCycleStageMutationVariables
  >(SetSingleBoardComputerLifeCycleStageDocument)
}
export const FindSingleBoardComputerForFacilityRemappingDocument = gql`
  query FindSingleBoardComputerForFacilityRemapping($where: SingleBoardComputerWhereInput) {
    singleBoardComputers(take: 1, where: $where) {
      productId
      version
      lifeCycleStage
    }
  }
`

export function useFindSingleBoardComputerForFacilityRemappingQuery(
  options?: Omit<Urql.UseQueryArgs<FindSingleBoardComputerForFacilityRemappingQueryVariables>, 'query'>
) {
  return Urql.useQuery<
    FindSingleBoardComputerForFacilityRemappingQuery,
    FindSingleBoardComputerForFacilityRemappingQueryVariables
  >({ query: FindSingleBoardComputerForFacilityRemappingDocument, ...options })
}
export const GetUserSearchDocument = gql`
  query getUserSearch(
    $where: FindManyUsersWhereArgs
    $skip: Int
    $take: Int
    $id: String!
    $idSearch: Boolean!
  ) {
    users(where: $where, skip: $skip, take: $take) {
      id
      name
      email
      phoneNumber
      createdAt
    }
    usersConnection(where: $where, skip: $skip, take: $take) {
      totalCount
    }
    user(id: $id) @include(if: $idSearch) {
      id
      name
      email
      phoneNumber
      createdAt
    }
  }
`

export function useGetUserSearchQuery(
  options: Omit<Urql.UseQueryArgs<GetUserSearchQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserSearchQuery, GetUserSearchQueryVariables>({
    query: GetUserSearchDocument,
    ...options
  })
}
export const GetUserListDocument = gql`
  query getUserList($where: FindManyUsersWhereArgs, $skip: Int, $take: Int) {
    usersConnection(where: $where, skip: $skip, take: $take) {
      totalCount
      edges {
        email
        name
        phoneNumber
        username
        id
        createdAt
        legacyId
      }
    }
  }
`

export function useGetUserListQuery(options?: Omit<Urql.UseQueryArgs<GetUserListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserListQuery, GetUserListQueryVariables>({
    query: GetUserListDocument,
    ...options
  })
}
export const GetUserDocument = gql`
  query getUser($id: String!) {
    user(id: $id) {
      colors
      createdAt
      email
      emailVerified
      id
      lastName
      legacyId
      locale
      name
      phoneNumber
    }
  }
`

export function useGetUserQuery(options: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options })
}
export const GetUserPowersharesDocument = gql`
  query getUserPowershares($userId: String!) {
    user(id: $userId) {
      powershares {
        id
        name
        relation
      }
    }
  }
`

export function useGetUserPowersharesQuery(
  options: Omit<Urql.UseQueryArgs<GetUserPowersharesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserPowersharesQuery, GetUserPowersharesQueryVariables>({
    query: GetUserPowersharesDocument,
    ...options
  })
}
export const GetUserFacilitiesDocument = gql`
  query getUserFacilities($userId: String!) {
    user(id: $userId) {
      facilities {
        name
        id
        info {
          version
        }
        relation
        updatedAt
      }
    }
  }
`

export function useGetUserFacilitiesQuery(
  options: Omit<Urql.UseQueryArgs<GetUserFacilitiesQueryVariables>, 'query'>
) {
  return Urql.useQuery<GetUserFacilitiesQuery, GetUserFacilitiesQueryVariables>({
    query: GetUserFacilitiesDocument,
    ...options
  })
}
export const AddOneUserToPowershareDocument = gql`
  mutation addOneUserToPowershare($userId: String!, $powershareId: Int!) {
    addOneUserToPowershare(
      data: { userId: $userId, relation: POWERSHARE_READER }
      where: { powershareId: $powershareId }
    ) {
      id
      name
    }
  }
`

export function useAddOneUserToPowershareMutation() {
  return Urql.useMutation<AddOneUserToPowershareMutation, AddOneUserToPowershareMutationVariables>(
    AddOneUserToPowershareDocument
  )
}
export const AddExistingUserToFacilityDocument = gql`
  mutation AddExistingUserToFacility($email: String!, $facilityId: ID!, $relation: FacilityUserRoles!) {
    addExistingUserToFacility(data: { email: $email, facilityId: $facilityId, relation: $relation }) {
      code
      errors {
        ... on ValidationError {
          __typename
          code
          message
          path
        }
        ... on UserWithEmailNotFound {
          __typename
          description
          email
        }
        ... on FacilityNotFound {
          __typename
          facilityId
        }
      }
      message
      success
      user {
        id
        name
      }
    }
  }
`

export function useAddExistingUserToFacilityMutation() {
  return Urql.useMutation<AddExistingUserToFacilityMutation, AddExistingUserToFacilityMutationVariables>(
    AddExistingUserToFacilityDocument
  )
}
export const RemoveUserFromFacilityDocument = gql`
  mutation RemoveUserFromFacility($userId: ID!, $facilityId: ID!) {
    removeUserFacilityRelation(data: { userId: $userId, facilityId: $facilityId }) {
      code
      errors {
        ... on ValidationError {
          __typename
          code
          message
          path
        }
        ... on UserWithEmailNotFound {
          __typename
          description
          email
        }
        ... on FacilityNotFound {
          __typename
          facilityId
        }
      }
      success
      message
      user {
        email
        id
      }
    }
  }
`

export function useRemoveUserFromFacilityMutation() {
  return Urql.useMutation<RemoveUserFromFacilityMutation, RemoveUserFromFacilityMutationVariables>(
    RemoveUserFromFacilityDocument
  )
}
