import { create } from 'zustand'

export interface UseHeaderProps {
  pageInfo?: {
    title?: string
    description?: string
  }
  setPageInfo: (pageInfo: UseHeaderProps['pageInfo']) => void
  removePageInfo: () => void
}

export const useHeader = create<UseHeaderProps>(set => ({
  pageInfo: undefined,
  setPageInfo: (pageInfo: UseHeaderProps['pageInfo']) => {
    set(() => ({ pageInfo }))
  },
  removePageInfo: () => {
    set({ pageInfo: undefined })
  }
}))
