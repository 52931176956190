import { FC } from 'react'

interface DatetimeProps {
  date: Date | string
}

export const Datetime: FC<DatetimeProps> = ({ date }) => {
  if (date === null || date === undefined) return <span />

  const asDate = new Date(date)
  const isValid = !isNaN(asDate.valueOf())
  const datetimeAsString = isValid ? asDate.toISOString() : ''

  return <span>{datetimeAsString}</span>
}
