import { DataTable } from '@/components/DataTable'
import TBody from '@/components/DataTable/TBody'
import THead from '@/components/DataTable/THead'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import useFacilitiesTable, { facilitiesSearchSchema } from '@/hooks/useFacilitiesTable'
import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/facilities/')({
  component: FacilityListComponent,
  validateSearch: facilitiesSearchSchema,
  pendingComponent: () => <Skeleton className='h-20 w-full' />
})

function FacilityListComponent() {
  const { t } = useTranslation()
  const { table, fetching, error } = useFacilitiesTable()

  return (
    <div className='flex flex-col gap-4'>
      <Card>
        <CardHeader>
          <CardTitle>{t('facility.facility', { count: 100 })}</CardTitle>
        </CardHeader>
        <CardContent>
          <DataTable showPagination={true} fetching={fetching} error={error} table={table}>
            <THead />
            <TBody />
          </DataTable>
        </CardContent>
      </Card>
    </div>
  )
}
