import { routeTree } from '@/routeTree.gen'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import '@tanstack/react-table'

import { CreateFerroampClient } from '@/api'
import '@/components/Charts'
import { NotAccess } from '@/components/NotAccess'
import { ReloadPrompt } from '@/components/ReloadPrompt'
import { FspSplash } from '@/components/Splash'
import { useAppConfigStore, useAuthStore, useShallow } from '@/store'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState, type ReactElement } from 'react'
import { Client, Provider as UrqlProvider } from 'urql'
import { useCurrentFacility } from './store/useCurrentFacility'
const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export default function App(): ReactElement {
  const setFacility = useCurrentFacility(s => s.setFacility)
  const [client, setClient] = useState<Client | null>(null)
  const { createKeycloak, initiateKeycloak, created, userHasNotAccess } = useAuthStore(
    useShallow(state => ({
      userHasNotAccess: state.userHasNotAccess,
      created: state.created,
      createKeycloak: state.createKeycloak,
      initiateKeycloak: state.initiateKeycloak
    }))
  )
  const initConfig = useAppConfigStore(useShallow(state => state.initConfig))
  const loggedIn = useAuthStore(useShallow(state => state.loggedIn))

  useEffect(() => {
    const initiateConfig = async () => {
      await initConfig()
      const { config } = useAppConfigStore.getState()
      createKeycloak(config)
    }
    initiateConfig()
  }, [createKeycloak, initConfig])

  useEffect(() => {
    const startKeycloakLogin = async () => {
      await initiateKeycloak()
    }
    if (!loggedIn && created) startKeycloakLogin()
  }, [initiateKeycloak, created, loggedIn])

  useEffect(() => {
    if (!client && loggedIn) setClient(CreateFerroampClient())
  }, [loggedIn, client])

  if (userHasNotAccess) {
    return <NotAccess />
  }

  return (
    <>
      <AnimatePresence>{!client && <FspSplash />}</AnimatePresence>

      {client ? (
        <UrqlProvider value={client}>
          <RouterProvider router={router} context={{ urql: client, setFacility }} />
        </UrqlProvider>
      ) : null}

      <ReloadPrompt />
    </>
  )
}
