import { FacilityWhereInput, Powershare, QueryMode, useGetFacilitiesListQuery } from '@/api'
import { CopyToClipBoard } from '@/components/CopyToClipBoard'
import { Datetime } from '@/components/DateTime'
import { IsOnline } from '@/components/IsOnline'
import { Route } from '@/routes/facilities'
import { useAuthStore } from '@/store'
import { Link as RouterLink, useNavigate } from '@tanstack/react-router'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { usePagination } from './usePagination'

export const facilitiesSearchSchema = z.object({
  pagination: z
    .object({
      take: z.number().min(0).default(30),
      skip: z.number().min(0).default(0)
    })
    .default({}),
  orderBy: z
    .object({
      id: z.string().default('id'),
      desc: z.boolean().default(false)
    })
    .array()
    .default([]),
  where: z
    .object({
      id: z.string(),
      value: z.string()
    })
    .array()
    .default([])
})

type FacilitiesSearchParams = z.infer<typeof facilitiesSearchSchema>
type Order = FacilitiesSearchParams['orderBy']

type FacilitiesColumns = {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
  dataReceivedAt: Date
}

function useFacilitiesTable() {
  const search = Route.useSearch()
  const canUseSsh = useAuthStore(s => s.roleFeatures.canUseSsh)

  const { t } = useTranslation()
  const navigate = useNavigate({ from: Route.fullPath })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(search.where)

  const { pagination, onPaginationChange } = usePagination(search.pagination)

  const { pageSize, pageIndex } = pagination

  const skip = pageSize * pageIndex
  const take = pageSize

  const [sorting, setSorting] = useState<Order>(search.orderBy)

  const queryVariables = useMemo(
    () => ({
      take,
      skip,
      orderBy: sorting.reduce((acc: Record<string, 'desc' | 'asc'>, item) => {
        acc[item.id] = item.desc ? 'desc' : 'asc'
        return acc
      }, {}),

      where: (() => {
        const value = columnFilters?.[0]?.value || ''
        const numericValue = Number(value) || null
        return {
          OR: [
            {
              name: {
                contains: value,
                mode: QueryMode.Insensitive
              }
            },
            {
              ...(numericValue
                ? {
                    id: {
                      gte: numericValue
                    }
                  }
                : {})
            }
          ]
        } as FacilityWhereInput
      })()
    }),
    [take, skip, sorting, columnFilters]
  )

  const [result] = useGetFacilitiesListQuery({
    variables: queryVariables
  })

  const { data, fetching, error } = result

  useEffect(() => {
    navigate({
      to: Route.fullPath,
      search: prev => ({
        ...prev,
        pagination: { skip, take },
        orderBy: sorting,
        where: columnFilters.map(f => ({ value: f.value as string, id: f.id }))
      })
    })
  }, [pageIndex, pageSize, sorting, columnFilters, navigate, skip, take])

  const columns: ColumnDef<FacilitiesColumns>[] = useMemo(() => {
    const c: ColumnDef<FacilitiesColumns>[] = [
      {
        accessorKey: 'id',
        header: t('common.id'),
        cell: ({ row }) => (
          <RouterLink
            to='/facilities/$id'
            params={{ id: row.getValue('id') as string }}
            className='hover:text-primary hover:underline'
          >
            {row.getValue('id')}
          </RouterLink>
        )
      },
      {
        accessorKey: 'name',
        header: t('common.name'),
        meta: {
          filterVariant: 'text'
        },
        cell: ({ row }) => (
          <RouterLink
            to='/facilities/$id'
            params={{ id: row.getValue('id') as string }}
            className='hover:text-primary hover:underline'
          >
            {row.getValue('name')}
          </RouterLink>
        )
      }
    ]
    if (canUseSsh) {
      c.push({
        accessorKey: 'hostname',
        header: t('sbc.hostname'),
        enableSorting: false,
        cell: ({ row }) => {
          const hostname = row.getValue('hostname')
          return hostname ? (
            <>
              <span className='align-center flex'>
                {row.getValue('hostname')}
                <span className='flex-1' />
                <CopyToClipBoard
                  size={20}
                  value={`ssh ${row.getValue('hostname') || 'no-hostname'}`}
                  valueName={t('sbc.hostname')}
                  className='ml-2'
                />
              </span>
            </>
          ) : (
            <></>
          )
        }
      })
    }

    c.push({
      accessorKey: 'powershare',
      header: t('powershare.powershare'),
      enableSorting: false,
      cell: ({ row }) => {
        const powershare = row.getValue('powershare') as Powershare
        return powershare ? (
          <RouterLink
            to='/powershares/$id'
            params={{ id: powershare.id }}
            className='hover:text-primary hover:underline'
          >
            {powershare.name}
          </RouterLink>
        ) : null
      }
    })
    c.push({
      accessorKey: 'version',
      header: t('common.ehub-os-version'),
      enableSorting: false
    })

    c.push({
      accessorKey: 'createdAt',
      header: t('common.created-at'),
      enableSorting: false,
      cell: ({ row }) => <Datetime date={row.getValue('createdAt')} />
    })

    c.push({
      header: t('common.connectivity'),
      accessorKey: 'dataReceivedAt',
      cell: ({ row }) => <IsOnline dataReceivedAt={row.getValue('dataReceivedAt')} />,
      enableSorting: false
    })

    return c
  }, [t, canUseSsh])

  const state = {
    pagination,
    sorting,
    columnFilters
  }

  const table = useReactTable({
    data: (data?.facilitiesConnection.edges.map(row => ({
      ...row,
      ...row.info,
      ...row.measurements,
      ...(row?.singleBoardComputer?.bbb || { hostname: null })
    })) || []) as FacilitiesColumns[],
    columns,
    state,
    rowCount: data?.facilitiesConnection.totalCount || 0,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableFilters: true,
    onSortingChange: setSorting,
    onPaginationChange,
    onColumnFiltersChange: setColumnFilters
  })

  return { table, fetching, error }
}

export default useFacilitiesTable
