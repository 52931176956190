import axios from 'axios'
import { create } from 'zustand'

export interface AppConfig {
  graphqlUrl?: string
  authURL?: string
  authREALM?: string
  authClientId?: string
}

export interface AppConfigStoreProps {
  initConfig: () => Promise<void>
  config: AppConfig
  loaded: boolean
}

const initialState: AppConfig = {
  graphqlUrl: undefined,
  authURL: undefined,
  authREALM: undefined,
  authClientId: undefined
}

export const useAppConfigStore = create<AppConfigStoreProps>(set => ({
  config: initialState,
  loaded: false,
  initConfig: async () => {
    const res = await axios.get('/config.json')
    set(() => ({
      config: res.data,
      loaded: true
    }))
  }
}))
