import { useEffect, useState } from 'react'
import { CommandDialog } from './../ui/command'
import { GeneralSearch } from './Search'

export const SearchShortCut = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        if (setOpen) {
          setOpen(open => !open)
        }
      }
    }
    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <GeneralSearch open={open} setOpen={setOpen} />
    </CommandDialog>
  )
}
