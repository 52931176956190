import { GetSearchablePowershareQueryVariables, useGetSearchablePowershareQuery } from '@/api'
import { useGraphQLError } from '@/hooks/useGraphqlError'
import { useEffect, useMemo } from 'react'
import { CommandItemRow } from './types'

export const usePowershareCommandGroupData = ({
  where,
  paused
}: {
  where: GetSearchablePowershareQueryVariables | null
  paused: boolean
}) => {
  const [res] = useGetSearchablePowershareQuery({
    variables: where || { searchNumber: 0, searchValue: '' },
    pause: paused
  })
  const [, setErrors] = useGraphQLError()
  useEffect(() => {
    if (res.error && !res.error.graphQLErrors?.[0]?.path?.includes('user')) {
      setErrors(res.error)
    }
  }, [res.error, setErrors])

  return (useMemo(() => {
    const powershares = res?.data?.powershares

    return (
      powershares?.map(powershare => ({
        type: 'Powershare',
        description: `${powershare.name}  (${powershare.id})`,
        id: powershare.id
      })) || []
    )
  }, [res.data]) || []) as CommandItemRow[]
}
