import { useSidebar } from '@/hooks/useSidebar'
import { cn } from '@/lib/utils'
import { RiMailSendLine } from '@remixicon/react'
import { useTranslation } from 'react-i18next'
import { buttonVariants } from '../ui/button'

export const FeedbackButton = () => {
  const { t } = useTranslation()
  const { isOpen } = useSidebar()

  const to = 'fsp-feedback@ferroamp.se'
  const subject = 'Ferroamp Service Portal Feedback'
  const body = `


[Write your message here] 👆

Please feel free to attach screenshots or recordings 😊

--- ⛔ Please do not remove the following ⛔ ---
agent:${navigator.userAgent};Version:${__APP_VERSION__};BuildDate:${__APP_BUILD_DATE__};browserLanguage:${navigator.language}

`
  return (
    <a
      className={cn(
        buttonVariants({ variant: 'ghost' }),
        'group relative flex h-12 items-center justify-start rounded-lg text-foreground/50 hover:bg-muted'
      )}
      href={encodeURI(`mailto:${to}?subject=${subject}&body=${body}`)}
    >
      <RiMailSendLine className='h-5 w-5 min-w-5' />
      <span
        className={cn(
          'absolute left-12 text-base duration-200',
          `${!isOpen && 'text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100'}`
        )}
      >
        {' '}
        {t('common.send-feedback')}
      </span>
    </a>
  )
}
