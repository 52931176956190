import {
  GetSingleBoardComputerListQueryVariables,
  QueryMode,
  SingleBoardComputerLifeCycleStages,
  SingleBoardComputerWhereInput,
  useGetSingleBoardComputerListQuery
} from '@/api'
import { Route, singleBoardComputersSearchSchema } from '@/routes/single-board-computers'
import { Link as RouterLink, useNavigate } from '@tanstack/react-router'
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { usePagination } from './usePagination'

type SingleBoardComputerSearchParams = z.infer<typeof singleBoardComputersSearchSchema>
type Order = SingleBoardComputerSearchParams['orderBy']

type SingleBoardComputerColumns = {
  productId: string
  createdAt: Date
  lifeCycleStage: SingleBoardComputerLifeCycleStages
  macAddress: string
  hostname: string
  generation: string
  version: string
}

export const useSingleBoardComputersTable = () => {
  const search = Route.useSearch()

  const { t } = useTranslation()
  const navigate = useNavigate({ from: Route.fullPath })

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(search.where)

  const { pagination, onPaginationChange } = usePagination(search.pagination)

  const { pageSize, pageIndex } = pagination

  const skip = pageSize * pageIndex
  const take = pageSize

  const [sorting, setSorting] = useState<Order>(search.orderBy)

  const queryVariables: GetSingleBoardComputerListQueryVariables = useMemo(
    () => ({
      take,
      skip,
      orderBy: sorting.reduce((acc: Record<string, 'desc' | 'asc'>, item) => {
        acc[item.id] = item.desc ? 'desc' : 'asc'
        return acc
      }, {}),

      where: (() => {
        const value = columnFilters?.[0]?.value || ''
        return {
          OR: [
            {
              productId: {
                contains: value,
                mode: QueryMode.Insensitive
              }
            },
            {
              bbb: {
                is: {
                  macAddress: {
                    contains: value,
                    mode: QueryMode.Insensitive
                  }
                }
              }
            },
            {
              bbb: {
                is: {
                  hostname: {
                    contains: value,
                    mode: QueryMode.Insensitive
                  }
                }
              }
            }
          ]
        } as SingleBoardComputerWhereInput
      })()
    }),
    [take, skip, sorting, columnFilters]
  )

  const [result] = useGetSingleBoardComputerListQuery({
    variables: queryVariables
  })

  const { data, fetching, error } = result

  useEffect(() => {
    navigate({
      to: Route.fullPath,
      search: prev => ({
        ...prev,
        pagination: { skip, take },
        orderBy: sorting,
        where: columnFilters.map(f => ({ value: f.value as string, id: f.id }))
      })
    })
  }, [pageIndex, pageSize, sorting, columnFilters, navigate, skip, take])

  const columns: ColumnDef<SingleBoardComputerColumns>[] = useMemo(
    () => [
      {
        accessorKey: 'productId',
        header: t('common.product-id'),

        cell: ({ row }) => (
          <RouterLink
            to='/single-board-computers/$productId'
            params={{ productId: row.getValue('productId') as string }}
          >
            {row.getValue('productId')}
          </RouterLink>
        ),
        meta: {
          filterVariant: 'text'
        }
      },
      {
        accessorKey: 'hostname',
        header: t('sbc.hostname'),
        cell: ({ row }) => (
          <RouterLink
            to='/single-board-computers/$productId'
            params={{ productId: row.getValue('productId') as string }}
          >
            {row.getValue('hostname')}
          </RouterLink>
        )
      },

      {
        accessorKey: 'macAddress',
        header: t('sbc.mac-address'),
        cell: ({ row }) => (
          <RouterLink
            to='/single-board-computers/$productId'
            params={{ productId: row.getValue('productId') as string }}
          >
            {row.getValue('macAddress')}
          </RouterLink>
        )
      },

      {
        accessorKey: 'facilityId',
        header: t('common.facility-id'),
        cell: ({ row }) =>
          row.getValue('facilityId') ? (
            <RouterLink
              to='/facilities/$id'
              params={{ id: row.getValue('facilityId') as string }}
              className='block w-full text-center'
            >
              {row.getValue('facilityId')}
            </RouterLink>
          ) : null
      },

      {
        accessorKey: 'lifeCycleStage',
        header: t('common.life-cycle-stage'),
        enableSorting: false
      },
      {
        accessorKey: 'generation',
        header: t('sbc.generation'),
        enableSorting: false
      },
      {
        accessorKey: 'version',
        header: t('common.ehub-os-version'),
        enableSorting: false
      }
    ],
    [t]
  )

  const state = {
    pagination,
    sorting,
    columnFilters
  }

  const table = useReactTable({
    data: (data?.singleBoardComputersConnection?.edges?.map(sbc => ({
      ...sbc,
      ...sbc.bbb,
      facilityId: sbc.facility?.id
    })) || []) as SingleBoardComputerColumns[],
    columns,
    state,

    rowCount: data?.singleBoardComputersConnection.totalCount || 0,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableFilters: true,
    onSortingChange: setSorting,
    onPaginationChange,
    onColumnFiltersChange: setColumnFilters
  })

  return { table, fetching, error }
}
