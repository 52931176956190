import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { RiWifiLine, RiWifiOffFill } from '@remixicon/react'

import { Datetime } from '@/components/DateTime/datetime'
import { Skeleton } from '@/components/ui/skeleton'
import { useTranslation } from 'react-i18next'

export const IsOnline = ({ dataReceivedAt }: { dataReceivedAt?: Date | string | null }) => {
  const { t } = useTranslation()

  if (dataReceivedAt === undefined) return <Skeleton className='h-6 w-6' />
  const isOffline = Math.abs(new Date(dataReceivedAt || 0).getTime() - new Date().getTime()) > 10 * 60 * 1000

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          {isOffline ? (
            <RiWifiOffFill className='text-destructive' />
          ) : (
            <RiWifiLine className='text-primary' />
          )}
        </TooltipTrigger>
        <TooltipContent>
          <p>
            {dataReceivedAt ? (
              <>
                {t('common.last-seen-at')} <Datetime date={dataReceivedAt} />{' '}
              </>
            ) : (
              t('common.no-data-available')
            )}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
