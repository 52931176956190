import { Profile } from '@/components/Profile/Profile'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/profile/')({
  component: () => (
    <>
      <Profile />
    </>
  )
})
