import { useTranslation } from 'react-i18next'
import {
  DashboardIcon,
  FacilityIcon,
  PowershareIcon,
  SingleBoardComputerIcon,
  UserIcon
} from '../Icons/Icons'

import { RiDashboard2Fill, RiSettings2Line } from '@remixicon/react'
import { useMemo } from 'react'

export type Nav = {
  title: string
  Icon?: typeof RiDashboard2Fill
  children?: Nav[]
  href?: string
  beta?: boolean
}
export interface NavItem {
  title: string
  href: string
  Icon: typeof RiDashboard2Fill
  color?: string
  isChildren?: boolean
  beta?: boolean
  children?: NavItem[]
}

export const useNav = ({ isBottom }: { isBottom: boolean }) => {
  const { t } = useTranslation()

  const nav: NavItem[] = useMemo(() => {
    const menu = [
      {
        title: t('nav.dashboard'),
        Icon: DashboardIcon,
        href: '/',
        beta: true
      },
      {
        title: t('nav.facilities'),
        Icon: FacilityIcon,
        href: '/facilities',
        beta: true
      },
      {
        title: t('nav.powershare-management'),
        Icon: PowershareIcon,
        href: '/powershares'
      },

      {
        title: t('nav.user-management'),
        Icon: UserIcon,
        href: '/users'
      },
      {
        title: t('nav.single-board-computers'),
        Icon: SingleBoardComputerIcon,
        href: '/single-board-computers'
      }
    ]

    if (isBottom) {
      menu.push({
        title: 'profile',
        Icon: RiSettings2Line,
        href: '/profile/'
      })
    }

    return menu
  }, [t, isBottom])
  return nav
}
