import { LocaleSwitch } from '@/i18n/LocaleSwitch'
import { useAuthStore } from '@/store'
import { useHeader } from '@/store/useHeader'
import { useEffect } from 'react'
import { ModeToggle } from '../Navigation/ModeToggle'
import { Card, CardHeader, CardTitle } from '../ui/card'

/**
 * Not to be confused with other users, this is the actually logged in users settings
 */
export const Profile = () => {
  const { user } = useAuthStore()
  // const { t } = useTranslation()
  const { setPageInfo, removePageInfo } = useHeader()

  useEffect(() => {
    if (user) {
      setPageInfo({
        title: `${user?.firstName} ${user?.lastName}`
      })
    }
    return () => removePageInfo()
  }, [setPageInfo, removePageInfo, user])

  return (
    <>
      <Card className='w-full'>
        <CardHeader className='flex flex-row items-center space-x-2'>
          <CardTitle>
            {user?.firstName} {user?.lastName}
          </CardTitle>
          <span className='flex-1' />
          <ModeToggle /> <LocaleSwitch />
        </CardHeader>
      </Card>
    </>
  )
}
