import { DateTime } from 'luxon'
import { z } from 'zod'

const DateType = z.union([
  z.literal('HOUR'),
  z.literal('DAY'),
  z.literal('WEEK'),
  z.literal('MONTH'),
  z.literal('YEAR')
])

const SubContextType = z.union([
  z.literal('iExtD'),
  z.literal('iExtQ'),
  z.literal('iExtRms'),
  z.literal('iInvD'),
  z.literal('iInvQ'),
  z.literal('iInvRms'),
  z.literal('u'),
  z.literal('uDc')
])

const ContextType = z.union([z.literal('power'), z.literal('energy'), z.literal('griddata')])

export const validateFacilityStatisticsSearch = z.object({
  month: z
    .object({
      monthNumber: z.number().default(() => new Date().getMonth()),
      year: z.number().default(() => new Date().getFullYear())
    })
    .default(() => ({
      year: new Date().getFullYear(),
      monthNumber: new Date().getMonth()
    })),
  week: z
    .object({
      weekNumber: z.number().default(() => DateTime.now().weekNumber),
      year: z.number().default(() => new Date().getFullYear())
    })
    .default(() => ({
      weekNumber: DateTime.now().weekNumber,
      year: new Date().getFullYear()
    })),
  date: z.coerce.date().default(() => new Date()),
  year: z.number().default(() => new Date().getFullYear()),
  type: DateType.default('DAY'),
  context: ContextType.default('energy'),
  subContext: SubContextType.default('iExtRms')
})
export type FacilityStatisticsSearch = z.infer<typeof validateFacilityStatisticsSearch>
